import { useMutation } from '@tanstack/react-query';
import useSignIn from 'react-auth-kit/hooks/useSignIn';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useAuthStore from '../store/auth';
import { axiosWithAuth, axiosWithoutAuth } from '../utils/axios';

interface InputCodeRequest {
  email: string;
  code: string;
}

export const useInputCode = () => {
  const axios = axiosWithoutAuth();
  const signIn = useSignIn();
  const navigate = useNavigate();
  const [params, _] = useSearchParams()

  const inputCode = async ({ email, code }: InputCodeRequest) => {
    try {
      const res = await axios.post(
        'auth/login',
        {
          email,
          code,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (res.status === 202) {
        // Return a specific state to indicate phone number collection is needed
        return { needPhoneNumber: true };
      }
      
      if (res.data?.access_token) {
        signIn({
          auth: {
            token: res.data?.access_token,
          },
        });
        const redirectURL = params.get('redirect_uri')
        if (!redirectURL) {
          navigate('/');
        } else {
          window.location.href = redirectURL
        }
        
      }
    } catch {}
  };
  const { mutateAsync } = useMutation(inputCode);
  return { mutateAsync };
};
