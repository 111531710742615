import classNames from "classnames";
import { ReactComponent as Checked } from "../../static/Checked.svg";

type Props = {
  onClick: () => void;
  content: string;
  selected: boolean;
};

const Tag = ({ onClick, content, selected }: Props) => {
  return (
    <div
      onClick={onClick}
      className={classNames(
        "text-sm px-3 py-1 rounded-[14px] border border-solid flex items-center gap-2",
        {
          "text-gray-900 bg-white border-gray-200 cursor-pointer hover:bg-gray-50": !selected,
          "text-blue-500 bg-blue-50 border-blue-500": selected,
        }
      )}
    >
      {selected && <Checked />}
      {content}
    </div>
  );
};

export default Tag;
