import React, { useEffect, useState } from 'react'
import Button, { ButtonType } from '../components/Button'
import { ReactComponent as Add } from '../static/Add.svg'
import PropertyList from '../components/PropertyList'
import { ReactComponent as File } from '../static/File.svg'
import { MoonLoader } from 'react-spinners'
import { useNavigate, useParams } from 'react-router-dom'
import { usePropertyDetail } from '../query/usePropertyDetail'
import useAgentInfoStore, {
  Agent,
  FileStatus,
  getTextForStatus,
  Knowledge, Property,
} from '../store/agentInfo'
import Modal from 'react-modal'
import { useAdminDeleteProperty } from '../query/useAdminDeleteProperty'
import { ReactComponent as Warning } from '../static/Warning.svg'
import { ReactComponent as Error } from '../static/Error.svg'
import { ReactComponent as Success } from '../static/Success.svg'
import { ReactComponent as Delete } from '../static/Delete.svg'
import { ReactComponent as Download } from '../static/Download.svg'
import Input from '../components/Input'
import TextArea from '../components/TextArea'
import Upload from '../components/Upload'
import { useAdminUploadFile } from '../query/useAdminUploadFile'
import { useAdminUpdateProperty } from '../query/useAdminUpdateProperty'
import {useAdminPublishProperty} from '../query/usePublishProperty'
import { ReactComponent as OpenTab } from '../static/OpenTab.svg'
import Navbar from '../components/Navbar'
import classNames from 'classnames'
import {useAdminCreateProperty} from "../query/useAdminCreateProperty";
import {useAdminReviewProperty} from "../query/useAdminReviewProperty";
import {useAdminPropertyDetail} from "../query/useAdminPropertyDetail";
import useAuthStore from '../store/auth'
import Login from './Login'
import {useAdminGenerateFullDisclosureDownloadURL} from "../query/useAdminGenerateFullDisclosureDownloadURL";

function AdminPropertyDetail() {
  const { propertyId } = useParams()
  const { data, isFetching, isLoading, refetch } = useAdminPropertyDetail(propertyId)
  const {
    mlsListingID,
    setMlsListingID,
    knowledges,
    addToKnowledge,
    removeKnowledge,
    initForUpdate,
    successModalOpen,
    setSuccessModalOpen,
    errorModalOpen,
    setErrorModalOpen,
  } = useAgentInfoStore()
  const propertyInfo = data as Property
  const navigate = useNavigate()
  const { mutateAsync } = useAdminDeleteProperty()
  const [warningModalOpen, setWarningModalOpen] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [isGeneratingDownloadURL, setIsGeneratingDownloadURL] = useState(false);
  const enableUpdate =
    Boolean(mlsListingID)
  const { mutateAsync: uploadFile } = useAdminUploadFile()
  const { mutateAsync: updateAgent, isLoading: isUpdating } = useAdminUpdateProperty()
  const { mutateAsync: publishProperty } = useAdminPublishProperty()
  const { mutateAsync: reviewProperty } = useAdminReviewProperty()
  const { mutateAsync: generateFullDisclosureDownloadURL } =useAdminGenerateFullDisclosureDownloadURL()
  const [uploading, setUploading] = useState(false)
  let timeout: any = null

  useEffect(() => {
    if (isEditing) {
      initForUpdate(propertyInfo)
    }
  }, [isEditing])

  useEffect(() => {
    const knowledges = data?.knowledges
    if (knowledges) {
      if (
        knowledges.some(
          (knowledge: Knowledge) => knowledge.status === FileStatus.PROCESSING,
        )
      ) {
        if (timeout) {
          clearTimeout(timeout)
          timeout = setTimeout(refetch, 5000)
        } else {
          timeout = setTimeout(refetch, 5000)
        }
      }
    }
  }, [data])

  const { isLogin } = useAuthStore()
  if (!isLogin) {
    return <Login/>
  }

  if (typeof propertyInfo !== 'object') {
    return null
  }

  if (isEditing) {
    return (
      <div className="flex justify-center pt-[156px] pb-[200px]">
        <Navbar />
        <Modal
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
          isOpen={successModalOpen}
          onRequestClose={() => {
            setSuccessModalOpen(false)
          }}
          style={{
            content: {
              width: 400,
              height: 200,
              inset: 'unset',
              padding: 0,
              border: 'none',
              borderRadius: '16px',
              boxShadow:
                '0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)',
              overflow: 'hidden',
            },
            overlay: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(0, 0, 0, 0.55)',
              zIndex: 100,
            },
          }}
          htmlOpenClassName="overflow-hidden"
          shouldCloseOnEsc={true}
        >
          <div className="flex flex-col items-center justify-center h-full gap-4 p-6">
            <Success />
            <div className="text-2xl font-semibold">Property created</div>
            <Button
              type={ButtonType.PRIMARY}
              content="OK"
              onClick={async () => {
                setSuccessModalOpen(false)
                navigate('/properties')
              }}
            />
          </div>
        </Modal>
        <Modal
          shouldCloseOnOverlayClick={false}
          ariaHideApp={false}
          isOpen={errorModalOpen}
          onRequestClose={() => {
            setErrorModalOpen(false)
          }}
          style={{
            content: {
              width: 400,
              height: 256,
              inset: 'unset',
              padding: 0,
              border: 'none',
              borderRadius: '16px',
              boxShadow:
                '0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)',
              overflow: 'hidden',
            },
            overlay: {
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              background: 'rgba(0, 0, 0, 0.55)',
              zIndex: 100,
            },
          }}
          htmlOpenClassName="overflow-hidden"
          shouldCloseOnEsc={true}
        >
          <div className="flex flex-col items-center justify-center h-full gap-4 p-6">
            <Error />
            <div className="text-2xl font-semibold">File cannot upload</div>
            <div className="text-base text-center">{`The file exceeds file size limit, please try a smaller file.`}</div>
            <Button
              type={ButtonType.PRIMARY}
              content="OK"
              onClick={async () => {
                setErrorModalOpen(false)
              }}
            />
          </div>
        </Modal>
        <div className="flex flex-col items-center w-[1024px] gap-6">
          <div className="flex items-center justify-start mb-[40px] w-full px-6 py-4">
              { propertyInfo.edit_status === 'draft' &&
                  <div className="text-[40px] font-bold">Property details:<span style={{color: "gray"}}>Draft</span></div>
              }
              { propertyInfo.edit_status === 'publish' &&
                  <div className="text-[40px] font-bold">Property details:<span style={{color: "green"}}>Publish</span></div>
              }
              { propertyInfo.edit_status === 'under_review' &&
                  <div className="text-[40px] font-bold">Property details:<span style={{color: "red"}}>Under Review</span></div>
              }
          </div>
          <div className="flex flex-col w-full gap-4">
            <div className="flex flex-col gap-1 px-6 w-full">
              <div className="text-base font-bold">Knowledge</div>
              <div className="text-base">
                {'20 files max, each file 20MB max (PDF 150MB max)'}
              </div>
            </div>
            <div className="flex flex-wrap gap-3">
              {knowledges.map((knowledge, index) => (
                <div className="w-[506px] bg-blue-50 px-6 py-3 flex items-center rounded-lg">
                  <div className="flex gap-2 items-center justify-start grow">
                    <File />
                    <div className="truncate max-w-[300px]" title={knowledge.name}>
                      {knowledge.name.length > 40 ? knowledge.name.substring(0, 40) + '...' : knowledge.name}
                    </div>
                    <div
                      className={classNames('', {
                        'text-red-700 font-semibold':
                          knowledge.status === FileStatus.FAILED,
                      })}
                    >{`(${getTextForStatus(knowledge.status)})`}</div>
                  </div>
                  <div className="flex gap-6 items-center justify-start">
                    <Delete
                      className={classNames('cursor-pointer', {
                        'fill-blue-500': knowledge.status !== FileStatus.FAILED,
                        'fill-red-700': knowledge.status === FileStatus.FAILED,
                      })}
                      onClick={() => {
                        removeKnowledge(index)
                      }}
                    />
                    {knowledge.download_url !== '' && (
                      <Download
                        className={classNames('cursor-pointer', {
                          'fill-blue-500':
                            knowledge.status !== FileStatus.FAILED,
                          'fill-red-700':
                            knowledge.status === FileStatus.FAILED,
                        })}
                        onClick={async () => {
                          try {
                            const response = await fetch(knowledge.download_url)
                            const blob = await response.blob()
                            const link = document.createElement('a')
                            link.href = URL.createObjectURL(blob)
                            link.download = knowledge.name // Replace 'filename.ext' with your desired filename
                            document.body.appendChild(link)
                            link.click()
                            document.body.removeChild(link)
                          } catch (error) {
                            console.error('Error downloading file:', error)
                          }
                        }}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
            {knowledges.length < 80 && (
              <Upload
                content="Upload knowledge file"
                onBeforeUpload={async (files) => {
                  setUploading(true)

                  Promise.all(Array.from(files).map(async (file: any) => {
                        if (file) {
                          if (
                              file.name.toLowerCase().endsWith('.pdf') ||
                              file.type === 'application/pdf'
                          ) {
                            if (file.size > 150 * 1024 * 1024) {
                              setErrorModalOpen(true)
                            } else {
                              //setUploading(true)
                              await uploadFile(file)
                              //setUploading(false)
                            }
                          } else {
                            if (file.size > 20 * 1024 * 1024) {
                              setErrorModalOpen(true)
                            } else {
                              //setUploading(true)
                              await uploadFile(file)
                              //setUploading(false)
                            }
                          }
                        }
                      }
                  )).then(() => setUploading(false))

                }}
                loading={uploading}
              />
            )}
          </div>

          <div className="fixed flex items-center justify-center w-full py-6 bottom-0 gap-4 border-t border-t-gray-200 bg-white">
            <Button
              disabled={uploading}
              type={ButtonType.SECONDARY}
              content="Discard changes"
              loading={isUpdating}
              onClick={() => {
                setIsEditing(false)
              }}
            />
            <Button
              type={ButtonType.PRIMARY}
              content="Save changes"
              disabled={!enableUpdate || uploading}
              loading={isUpdating}
              onClick={async () => {
                await updateAgent()
                setIsEditing(false)
                refetch()
              }}
            />
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="flex justify-center pt-[156px] pb-[200px]">
      <Navbar />
      <Modal
        shouldCloseOnOverlayClick={false}
        ariaHideApp={false}
        isOpen={warningModalOpen}
        onRequestClose={() => {
          setWarningModalOpen(false)
        }}
        style={{
          content: {
            width: 400,
            height: 312,
            inset: 'unset',
            padding: 0,
            border: 'none',
            borderRadius: '16px',
            boxShadow:
              '0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)',
            overflow: 'hidden',
          },
          overlay: {
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'rgba(0, 0, 0, 0.55)',
            zIndex: 100,
          },
        }}
        htmlOpenClassName="overflow-hidden"
        shouldCloseOnEsc={true}
      >
        <div className="flex flex-col items-center justify-center h-full gap-4 p-6">
          <Warning />
          <div className="text-2xl font-semibold text-center">{`Are you sure you want to delete ${propertyInfo?.address}?`}</div>
          <div className="text-base text-center">{`You can’t undo this action. By deleting ${propertyInfo?.address}, chat history with this agent will also be deleted.`}</div>
          <div className="flex gap-3">
            <Button
              type={ButtonType.SECONDARY}
              content="Cancel"
              onClick={async () => {
                setWarningModalOpen(false)
              }}
            />
            <Button
              type={ButtonType.PRIMARY}
              content="Delete property"
              onClick={async () => {
                setWarningModalOpen(false)
                await mutateAsync({ propertyId })
                navigate('/admin/properties')
              }}
            />
          </div>
        </div>
      </Modal>
      <div className="flex flex-col items-center w-[1024px] gap-6">
        <div className="flex items-center justify-between mb-[40px] w-full px-6 py-4">
            {propertyInfo.edit_status === 'draft' &&
                <div className="text-[40px] font-bold">Property details:<span style={{color: "gray"}}>Draft</span></div>
            }
            {propertyInfo.edit_status === 'publish' &&
                <div className="text-[40px] font-bold">Property details:<span style={{color: "green"}}>Publish</span>
                </div>
            }
            {propertyInfo.edit_status === 'under_review' &&
                <div className="text-[40px] font-bold">Property details:<span style={{color: "red"}}>Under Review</span>
                </div>
            }
          <Button
              type={ButtonType.SECONDARY}
              content="Test property"
              Icon={OpenTab}
              onClick={async () => {
                window.open(`/admin/test/${propertyId}`, '_blank')
              }}
          />
        </div>
        {isLoading ? (
            <div className="flex min-h-[200px] items-center">
              <MoonLoader size={24}/>
            </div>
        ) : (
            <>
              <div className="flex flex-col gap-1 px-6 w-full">
                <div className="text-base font-bold">Property address</div>
                <div className="text-base">{propertyInfo.address}</div>
              </div>
              <div className="flex flex-col gap-1 px-6 w-full">
                <div className="text-base font-bold">APN</div>
                <div className="text-base">{propertyInfo.apn}</div>
              </div>
              <div className="flex flex-col gap-1 px-6 w-full">
                <div className="text-base font-bold">Status</div>
                <div className="text-base">{propertyInfo.status}</div>
              </div>
              <div className="flex flex-col gap-1 px-6 w-full">
                <div className="text-base font-bold">Full disclosure download URL</div>
                    {isGeneratingDownloadURL ? (
                        <div
                            className={`bg-gray-200 h-10 min-w-24 rounded-[32px] px-4 py-2 flex gap-1 items-center cursor-not-allowed justify-center`}
                        >
                          <MoonLoader size={16}/>
                          <div className="text-gray-500">Generating Full disclosure download link</div>
                        </div>

                    ) : (
                        <Button
                            type={ButtonType.SECONDARY}
                            content={"Generate Full disclosure download link"}
                            onClick={async () => {
                              setIsGeneratingDownloadURL(true);
                              try {
                                await generateFullDisclosureDownloadURL({
                                  propertyId: propertyInfo.id,
                                })
                                window.location.reload()
                              } catch (error) {
                                //setError(error.message);
                              } finally {
                                //setIsLoading(false);
                                setIsGeneratingDownloadURL(false);
                              }
                            }}
                    />)}
              </div>
              <div className="flex flex-col gap-1 px-6 w-full">
                <div className="text-base font-bold">Full disclosure download URL</div>
                {propertyInfo.full_disclosure_download_url ? (
                    <a
                        href={propertyInfo.full_disclosure_download_url}
                        //download="myfile.pdf"
                        className="text-black font-bold"
                    >
                      Download Link
                    </a>
                ) : (
                    <div className="text-base">No download URL available</div>
                )}
              </div>
              <div className="flex flex-col gap-1 px-6 w-full">
                <div className="text-base font-bold">Basic info</div>
                <div
                    className="text-base">{propertyInfo.bedrooms} bedrooms, {propertyInfo.full_bathrooms} full_bathrooms, {propertyInfo.half_bathrooms} half_bathrooms, {propertyInfo.living_sqft} living_sqft, {propertyInfo.lot_size} lot_size
                </div>
              </div>
              <div className="flex flex-col gap-1 px-6 w-full">
                <div className="text-base font-bold">Property type</div>
                <div
                    className="text-base">{propertyInfo.property_type}
                </div>
              </div>
              <div className="flex flex-col gap-1 px-6 w-full">
                <div className="text-base font-bold">Prices</div>
                <div className="text-base">listing_price {propertyInfo.listing_price},
                  estimate_price {propertyInfo.estimate_price}</div>
              </div>
              <div className="flex flex-col gap-1 px-6 w-full">
                <div className="text-base font-bold">Disclosure URL</div>
                <div className="text-base">{propertyInfo.disclosure_url}</div>
              </div>
              <div className="flex flex-col gap-1 px-6 w-full">
                <div className="text-base font-bold">Listing agent</div>
                <div
                    className="text-base">Name: {propertyInfo.listing_agent_name},
                  License: {propertyInfo.listing_agent_license_number}
                </div>
                <div
                    className="text-base">Phone: {propertyInfo.listing_agent_phone_number},
                  Email: {propertyInfo.listing_agent_email}
                </div>
                <div
                    className="text-base">Broker Name: {propertyInfo.brokerage_name},
                  Broker License: {propertyInfo.brokerage_license_number}
                </div>
              </div>
              <div className="flex flex-col w-full gap-4">
                <div className="flex flex-col gap-1 px-6 w-full">
                  <div className="text-base font-bold">Knowledge</div>
                  <div className="text-base">20 files max, each file 10MB max</div>
                </div>
                <div className="flex flex-wrap gap-3">
                  {propertyInfo.knowledges.map((knowledge) => (
                      <div className="w-[506px] bg-blue-50 px-6 py-3 flex items-center rounded-lg">
                        <div className="flex w-full items-center justify-between">
                          <div className="flex gap-2">
                            <File/>
                            <div className="truncate max-w-[300px]" title={knowledge.name}>
                              {knowledge.name.length > 40 ? knowledge.name.substring(0, 40) + '...' : knowledge.name}
                            </div>
                            <div
                                className={classNames('', {
                                  'text-red-700 font-semibold':
                                      knowledge.status === FileStatus.FAILED,
                                })}
                            >{`(${getTextForStatus(knowledge.status)})`}</div>
                          </div>
                          {knowledge.download_url !== '' && (
                              <Download
                                  className={classNames('cursor-pointer', {
                                    'fill-blue-500':
                                        knowledge.status !== FileStatus.FAILED,
                                    'fill-red-700':
                                        knowledge.status === FileStatus.FAILED,
                                  })}
                                  onClick={async () => {
                                    try {
                                      const response = await fetch(
                                          knowledge.download_url,
                                      )
                                      const blob = await response.blob()
                                      const link = document.createElement('a')
                                      link.href = URL.createObjectURL(blob)
                                      link.download = knowledge.name // Replace 'filename.ext' with your desired filename
                                      document.body.appendChild(link)
                                      link.click()
                                      document.body.removeChild(link)
                                    } catch (error) {
                                      console.error('Error downloading file:', error)
                                    }
                                  }}
                              />
                          )}
                        </div>
                      </div>
                  ))}
                </div>
              </div>
            </>
        )}
        <div
            className="fixed flex items-center justify-center w-full py-6 bottom-0 gap-4 border-t border-t-gray-200 bg-white">
          <Button
              type={ButtonType.SECONDARY}
              content="Delete property"
              onClick={() => {
                setWarningModalOpen(true)
              }}
          />
          <Button
              type={ButtonType.PRIMARY}
            content="Edit property"
            onClick={async () => {
              setIsEditing(true)
            }}
          />
          {['draft', 'under_review'].includes(propertyInfo.edit_status) &&
              <Button
                  type={ButtonType.SECONDARY}
                  content={"Publish property"}
                  onClick={async () => {
                    await publishProperty({
                      propertyId: propertyInfo.id,
                    })
                    window.location.reload()
                  }}
              />
          }
          {['draft', 'publish'].includes(propertyInfo.edit_status) &&
              <Button
                  type={ButtonType.SECONDARY}
                  content={"Review property"}
                  onClick={async () => {
                    await reviewProperty({propertyId: propertyInfo.id})
                    window.location.reload()
                  }}
              />
          }
        </div>
      </div>
    </div>
  )
}

export default AdminPropertyDetail
