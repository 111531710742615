import React, { HTMLProps, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { ReactComponent as UpArrow } from "../../static/UpArrow.svg";
import { ReactComponent as Search } from "../../static/Search.svg";

type Props = {
  isError: boolean;
  errMsg?: string;
  required?: boolean;
} & HTMLProps<HTMLInputElement>;

const Input = ({
  isError,
  errMsg,
  placeholder,
  value,
  onFocus,
  onBlur,
  className,
  onChange,
  type,
  required = false,
  ...props
}: Props) => {
  return (
    <div
      className={classnames(
        "border border-gray-200 border-solid rounded-[30px] h-[52px] pl-6 pr-3 py-3 w-full mb-2 flex items-center justify-between gap-2",
        className
      )}
    >
      <div className="flex flex-col w-full gap-1">
        {Boolean(value) && (
          <div className="text-gray-600 text-xs">
            {placeholder}
            {required && <span className="text-red-700 text-xs"> *</span>}
          </div>
        )}
        {!Boolean(value) && (
          <div className="text-gray-600 text-sm absolute z-0">
            {placeholder}
            {required && <span className="text-red-700 text-sm"> *</span>}
          </div>
        )}
        <input
          className="border-none outline-none h-full w-full bg-transparent text-sm grow z-[1]"
          onChange={(e) => {
            onChange?.(e);
          }}
          type={type}
          value={value}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
            }
          }}
          onBlur={onBlur}
          {...props}
        />
      </div>
    </div>
  );
};

export default Input;
