import React, { HTMLProps, useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { ReactComponent as UpArrow } from "../../static/UpArrow.svg";
import { ReactComponent as Search } from "../../static/Search.svg";

type Props = {
  isError: boolean;
  errMsg?: string;
  onFinish: () => void;
} & HTMLProps<HTMLInputElement>;

const ChatInput = ({
  isError,
  errMsg,
  placeholder,
  value,
  onFocus,
  onBlur,
  className,
  onChange,
  onFinish,
  ...props
}: Props) => {
  const canSubmit = Boolean(value);
  return (
    <div
      className={classnames(
        "border border-gray-200 border-solid rounded-[30px] h-[52px] pl-6 pr-3 py-3 w-full mb-2 flex items-center justify-between gap-2",
        className
      )}
    >
      <div className="flex flex-col w-full gap-1">
        {Boolean(value) && (
          <div className="text-gray-600 text-xs">{placeholder}</div>
        )}

        <input
          className="border-none outline-none h-full w-full bg-transparent text-sm grow"
          placeholder={placeholder}
          onChange={(e) => {
            onChange?.(e);
          }}
          value={value}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              onFinish();
            }
          }}
          onBlur={onBlur}
          onFocus={onFocus}
          {...props}
        />
      </div>
      <div
        onClick={() => onFinish()}
        className={classnames(
          "w-[34px] h-[34px] rounded-full shrink-0 flex items-center justify-center",
          {
            "cursor-pointer bg-blue-500": canSubmit,
            "cursor-not-allowed bg-gray-200": !canSubmit,
          }
        )}
      >
        <UpArrow />
      </div>
    </div>
  );
};

export default ChatInput;
