import Modal from "react-modal";
import { Property } from "../../store/agentInfo";
import { ReactComponent as Close } from "../../static/Close.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Button, { ButtonType } from "./Button";
import classNames from "classnames";
import { propertyStatusMap } from "../utils/propertyStatus";
import { useHomeRequestProperty } from "../../query/useHomeRequestProperty";
import { useHomeUser } from "../../query/useHomeUser";

export type Suggestion = {
  address: string;
  property: Property;
};

type Props = {
  inputAddress: string;
  suggestionData: Suggestion[];
  isOpen: boolean;
  handleModalClose: () => void;
  onRequestSent: () => void;
};

const PropertySearchModal = ({
  inputAddress,
  suggestionData,
  isOpen,
  handleModalClose,
  onRequestSent,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [selectedProperty, setSelectedProperty] = useState("");
  const { data: userData } = useHomeUser();

  useEffect(() => {
    if (suggestionData.length === 1) {
      setSelectedProperty(suggestionData[0].property.id);
    }
  }, [suggestionData]);
  const { mutateAsync: requestProperty } = useHomeRequestProperty();

  const onModalClose = () => {
    handleModalClose();
    setSelectedProperty("");
  };

  console.log(suggestionData);
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === "Enter" && selectedProperty !== "") {
        window.open(`/home/report/${selectedProperty}`, "_blank");
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [selectedProperty]);

  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onModalClose}
      style={{
        content: {
          width: 600,
          //height: 600,
          inset: "unset",
          padding: 0,
          border: "none",
          borderRadius: "16px",
          boxShadow:
            "0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)",
          overflow: "hidden",
          overflowY: "auto",
        },
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.55)",
          zIndex: 100,
        },
      }}
      htmlOpenClassName="overflow-hidden"
      shouldCloseOnEsc={true}
    >
      <div className="flex flex-col items-start justify-start h-full w-full p-5">
        <Close
          className="absolute top-5 right-5 cursor-pointer"
          onClick={onModalClose}
        />
        <div className="text-sm font-bold">{t("newSearchPropertyModalTitle")}</div>
        <div className="text-sm text-gray-600 mt-2 mb-5">
          {t("newSearchPropertyModalSubtitle")}
        </div>
        <div
          className={`flex flex-col justify-between items-center w-full gap-2`}
        >
          {suggestionData &&
            suggestionData.length > 0 &&
            suggestionData.map((suggestion: Suggestion) => {
              const statusData = propertyStatusMap[
                suggestion.property.status as keyof typeof propertyStatusMap
              ] ?? {
                label: "For sale",
                color: "green-600",
              };
              return (
                <div
                  className={classNames(
                    `py-2 px-3 rounded-xl flex-grow cursor-pointer w-full border`,
                    {
                      "border-blue-500 bg-blue-50":
                        selectedProperty === suggestion.property.id,
                      "border-gray-100 bg-gray-100":
                        selectedProperty !== suggestion.property.id,
                    }
                  )}
                  onClick={() => setSelectedProperty(suggestion.property.id)}
                >
                  <div className="flex justify-between items-start">
                    <div className="flex items-start">
                      <div className="ml-2">
                        <h3 className="text-sm font-normal text-gray-900">
                          {suggestion.address}
                        </h3>
                        <p className="text-xs font-normal text-gray-600">
                          {suggestion.property.bedrooms} {t("bedsLabel")} |{" "}
                          {suggestion.property.full_bathrooms +
                            suggestion.property.half_bathrooms * 0.5}{" "}
                          {t("bathsLabel")} | {suggestion.property.living_sqft}{" "}
                          {t("sqftLabel")}
                        </p>
                      </div>
                    </div>
                    <div className="flex flex-col items-end">
                      <div
                        className={`text-sm font-bold text-${statusData.color}`}
                      >
                        {statusData.label}
                      </div>
                      <div className="text-xs text-gray-600">
                        {suggestion.property.listing_price.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          {suggestionData && suggestionData.length === 0 && (
            <div className="text-center mb-4">
              <div className="text-sm font-bold text-gray-900">
                {t("newSearchPropertyModalNoResultLabel")}
              </div>
              <div className="mt-2 text-sm text-gray-900">
                {t("newSearchPropertyModalNoResultDescription")}
              </div>
            </div>
          )}
        </div>
        <div className="flex mt-8 w-full justify-between">
          <Button
            type={ButtonType.SECONDARY}
            content={t("offerSearchAnotherPropertyLabel")}
            onClick={onModalClose}
          />
          {suggestionData.length > 0 ? (
            <Button
              type={ButtonType.PRIMARY}
              loading={false}
              disabled={selectedProperty === ""}
              content={t("offerSearchPropertyContinueLabel")}
              onClick={async () => {
                window.open(`/home/report/${selectedProperty}`, "_blank");
              }}
            />
          ) : (
            <Button
              type={ButtonType.PRIMARY}
              loading={false}
              disabled={false}
              content={t("requestPropertyReport")}
              onClick={async () => {
                requestProperty({
                  address: inputAddress,
                  email: userData.email,
                });
                onModalClose();
                onRequestSent()
              }}
            />
          )}
        </div>
      </div>
    </Modal>
  );
};

export default PropertySearchModal;
