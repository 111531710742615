import { useTranslation } from "react-i18next";
import DropdownSelect, { Option } from "./DropdownSelect";

const LanguageSelect = () => {
  const { t, i18n } = useTranslation();
  const options: Option[] = [
    {
      label: "English",
      value: "en-US",
    },
    {
      label: "中文",
      value: "zh-CN",
    },
  ];
  return (
    <DropdownSelect
      options={options}
      selectedOption={i18n.language}
      onOptionSelected={(value) => {
        i18n
          .changeLanguage(value)
          .then(() => {
            console.log(`Language changed to ${value}`);
          })
          .catch((error) => {
            console.error(`Failed to change language to ${value}:`, error);
          });
      }}
    />
  );
};

export default LanguageSelect;
