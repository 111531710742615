import { useState } from "react";
import ReactPlayer from "react-player";
import TopBar from "../components/TopBar";
import { ReactComponent as Call } from "../../static/Call.svg";
import { ReactComponent as Mail } from "../../static/Mail.svg";
import Button, { ButtonType } from "../components/Button";
import { ReactComponent as Video } from "../../static/Video.svg";
import ContactExpertButton from "../components/ContactExpertButton";
import {useTranslation} from "react-i18next";

function ListingAgentCommunicationGuide() {
  const { t, i18n } = useTranslation();

  const videoLinkMap = {
    "en-US": "https://app.heygen.com/embeds/67a5d0ee45f4443da9d270d78eaf107b",
    "zh-CN": "https://app.heygen.com/embeds/b7e46bda46f94cf48c2d21e49f4c53ff"
  }

  const handleCopy = () => {
    navigator.clipboard.writeText(t('priceNegotiationEmailTemplateValue'));
    alert("Email template copied to clipboard!"); // Optional feedback for users
  };

  return (
    <div className="flex flex-col items-center">
        <TopBar/>

        <div className="flex flex-col w-full px-5 mb-28">
            <div className="flex flex-col gap-y-1 mb-10">
                <div className="text-xl font-bold text-[#161A1D]">
                    {t('newListingAgentCommunicationGuideTitle')}
                </div>
                <div className="text-sm font-normal text-[#161A1D]">
                    {t('newListingAgentCommunicationGuideSubTitle')}
                </div>
            </div>
            <div className="mb-10">
                <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white">
                    <div className="pb-2 w-[300px]">
                        <div className="inline-flex pr-2 text-sm font-bold text-black bg-[#F6F7F8] w-auto">🧑‍🏫 {t('newListingAgentCommunicationGuideOverviewTitle')}</div>
                    </div>
                    <div className="flex flex-col items-center grow basis-0">
                    <iframe width="560" height="315" src={videoLinkMap[i18n.language as keyof typeof videoLinkMap] ?? videoLinkMap["zh-CN"]} title="HeyGen video player" frameBorder="0" allow="encrypted-media; fullscreen;" allowFullScreen></iframe>
                    </div>
                </div>
            </div>
            <div className="mb-10">
                <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white">
                    <div className="pb-2 w-[300px]">
                        <div className="inline-flex pr-2 text-sm font-bold text-black bg-[#F6F7F8] w-auto">📅 {t('newListingAgentCommunicationGuidePreOfferTitle')}</div>
                    </div>
                    <div className="flex flex-col items-start grow basis-0">
                        <div className="text-sm font-semibold text-[#161A1D]">{t('newListingAgentCommunicationGuidePreOfferDueTitle')}</div>
                        <ul className="list-disc list-inside">
                            <li className="text-sm font-normal text-[#5D6B79]">{t('newListingAgentCommunicationGuidePreOfferDueBulletPointOne')}</li>
                            <li className="text-sm font-normal text-[#5D6B79]">{t('newListingAgentCommunicationGuidePreOfferDueBulletPointTwo')}</li>
                        </ul>
                        <div className="text-sm font-semibold mt-2 text-[#161A1D]">{t('newListingAgentCommunicationGuidePreOfferPreemptiveTitle')}</div>
                        <ul className="list-disc list-inside">
                            <li className="text-sm font-normal text-[#5D6B79]">{t('newListingAgentCommunicationGuidePreOfferPreemptiveBulletPointOne')}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="mb-10">
                <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white">
                    <div className="pb-2 w-[300px]">
                        <div className="inline-flex pr-2 text-sm font-bold text-black bg-[#F6F7F8] w-auto">📝 {t('newListingAgentCommunicationGuideDraftOfferTitle')}</div>
                    </div>
                    <div className="flex flex-col items-start grow basis-0">
                        <div className="text-sm font-semibold text-[#161A1D]">{t('newListingAgentCommunicationGuideDraftOfferDemonstrateTitle')}</div>
                        <ul className="list-disc list-inside">
                            <li className="text-sm font-normal text-[#5D6B79]">{t('newListingAgentCommunicationGuideDraftOfferDemonstrateBulletPointOne')}</li>
                            <li className="text-sm font-normal text-[#5D6B79]">{t('newListingAgentCommunicationGuideDraftOfferDemonstrateBulletPointTwo')}</li>
                            <li className="text-sm font-normal text-[#5D6B79]">{t('newListingAgentCommunicationGuideDraftOfferDemonstrateBulletPointThree')}</li>
                        </ul>
                        <div className="w-full border border-dashed border-[#D1D6DC] bg-[#F6F7F8] rounded-[20px] flex flex-col mt-3 pb-3 pt-2 px-4 gap-2 text-gray-600">
                            <div className="flex items-center justify-between">
                                <div className="flex items-center gap-2">
                                    <Mail/>
                                    <div className="text-sm font-normal text-[#161A1D]">{t('newPriceNegotiationFirstOfferEmailTemplateTitle')}</div>
                                </div>
                                <div
                                    className="flex items-center border border-gray-300 rounded-[14px] px-3 py-1 space-x-1 bg-white hover:text-gray-700 cursor-pointer"
                                    onClick={handleCopy}
                                >
                                    <div className="text-sm font-normal text-[#161A1D]">{t('newListingAgentCommunicationGuideCopyLabel')}</div>
                                </div>
                            </div>
                            <div className="text-sm font-normal text-[#5D6B79]">
                                {t('newPriceNegotiationEmailTemplateValue')}
                            </div>
                        </div>
                        <div
                            className="w-full border border-dashed border-[#D1D6DC] bg-[#F6F7F8] rounded-[20px] flex flex-col mt-3 pb-3 pt-2 px-4 gap-2 text-gray-600">
                            <div className="flex items-center gap-2">
                                <Call/>
                                <div className="text-sm font-normal text-[#161A1D]">{t('newPriceNegotiationFirstOfferCommunicationTitle')}</div>
                            </div>
                            <ul className="px-5" style={{ listStyleType: "disc" }}>
                                <li className="text-sm font-normal text-[#5D6B79]">
                                    {t('newPriceNegotiationFirstOfferCommunicationBulletPointOne')}
                                </li>
                                <li className="text-sm font-normal text-[#5D6B79]">
                                    {t('newPriceNegotiationFirstOfferCommunicationBulletPointTwo')}
                                </li>
                                <li className="text-sm font-normal text-[#5D6B79]">
                                    {t('newPriceNegotiationFirstOfferCommunicationBulletPointThree')}
                                </li>
                                <li className="text-sm font-normal text-[#5D6B79]">
                                    {t('newPriceNegotiationFirstOfferCommunicationBulletPointFour')}
                                </li>
                                <li className="text-sm font-normal text-[#5D6B79]">
                                    {t('newPriceNegotiationFirstOfferCommunicationBulletPointFive')}
                                </li>
                                <li className="text-sm font-normal text-[#5D6B79]">
                                    {t('newPriceNegotiationFirstOfferCommunicationBulletPointSix')}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="mb-10">
                <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white">
                    <div className="pb-2 w-[300px]">
                        <div className="inline-flex pr-2 text-sm font-bold text-black bg-[#F6F7F8] w-auto">☎️ {t('newListingAgentCommunicationGuideSendOfferTitle')}</div>
                    </div>
                    <div className="flex flex-col items-start grow basis-0">
                        <div className="text-sm font-semibold text-[#161A1D]">{t('newListingAgentCommunicationGuideSendOfferConfirmationTitle')}</div>
                        <div className="text-sm font-normal text-[#5D6B79]">{t('newListingAgentCommunicationGuideSendOfferConfirmationSubTitle')}</div>
                        <div
                            className="w-full border border-dashed border-[#D1D6DC] bg-[#F6F7F8] rounded-[20px] flex flex-col mt-3 pb-3 pt-2 px-4 gap-2 text-gray-600">
                            <div className="flex items-center gap-2">
                                <Call/>
                                <div className="text-sm font-normal text-[#161A1D]">{t('newListingAgentCommunicationGuideSendOfferNoConfirmationTitle')}</div>
                            </div>
                            <ul className="px-5" style={{ listStyleType: "disc" }}>
                                <li className="text-sm font-normal text-[#5D6B79]">
                                    {t('newListingAgentCommunicationGuideSendOfferNoConfirmationBulletPointOne')}
                                </li>
                            </ul>
                        </div>
                        <div className="text-sm font-semibold text-[#161A1D] mt-4">{t('newListingAgentCommunicationGuideSendOfferActiveCommunicationTitle')}</div>
                        <div className="text-sm font-normal text-[#5D6B79]">{t('newListingAgentCommunicationGuideSendOfferActiveCommunicationSubTitle')}</div>
                        <div
                            className="w-full border border-dashed border-[#D1D6DC] bg-[#F6F7F8] rounded-[20px] flex flex-col mt-3 pb-3 pt-2 px-4 gap-2 text-gray-600">
                            <div className="flex items-center gap-2">
                                <Call/>
                                <div className="text-sm font-normal text-[#161A1D]">{t('newPriceNegotiationFirstOfferCommunicationTitle')}</div>
                            </div>
                            <ul className="px-5" style={{ listStyleType: "disc" }}>
                                <li className="text-sm font-normal text-[#5D6B79]">
                                    {t('newListingAgentCommunicationGuideSendOfferActiveCommunicationBulletPointOne')}
                                </li>
                                <li className="text-sm font-normal text-[#5D6B79]">
                                    {t('newListingAgentCommunicationGuideSendOfferActiveCommunicationBulletPointTwo')}
                                </li>
                                <li className="text-sm font-normal text-[#5D6B79]">
                                    {t('newListingAgentCommunicationGuideSendOfferActiveCommunicationBulletPointThree')}
                                </li>
                            </ul>
                        </div>
                        <div className="mt-3 text-sm font-semibold text-[#161A1D]">{t('newListingAgentCommunicationGuideFinalResultNoResponseTitle')}</div>
                        <ul className="list-disc list-inside">
                            <li className="text-sm font-normal text-[#5D6B79]">{t('newListingAgentCommunicationGuideFinalResultNoResponseBulletPointOnePartOne')}<span className="text-[#C40612]">{t('newListingAgentCommunicationGuideFinalResultNoResponseBulletPointOnePartTwo')}</span></li>
                            <li className="text-sm font-normal text-green-600">{t('newListingAgentCommunicationGuideFinalResultNoResponseBulletPointTwo')}</li>
                        </ul>
                    </div>
                </div>
            </div>
            <div>
                <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white">
                    <div className="pb-2 w-[300px]">
                        <div className="inline-flex pr-2 text-sm font-bold text-black bg-[#F6F7F8] w-auto">🏡 {t('newListingAgentCommunicationGuideFinalResultTitle')}</div>
                    </div>
                    <div className="flex flex-col items-start grow basis-0">
                        <div className="text-sm font-semibold text-gray-900">{t('newListingAgentCommunicationGuideFinalResultOfferRejectedTitle')}</div>
                        <div className="text-sm font-normal text-gray-600">{t('newListingAgentCommunicationGuideFinalResultOfferRejectedSubTitle')}</div>
                        {/*<div
                            className="w-full border border-dashed border-[#D1D6DC] bg-[#F6F7F8] rounded-[20px] flex flex-col mt-3 pb-3 pt-2 px-4 gap-2 text-gray-600">
                            <div className="flex items-center gap-2">
                                <Call/>
                                <div className="text-sm font-normal text-[#161A1D]">{t('newListingAgentCommunicationGuideFinalResultOfferRejectedRetryTitle')}</div>
                            </div>
                            <ul className="px-5" style={{ listStyleType: "disc" }}>
                                <li className="text-sm font-normal text-[#5D6B79]">
                                    {t('newListingAgentCommunicationGuideFinalResultOfferRejectedRetryBulletPointOne')}
                                </li>
                                <li className="text-sm font-normal text-[#5D6B79]">
                                    {t('newListingAgentCommunicationGuideFinalResultOfferRejectedRetryBulletPointTwo')}
                                </li>
                                <li className="text-sm font-normal text-[#5D6B79]">
                                    {t('newListingAgentCommunicationGuideFinalResultOfferRejectedRetryBulletPointThree')}
                                </li>
                            </ul>
                        </div>*/}
                        <div className="text-sm font-semibold mt-4 text-[#161A1D]">{t('newListingAgentCommunicationGuideFinalResultOfferAcceptedTitle')}</div>
                        <div className="text-sm font-normal text-[#5D6B79]">{t('newListingAgentCommunicationGuideFinalResultOfferAcceptedSubTitle')}</div>
                        <ul className="list-disc list-inside">
                            <li className="text-sm font-normal text-[#5D6B79]">{t('newListingAgentCommunicationGuideFinalResultOfferAcceptedBulletPointOne')}</li>
                            <li className="text-sm font-normal text-[#5D6B79]">{t('newListingAgentCommunicationGuideFinalResultOfferAcceptedBulletPointTwo')}</li>
                        </ul>
                        <div className="text-sm font-semibold mt-4 text-[#161A1D]">{t('newListingAgentCommunicationGuideFinalResultFinalResultTitle')}</div>
                        <ul className="list-disc list-inside">
                            <li className="text-sm font-normal text-gray-600">{t('newListingAgentCommunicationGuideFinalResultFinalResultBulletPointOne')}</li>
                            <li className="text-sm font-normal text-green-600">{t('newListingAgentCommunicationGuideFinalResultFinalResultBulletPointTwo')}</li>
                            <li className="text-sm font-normal text-gray-600">{t('newListingAgentCommunicationGuideFinalResultFinalResultBulletPointThree')}</li>
                        </ul>
                        <div
                            className="w-full border border-dashed border-[#D1D6DC] bg-[#F6F7F8] rounded-[20px] flex flex-col mt-3 pb-3 pt-2 px-4 gap-2 text-gray-600">
                            <div className="flex items-center gap-2">
                                <Call/>
                                <div className="text-sm font-normal text-[#161A1D]">{t('newListingAgentCommunicationGuideFinalResultFinalResultOutcomesTitle')}</div>
                            </div>
                            <ul className="px-5" style={{ listStyleType: "disc" }}>
                                <li className="text-sm font-normal text-[#5D6B79]">
                                    {t('newListingAgentCommunicationGuideFinalResultFinalResultOutcomesBulletPointOne')}
                                </li>
                                <li className="text-sm font-normal text-[#5D6B79]">
                                    {t('newListingAgentCommunicationGuideFinalResultFinalResultOutcomesBulletPointTwo')}
                                </li>
                            </ul>
                        </div>
                        <div className="text-sm font-semibold mt-4 text-[#161A1D]">{t('newListingAgentCommunicationGuideFinalResultSpecialCaseTitle')}</div>
                        <div className="text-sm font-normal text-[#5D6B79]">{t('newListingAgentCommunicationGuideFinalResultSpecialCaseSubTitle')}</div>
                    </div>
                </div>
            </div>
        </div>
        <div className="fixed right-5 bottom-5">
            <ContactExpertButton />
        </div>
    </div>
  );
}

export default ListingAgentCommunicationGuide;
