type Props = {
  content: string;
};

const WarningTag = ({ content }: Props) => {
  return (
    <div className="rounded-[10px] rounded-bl-[4px] bg-red-700 text-white px-2 py-0.5 text-xs font-semibold">
      {content}
    </div>
  );
};

export default WarningTag;
