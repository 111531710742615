import { useEffect, useRef, useState } from "react";
import { useHomeExpertConversation } from "../../query/useHomeExpertConversation";
import TopBar from "../components/TopBar";
import { axiosWithAuth } from "../../utils/axios";
import { useTranslation } from "react-i18next";
import icons from "../../static/ExpertChatIcons.png";
import Input from "../components/ChatInput";

type IntercomAuthor = {
  id: string;
  type: string;
  name: string;
  email: string;
};

type IntercomAttachment = {
  type: string;
  name: string;
  url: string;
  content_type: string;
  filesize: string;
  width?: number;
  height?: number;
};

type IntercomSource = {
  type: string;
  id: string;
  delivered_as: string;
  subject: string;
  body: string;
  author: IntercomAuthor;
  attachments: IntercomAttachment[];
  url?: string;
  redacted: boolean;
};

type WrappedIntercomSource = {
  source: IntercomSource;
  is_user: boolean;
};

type IntercomReference = {
  type: string;
  id?: string;
};

type IntercomConversationPart = {
  type: string;
  id: string;
  part_type: string;
  body: string;
  created_at: number;
  updated_at: number;
  notified_at: number;
  assigned_to?: IntercomReference;
  author: IntercomAuthor;
  attachments: IntercomAttachment[];
  external_id?: string;
  redacted: boolean;
};

type WrappedIntercomConversationPart = {
  conversation_part: IntercomConversationPart;
  is_user: boolean;
};

type IntercomConversation = {
  waiting_since?: number;
  snoozed_until?: number;
  open: boolean;
  state: string;
  read: boolean;
  wrapped_source: WrappedIntercomSource;
  wrapped_conversation_parts: WrappedIntercomConversationPart[];
};

const ExpertChatPage = () => {
  const { data, refetch } = useHomeExpertConversation();
  const [expertConversation, setExpertConversation] =
    useState<IntercomConversation | null>(data);
  const [expertQuestion, setExpertQuestion] = useState("");
  const axios = axiosWithAuth();
  const { t, i18n } = useTranslation();

  const formatFileSize = (size: string) => {
    const sizeInBytes = parseInt(size, 10); // Convert string to integer
    return `${(sizeInBytes / (1024 * 1024)).toFixed(2)} MB`;
  };

  const expertScrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Set interval to poll every 3 seconds
    const interval = setInterval(() => {
      refetch(); // Trigger a re-fetch of data
    }, 20000);

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [refetch]); // Only run the effect once on mount, and whenever refetch changes

  // Optionally update state when data changes
  useEffect(() => {
    if (!data?.read) {
      markExpertConversationAsRead();
    }
    setExpertConversation(data);
    if (expertScrollRef.current) {
      expertScrollRef.current.scrollTop = expertScrollRef.current.scrollHeight;
    }
  }, [data]);

  const handleExpertSubmit = async (question?: string) => {
    const text = question ? question : expertQuestion.toString();
    setExpertQuestion("");
    /*addToAIHistory([
            {
                role: 'human',
                content: text,
            },
            { role: 'ai', content: '' },
        ]);*/
    if (expertScrollRef.current) {
      expertScrollRef.current.style.overflow = "hidden";
    }

    const body = {
      user_input: text,
    };
    const { data } = await axios.post("ask_expert", body);

    console.log(data?.result);
    //const intercomConversation = data?.result as IntercomConversation
    setExpertConversation(data?.result as IntercomConversation);
    if (expertScrollRef.current) {
      expertScrollRef.current.scrollTop = expertScrollRef.current.scrollHeight;
    }

    if (expertScrollRef.current) {
      expertScrollRef.current.style.overflow = "auto";
    }
  };

  const markExpertConversationAsRead = async () => {
    if (expertScrollRef.current) {
      expertScrollRef.current.style.overflow = "hidden";
    }

    const { data } = await axios.get("mark_expert_conversation_read");
    console.log(data?.result);
    //const intercomConversation = data?.result as IntercomConversation
    setExpertConversation(data?.result as IntercomConversation);
    if (expertScrollRef.current) {
      expertScrollRef.current.scrollTop = expertScrollRef.current.scrollHeight;
    }

    if (expertScrollRef.current) {
      expertScrollRef.current.style.overflow = "auto";
    }
  };

  return (
    <div className="flex flex-col items-center h-full">
      <TopBar />
      <div className="w-full px-5 pb-5">
        <div className="px-4 py-5 rounded-[20px] bg-[url('static/ExpertChatBackground.png')] w-full flex flex-col items-center gap-5">
          <img className="h-[60px]" src={icons} />
          <div className="flex flex-col w-full gap-1 items-center">
            <div className="text-xl font-bold">
              {t("newContactExpertTitle")}
            </div>
            <div className="text-sm">
              {t(
                "newContactExpertSubTitle"
              )}
            </div>
          </div>
        </div>
      </div>
      <div
        ref={expertScrollRef}
        style={{
          flexGrow: 1,
          overflow: "auto",
          width: "100%",
          padding: 20,
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 12,
            width: "100%",
          }}
        >
          {expertConversation &&
            expertConversation.wrapped_source &&
            !expertConversation.wrapped_source.is_user && (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                  gap: 12,
                  minWidth: 0,
                }}
              >
                <div
                  style={{
                    borderRadius: "4px 20px 20px 20px",
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 8,
                    paddingBottom: 8,
                    fontWeight: 400,
                    maxWidth: "90%",
                    backgroundColor: "#F6F7F8",
                    minWidth: 0,
                    color: "#161A1D",
                  }}
                  className="conversation"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: expertConversation.wrapped_source.source.body,
                    }}
                  />
                </div>
              </div>
            )}
          {expertConversation &&
            expertConversation.wrapped_source &&
            expertConversation.wrapped_source.is_user && (
              <div
                style={{
                  display: "flex",
                  alignItems: "flex-start",
                  width: "100%",
                  gap: 12,
                  flexDirection: "row-reverse",
                  minWidth: 0,
                }}
              >
                <div
                  style={{
                    borderRadius: "20px 4px 20px 20px",
                    paddingLeft: 16,
                    paddingRight: 16,
                    paddingTop: 8,
                    paddingBottom: 8,
                    color: "white",
                    fontWeight: 400,
                    maxWidth: 750,
                    backgroundColor: "#454F5A",
                    minWidth: 0,
                  }}
                  className="conversation"
                >
                  <div
                    dangerouslySetInnerHTML={{
                      __html: expertConversation.wrapped_source.source.body,
                    }}
                  />
                </div>
              </div>
            )}
          {expertConversation &&
            expertConversation.wrapped_conversation_parts.length > 0 &&
            expertConversation.wrapped_conversation_parts.map(
              (wrapped_conversation_part: WrappedIntercomConversationPart) => {
                if (!wrapped_conversation_part.is_user) {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        width: "100%",
                        gap: 12,
                        minWidth: 0,
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "4px 20px 20px 20px",
                          padding: 12,
                          fontWeight: 400,
                          maxWidth: "90%",
                          backgroundColor: "#F6F7F8",
                          minWidth: 0,
                          color: "#161A1D",
                        }}
                        className="conversation"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              wrapped_conversation_part.conversation_part.body,
                          }}
                        />
                        {wrapped_conversation_part.conversation_part.attachments.map(
                          (attachment) => (
                            <div className="flex items-center justify-between bg-white border rounded-[4px] pl-2 pt-[6px] pr-2 pb-[6px] mt-2 w-full max-w-md">
                              {/* Attachment icon and file name */}
                              <div className="flex items-center space-x-2 overflow-hidden">
                                {/* Paperclip icon */}
                                {/*<AiOutlinePaperClip className="text-white" size={18}/>*/}
                                <img
                                  src="https://home8-gswe.onrender.com/attachment-icon.png"
                                  alt="Home"
                                  style={{
                                    width:
                                      "24px" /* Adjust the size of the icon */,
                                    height: "24px",
                                  }}
                                />

                                {/* File name and link */}
                                <a
                                  href={attachment.url}
                                  className="text-blue-500 text-sm font-weight-400 truncate no-underline hover:underline"
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {attachment.name}
                                </a>
                              </div>

                              {/* File size */}
                              <span className="text-gray-600 text-sm ml-3 whitespace-nowrap">
                                {formatFileSize(attachment.filesize)}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  );
                } else {
                  return (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        width: "100%",
                        gap: 12,
                        flexDirection: "row-reverse",
                        minWidth: 0,
                      }}
                    >
                      <div
                        style={{
                          borderRadius: "20px 4px 20px 20px",
                          paddingLeft: 16,
                          paddingRight: 16,
                          paddingTop: 8,
                          paddingBottom: 8,
                          color: "white",
                          fontWeight: 400,
                          maxWidth: 750,
                          backgroundColor: "#454F5A",
                          minWidth: 0,
                        }}
                        className="conversation"
                      >
                        <div
                          dangerouslySetInnerHTML={{
                            __html:
                              wrapped_conversation_part.conversation_part.body,
                          }}
                        />
                      </div>
                    </div>
                  );
                }
              }
            )}
        </div>
      </div>
      <div className="p-5 w-full">
        <Input
          className="w-full text-black h-[40px] text-xs bg-white"
          placeholder={t("newAskAnythingAboutThisPropertyLabel")}
          value={expertQuestion}
          isError={false}
          onChange={(e) => {
            setExpertQuestion((e.target as HTMLInputElement).value);
          }}
          onFinish={handleExpertSubmit}
        />
      </div>
    </div>
  );
};

export default ExpertChatPage;
