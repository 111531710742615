import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import { useHomePropertyDetail } from "../../query/useHomePropertyDetail";
import { Property, PropertyWithProblemOverview } from "../../store/agentInfo";
import TopBar from "../components/TopBar";
import {
  propertyStatusDarkMap,
  propertyStatusMap,
} from "../utils/propertyStatus";
import moment from "moment";
import Button, { ButtonType } from "../components/Button";
import { ReactComponent as DownloadDisclosure } from "../../static/DownloadDisclosure.svg";
import { ReactComponent as WriteOffer } from "../../static/WriteOffer.svg";
import { ReactComponent as ListingAgent } from "../../static/ListingAgent.svg";
import { ReactComponent as Strategy } from "../../static/Strategy.svg";
import { ReactComponent as Calculator } from "../../static/Calculator.svg";
import { useEffect, useRef, useState } from "react";
import Input from "../components/ChatInput";
import ContactExpertButton from "../components/ContactExpertButton";
import { useHomePropertyWithProblemOverview } from "../../query/useHomePropertyWithProblemOverview";
import remarkGfm from "remark-gfm";
import ReactMarkdown from "react-markdown";
import React from "react";
import CustomReactionMarkdown from "../../home8/CustomReactMarkdown";
import { BeatLoader } from "react-spinners";
import { axiosWithAuth } from "../../utils/axios";
import Cookies from "js-cookie";
import { useCompareSalesData } from "../../query/useCompareSalesData";
import RequestDisclosureModal from "../components/RequestDisclosureModal";
import NotificationModal from "../components/NotificationModal";
import { ReactComponent as Success } from "../../static/Success.svg";

type ChatSentence = {
  role: "human" | "ai";
  content: string;
  content_type: "text";
};

const ReportPage = () => {
  const { propertyId } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { data: compareSalesData, refetch: refetchCompareSalesData } = useCompareSalesData({
    propertyId: propertyId ?? "",
  });
  const { data, isFetching, isLoading, refetch } =
    useHomePropertyWithProblemOverview(propertyId, i18n.language);
  const propertyProblemOverviewInfo = data as PropertyWithProblemOverview;
  const [chatInput, setChatInput] = useState("");
  const aiScrollRef = useRef<HTMLDivElement>(null);
  const [aiChatHistory, setAIChatHistory] = useState<ChatSentence[]>([]);
  const axios = axiosWithAuth();
  const [showSuggestions, setShowSuggestions] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

  const suggestions = [
    {
      label: t("newReportPageSuggestionQuestionInspectionLabel"),
      message: {
        content_type: "text",
        content: t("newReportPageSuggestionQuestionInspectionContent"),
        role: "human",
      },
    },
    {
      label: t("newReportPageSuggestionQuestionSellerDisclosureLabel"),
      message: {
        content_type: "text",
        content: t("newReportPageSuggestionQuestionSellerDisclosureContent"),
        role: "human",
      },
    },
    {
      label: t("newReportPageSuggestionQuestionMismatchLabel"),
      message: {
        content_type: "text",
        content: t("newReportPageSuggestionQuestionMismatchContent"),
        role: "human",
      },
    },
    {
      label: t("newReportPageSuggestionQuestionLegalAndTitleLabel"),
      message: {
        content_type: "text",
        content: t("newReportPageSuggestionQuestionLegalAndTitleContent"),
        role: "human",
      },
    },
    {
      label: t("newReportPageSuggestionQuestionNaturalHazardLabel"),
      message: {
        content_type: "text",
        content: t("newReportPageSuggestionQuestionNaturalHazardContent"),
        role: "human",
      },
    },
  ];

  useEffect(() => {
    axios
      .get(`/property_chat_history_v2/${propertyId}`)
      .then((res) => {
        if (res.data.code != -1) {
          setAIChatHistory(res.data.result.conversation);
        }
      })
      .catch(() => {
        setAIChatHistory([]);
      });
  }, []);

  const handleAISubmit = async (question?: ChatSentence) => {
    const sentence: ChatSentence = question
      ? question
      : {
          role: "human",
          content: chatInput.toString(),
          content_type: "text",
        };
    setChatInput("");
    addToAIHistory([
      sentence,
      { role: "ai", content: "", content_type: "text" },
    ]);
    if (aiScrollRef.current) {
      aiScrollRef.current.style.overflow = "hidden";
    }

    const token = Cookies.get("auth");

    const response = await fetch(
      "https://home8-backend.onrender.com/property_chat_v2",
      {
        method: "POST",
        body: JSON.stringify({
          property_id: propertyId,
          user_input: sentence,
          chat_history: aiChatHistory.map((sentence) => {
            return {
              role: sentence.role,
              content: sentence.content,
              content_type: sentence.content_type,
            };
          }),
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: token ? `Bearer ${token}` : "",
        },
      }
    );

    if (aiScrollRef.current) {
      aiScrollRef.current.scrollTop = aiScrollRef.current.scrollHeight;
    }
    const reader = response.body
      ?.pipeThrough(new TextDecoderStream())
      .getReader();
    if (reader) {
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          setAIChatHistory((history) => {
            const newHistory = [...history];
            try {
              fetch(
                "https://home8-backend.onrender.com/save_property_chat_v2",
                {
                  method: "POST",
                  body: JSON.stringify({
                    property_id: propertyId,
                    conversation: newHistory.map((sentence) => {
                      return {
                        role: sentence.role,
                        content: sentence.content,
                        content_type: sentence.content_type,
                      };
                    }),
                  }),
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: token ? `Bearer ${token}` : "",
                  },
                }
              );
            } catch {}
            return newHistory;
          });
          if (aiScrollRef.current) {
            aiScrollRef.current.scrollTop = aiScrollRef.current.scrollHeight;
          }

          break;
        }
        setAIChatHistory((history) => {
          const newHistory = [...history];
          if (process.env.NODE_ENV === "development") {
            if (!newHistory[newHistory.length - 1].content.endsWith(value)) {
              newHistory[newHistory.length - 1].content += value;
            }
          } else {
            newHistory[newHistory.length - 1].content += value;
          }
          return newHistory;
        });
        await timeout(100);
        if (aiScrollRef.current) {
          aiScrollRef.current.scrollTop = aiScrollRef.current.scrollHeight;
        }
      }
    }
    if (aiScrollRef.current) {
      aiScrollRef.current.style.overflow = "auto";
    }
  };

  const addToAIHistory = (content: ChatSentence[]) =>
    setAIChatHistory((history) => {
      return [...history, ...content];
    });

  function timeout(ms: number) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }
  const LinkRenderer = ({ node, ...props }: any) => (
    <a href={props.href} target="_blank" rel="noopener noreferrer">
      {props.children}
    </a>
  );

  const tableColumns = [
    {
      title: t("newReportPageBedroomLabel"),
      renderer: (property: Property) => property.bedrooms,
    },
    {
      title: t("newReportPageBathroomLabel"),
      renderer: (property: Property) =>
        property.full_bathrooms + property.half_bathrooms,
    },
    {
      title: t("newReportPageLivingAreaLabel"),
      renderer: (property: Property) =>
        `${property.living_sqft} ${t("sqftLabel")}`,
    },
    {
      title: t("newReportPageLotSizeLabel"),
      renderer: (property: Property) =>
        `${property.lot_size} ${t("sqftLabel")}`,
    },
    {
      title: t("newReportPagePropertyTypeLabel"),
      renderer: (property: Property) => property.property_type,
    },
    {
      title: t("newReportPageYearBuiltLabel"),
      renderer: (property: Property) => property.year_built,
    },
    {
      title: t("newReportPageHOALabel"),
      renderer: (property: Property) =>
        property.hoa_yn ? "Has HOA" : "No HOA",
    },
  ];

  if (!propertyProblemOverviewInfo) {
    return <></>;
  }

  const formattedDisclosureDownloadTime = propertyProblemOverviewInfo.property
    .disclosure_download_time
    ? new Date(
        propertyProblemOverviewInfo.property.disclosure_download_time
      ).toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      })
    : "N/A";

  const formattedUpdateTime = propertyProblemOverviewInfo.property
    .update_time
    ? new Date(
        propertyProblemOverviewInfo.property.update_time
      ).toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
        year: "numeric",
      })
    : "N/A";

  console.log(
    propertyProblemOverviewInfo.property
  );
  const statusData = propertyStatusDarkMap[
    propertyProblemOverviewInfo.property
      .status as keyof typeof propertyStatusMap
  ] ?? {
    label: "For sale",
    color: "green-300",
  };

  const onMarketDays = propertyProblemOverviewInfo.property.on_market_date 
    ? moment().diff(moment(propertyProblemOverviewInfo.property.on_market_date, 'YYYY-MM-DD'), 'days')
    : 'N/A';

  console.log(propertyProblemOverviewInfo.property.offer_due);
  return (
    <div className="flex flex-col items-center h-full">
      <TopBar />
      <div ref={aiScrollRef} className="w-full overflow-auto">
        <div
          className="w-full flex flex-col justify-between items-center bg-cover bg-no-repeat bg-center h-[530px] relative"
          style={{
            backgroundImage: `url(${propertyProblemOverviewInfo.property.property_thumbnail_url})`,
          }}
        >
          <div className="absolute inset-0 bg-[#00000010]" />
          <div className="relative w-full p-5 flex flex-col justify-between h-full">
            <div className="text-white w-full px-4 py-3 rounded-[20px] bg-[#00000033] backdrop-blur flex justify-between">
              <div className="flex flex-col gap-1">
                <div className="text-xl font-bold text-white">
                  {propertyProblemOverviewInfo.property.address}
                </div>
                <div className={`text-sm font-bold text-${statusData.color}`}>
                  {statusData.label}{" "}
                  <span className="text-sm font-normal text-white">
                    {onMarketDays} days,{" "}
                    {propertyProblemOverviewInfo.property.auto_offer_due && 
                     (!propertyProblemOverviewInfo.property.offer_due || propertyProblemOverviewInfo.property.offer_due === "N/A" || propertyProblemOverviewInfo.property.offer_due === "")
                      ? `offer due on ${propertyProblemOverviewInfo.property.auto_offer_due}`
                      : propertyProblemOverviewInfo.property.offer_due === "N/A" ||
                        !propertyProblemOverviewInfo.property.offer_due || 
                        propertyProblemOverviewInfo.property.offer_due === ""
                        ? "offer due TBD"
                        : propertyProblemOverviewInfo.property.offer_due === "any_time"
                          ? "accept offer at any time"
                          : `offer due on ${new Date(
                              propertyProblemOverviewInfo.property.offer_due
                            ).toLocaleString("en-US", {
                              month: "short",
                              day: "2-digit",
                              year: "numeric",
                              hour: "numeric",
                              minute: "2-digit",
                              timeZoneName: "short"
                            })}`}
                  </span>
                </div>
              </div>
              <div className="flex gap-2">
                <Button
                  type={ButtonType.SECONDARY}
                  Icon={DownloadDisclosure}
                  loading={false}
                  disabled={
                    !propertyProblemOverviewInfo.property.full_disclosure_download_url &&
                    propertyProblemOverviewInfo.property.report_generation_status !== "created" &&
                    //propertyProblemOverviewInfo.property.report_generation_status !== "disclosure_downloaded" &&
                    propertyProblemOverviewInfo.property.report_generation_status !== "failed_auto_disclosure_download"
                  }
                  content={t("newReportPageDownloadDisclosureLabel")}
                  subContent={`${t("newReportPageDownloadDisclosureLastUpdateLabel")}: ${
                    propertyProblemOverviewInfo.property.report_generation_status === "created" || 
                    propertyProblemOverviewInfo.property.report_generation_status === "failed_auto_disclosure_download"
                      ? formattedUpdateTime
                      : formattedDisclosureDownloadTime
                  }`}
                  onClick={() => {
                    if (propertyProblemOverviewInfo.property.report_generation_status === "created" || propertyProblemOverviewInfo.property.report_generation_status === "disclosure_downloaded" || propertyProblemOverviewInfo.property.report_generation_status === "failed_auto_disclosure_download") {
                      setIsModalOpen(true);
                    } else {
                      window.open(
                        propertyProblemOverviewInfo.property
                          .full_disclosure_download_url,
                        "_blank"
                      );
                    }
                  }}
                />
                <Button
                  type={ButtonType.SECONDARY}
                  Icon={WriteOffer}
                  loading={false}
                  disabled={false}
                  content={t("newReportPageWriteOfferLabel")}
                  subContent={t("newReportPageWriteOfferNote")}
                  onClick={() => {
                    window.open(
                      `/home/offer_draft?property_id=${propertyId}`,
                      "_blank"
                    );
                  }}
                />
              </div>
            </div>
            <div className="text-white flex flex-col gap-5 w-full">
              <div className="w-full px-4 py-3 rounded-[20px] bg-[#00000033] backdrop-blur flex">
                {tableColumns.map((column) => (
                  <div className="flex flex-col items-start gap-1 grow text-xs text-white">
                    <div>{column.title}</div>
                    <div className="font-bold">
                      {column.renderer(propertyProblemOverviewInfo.property)}
                    </div>
                  </div>
                ))}
              </div>
              <div className="w-full flex gap-2">
                <div className="basis-0 grow px-4 py-3 rounded-[20px] bg-[#00000033] backdrop-blur flex">
                  <div className="flex flex-col justify-between h-full w-full">
                    <div className="flex flex-col w-full text-sm gap-2 text-white">
                      <div className="flex justify-between font-bold">
                        <div>{t("newReportPageListingAgentLabel")}</div>
                        <div>
                          {
                            propertyProblemOverviewInfo.property
                              .listing_agent_name
                          }
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div>{t("newReportPageListingAgentPhoneLabel")}</div>
                        <div>
                          {
                            propertyProblemOverviewInfo.property
                              .listing_agent_phone_number
                          }
                        </div>
                      </div>
                      <div className="flex justify-between">
                        <div>{t("newReportPageListingAgentEmailLabel")}</div>
                        <div>
                          {
                            propertyProblemOverviewInfo.property
                              .listing_agent_email
                          }
                        </div>
                      </div>
                    </div>
                    <Button
                      block
                      type={ButtonType.SECONDARY}
                      Icon={ListingAgent}
                      loading={false}
                      disabled={false}
                      content={t("newReportPageListingAgentGuideLabel")}
                      subContent={t("newReportPageListingAgentGuideSubLabel")}
                      onClick={() => {
                        window.open(`/home/la_communication_guide`, "_blank");
                      }}
                    />
                  </div>
                </div>
                <div className="basis-0 grow px-4 py-3 rounded-[20px] bg-[#00000033] backdrop-blur flex">
                  <div className="flex flex-col justify-between h-full w-full gap-4">
                    <div className="flex flex-col w-full items-center">
                      <div className="text-sm">
                        {t("newReportPageListingPriceLabel")}
                      </div>
                      <div className="text-xl font-bold">
                        {propertyProblemOverviewInfo.property.listing_price.toLocaleString(
                          "en-US",
                          {
                            style: "currency",
                            currency: "USD",
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 0,
                          }
                        )}
                      </div>
                    </div>
                    {compareSalesData && compareSalesData.expert_estimate_price_min && compareSalesData.expert_estimate_price && <div className="flex flex-col w-full gap-2 text-sm">
                      <div className="flex w-full justify-between">
                        <div>{t("newReportPageHome8PriceEstimateLabel")}</div>
                        <div>
                          {compareSalesData.expert_estimate_price_min.toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }
                          )} - {compareSalesData.expert_estimate_price.toLocaleString(
                              "en-US",
                              {
                                style: "currency",
                                currency: "USD",
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            }
                          )}
                        </div>
                      </div>
                    </div>}
                    <div className="flex flex-col w-full gap-2">
                      <Button
                        block
                        type={ButtonType.SECONDARY}
                        Icon={Strategy}
                        loading={false}
                        disabled={false}
                        content={t("newReportPagePricingStrategyLabel")}
                        subContent={t("newReportPagePricingStrategySubLabel")}
                        onClick={() => {
                          window.open(
                            `/home/pricing_strategy/${propertyId}`,
                            "_blank"
                          );
                        }}
                      />
                      <Button
                        block
                        type={ButtonType.SECONDARY}
                        Icon={Calculator}
                        loading={false}
                        disabled={false}
                        content={t("newReportPageFinancialCalculationLabel")}
                        subContent={t(
                          "newReportPageFinancialCalculationSubLabel"
                        )}
                        onClick={() => {
                          window.open(
                            `/home/calculator/${propertyId}`,
                            "_blank"
                          );
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="p-5 w-full">
          {propertyProblemOverviewInfo.property && propertyProblemOverviewInfo.property.report_generation_status === "created" && (
            <div className="text-sm text-gray-900 mb-5">
              {t("newSearchPropertyNoDisclosureAvailableNotePart1")} ({t("newReportPageDownloadDisclosureLastUpdateLabel")}: {formattedUpdateTime}){t("newPeriod")} {t("newSearchPropertyNoDisclosureAvailableNotePart2")}
            </div>
          )}
          {propertyProblemOverviewInfo.property && (propertyProblemOverviewInfo.property.report_generation_status === "disclosure_downloaded" || propertyProblemOverviewInfo.property.report_generation_status === "failed_auto_disclosure_download") && (
            <div className="text-sm text-gray-900 mb-5">
              {t("newReportPagePendingDisclosureNote")}
            </div>
          )}
          {propertyProblemOverviewInfo.property_problem_overview &&
            propertyProblemOverviewInfo.property_problem_overview
              .inspection_report_summary && (
              <div>
                <div className="flex justify-items-start mb-2">
                  <div className="pl-2 pr-2 pt-0.5 pb-0.5 w-auto bg-gray-50 rounded-lg">
                    <div className="flex">
                      <div className="text-sm font-bold text-gray-900">
                        🏠 {t("newReportPageHomeInspectionLabel")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ml-7 mb-5">
                  <CustomReactionMarkdown
                    content={
                      propertyProblemOverviewInfo.property_problem_overview
                        .inspection_report_summary
                    }
                  />
                </div>
              </div>
            )}
          {propertyProblemOverviewInfo.property_problem_overview &&
            propertyProblemOverviewInfo.property_problem_overview
              .termite_report_summary && (
              <div>
                <div className="flex justify-items-start mb-2">
                  <div className="pl-2 pr-2 pt-0.5 pb-0.5 w-auto bg-gray-50 rounded-lg">
                    <div className="flex">
                      <div className="text-sm font-bold text-gray-900">
                        🐞 {t("newReportPageTermiteInspectionLabel")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ml-7 mb-5">
                  <div className="text-sm font-normal text-[#5D6B79]">
                    {
                      propertyProblemOverviewInfo.property_problem_overview
                        .termite_report_summary
                    }
                  </div>
                </div>
              </div>
            )}
          {propertyProblemOverviewInfo.property_problem_overview &&
            propertyProblemOverviewInfo.property_problem_overview
              .spq_summary && (
              <div>
                <div className="flex justify-items-start mb-2">
                  <div className="pl-2 pr-2 pt-0.5 pb-0.5 w-auto bg-gray-50 rounded-lg">
                    <div className="flex">
                      <div className="text-sm font-bold text-gray-900">
                        📋 {t("newReportPageSellerDisclosureLabel")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ml-7 mb-5">
                  <CustomReactionMarkdown
                    content={
                      propertyProblemOverviewInfo.property_problem_overview
                        .spq_summary
                    }
                  />
                  {propertyProblemOverviewInfo.property_problem_overview
                    .tds_summary && (
                    <CustomReactionMarkdown
                      content={
                        propertyProblemOverviewInfo.property_problem_overview
                          .tds_summary
                      }
                    />
                  )}
                </div>
              </div>
            )}
          {propertyProblemOverviewInfo.property_problem_overview &&
            propertyProblemOverviewInfo.property_problem_overview
              .prelim_summary && (
              <div>
                <div className="flex justify-items-start mb-2">
                  <div className="pl-2 pr-2 pt-0.5 pb-0.5 w-auto bg-gray-50 rounded-lg">
                    <div className="flex">
                      <div className="text-sm font-bold text-gray-900">
                        ⚖️ {t("newReportPageLagelAndTitleLabel")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ml-7 mb-5">
                  <div className="text-sm font-normal text-[#5D6B79]">
                    {
                      propertyProblemOverviewInfo.property_problem_overview
                        .prelim_summary
                    }
                  </div>
                </div>
              </div>
            )}
          {propertyProblemOverviewInfo.property_problem_overview &&
            propertyProblemOverviewInfo.property_problem_overview
              .nhd_summary && (
              <div>
                <div className="flex justify-items-start mb-2">
                  <div className="pl-2 pr-2 pt-0.5 pb-0.5 w-auto bg-gray-50 rounded-lg">
                    <div className="flex">
                      <div className="text-sm font-bold text-gray-900">
                        🌪 {t("newReportPageNaturalHazardLabel")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="ml-7 mb-5">
                  <div className="text-sm font-normal text-[#5D6B79]">
                    {
                      propertyProblemOverviewInfo.property_problem_overview
                        .nhd_summary
                    }
                  </div>
                </div>
              </div>
            )}
          {propertyProblemOverviewInfo.property_problem_overview && (
            <div>
              <div className="flex justify-items-start mb-2">
                <div className="pl-2 pr-2 pt-0.5 pb-0.5 w-auto bg-gray-50 rounded-lg">
                  <div className="flex">
                    <div className="text-sm font-bold text-gray-900">
                      🔢 {t("newReportPageMismatchLabel")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="ml-7 mb-5">
                {propertyProblemOverviewInfo.property_problem_overview
                  .mismatch_summary ? (
                  <div className="text-sm font-normal text-[#5D6B79]">
                    {
                      propertyProblemOverviewInfo.property_problem_overview
                        .mismatch_summary
                    }
                  </div>
                ) : (
                  <div className="text-sm font-normal text-[#5D6B79]">
                    Unknown
                  </div>
                )}
              </div>
            </div>
          )}
          {propertyProblemOverviewInfo.property && propertyProblemOverviewInfo.property.listing_agent_notes && (
            <div>
              <div className="flex justify-items-start mb-2">
                <div className="pl-2 pr-2 pt-0.5 pb-0.5 w-auto bg-gray-50 rounded-lg">
                  <div className="flex">
                    <div className="text-sm font-bold text-gray-900">
                      📝 {t("newReportPagePrivateRemarksLabel")}
                    </div>
                  </div>
                </div>
              </div>
              <div className="ml-7 mb-5">
                  <div className="text-sm font-normal text-[#5D6B79]">
                    {
                      propertyProblemOverviewInfo.property.listing_agent_notes
                    }
                  </div>
              </div>
            </div>
          )}
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 12,
            width: "100%",
            paddingLeft: 20,
            paddingRight: 20,
            marginBottom: 72,
          }}
        >
          {aiChatHistory.map((message: any) => {
            if (message.role !== "human" && message.role != "ai") {
              return null;
            }
            if (message.role === "ai") {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "100%",
                    gap: 12,
                    minWidth: 0,
                  }}
                >
                  <div
                    style={{
                      borderRadius: "4px 20px 20px 20px",
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 8,
                      paddingBottom: 8,
                      fontWeight: 400,
                      maxWidth: "90%",
                      backgroundColor: "#F6F7F8",
                      minWidth: 0,
                      color: "#161A1D",
                    }}
                    className="conversation"
                  >
                    {message.content ? (
                      <ReactMarkdown
                        remarkPlugins={[remarkGfm]}
                        components={{
                          a: LinkRenderer,
                        }}
                      >
                        {message.content}
                      </ReactMarkdown>
                    ) : (
                      <BeatLoader size={6} color="#D9D9D9" />
                    )}
                  </div>
                </div>
              );
            } else {
              return (
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: "100%",
                    gap: 12,
                    flexDirection: "row-reverse",
                    minWidth: 0,
                  }}
                >
                  <div
                    style={{
                      borderRadius: "20px 4px 20px 20px",
                      paddingLeft: 16,
                      paddingRight: 16,
                      paddingTop: 8,
                      paddingBottom: 8,
                      color: "white",
                      fontWeight: 400,
                      maxWidth: 750,
                      backgroundColor: "#454F5A",
                      minWidth: 0,
                    }}
                    className="conversation"
                  >
                    {message.content ? (
                      <ReactMarkdown
                        components={{
                          a: LinkRenderer,
                        }}
                      >
                        {message.content}
                      </ReactMarkdown>
                    ) : (
                      <BeatLoader size={6} />
                    )}
                  </div>
                </div>
              );
            }
          })}
        </div>
      </div>
      <div className="flex flex-col gap-5 items-end p-5 w-full relative">
        <div className="absolute top-[-56px] right-5">
          <ContactExpertButton />
        </div>
        {showSuggestions && (
          <div className="flex self-start gap-2">
            {suggestions.map((suggestion, index) => (
              <div
                tabIndex={0}
                id={`suggestion-${index}`}
                className="py-3 px-4 rounded-[30px] cursor-pointer text-sm bg-blue-50"
                onClick={(e) => {
                  handleAISubmit(suggestion.message as ChatSentence);
                  setShowSuggestions(false);
                }}
              >
                {suggestion.label}
              </div>
            ))}
          </div>
        )}
        {(propertyProblemOverviewInfo.property.report_generation_status === "auto_published" || propertyProblemOverviewInfo.property.report_generation_status === "manual_published") && <Input
          className="w-full text-black h-[40px] text-xs bg-white"
          placeholder={t("newAskAnythingAboutThisPropertyLabel")}
          value={chatInput}
          isError={false}
          onChange={(e) => {
            setChatInput((e.target as HTMLInputElement).value);
          }}
          onFinish={handleAISubmit}
          onFocus={() => setShowSuggestions(true)}
          onBlur={(e) => {
            if (!e.relatedTarget?.id.startsWith("suggestion-")) {
              setShowSuggestions(false);
            }
          }}
        />}
      </div>
      <NotificationModal
        isModalOpen={isNotificationModalOpen}
        onModalClose={() => {
          setIsNotificationModalOpen(false);
        }}
        title={t("newNotificationModalRequestSentTitle")}
        subTitle={t("newReportPageNotificationModalSentSubTitle")}
        Icon={Success}
      />
      {propertyProblemOverviewInfo.property && propertyProblemOverviewInfo.property.report_generation_status === "created" && (
        <RequestDisclosureModal
          inputAddress={propertyProblemOverviewInfo.property.address}
          isOpen={isModalOpen}
          title={t("newReportPageNoDisclosureRequestDisclosureTitle")}
          subtitle={formattedUpdateTime}
          handleModalClose={() => {
          setIsModalOpen(false);
        }}
          onRequestSent={() => setIsNotificationModalOpen(true)}
        />
      )}
      {propertyProblemOverviewInfo.property && (propertyProblemOverviewInfo.property.report_generation_status === "disclosure_downloaded" || propertyProblemOverviewInfo.property.report_generation_status === "failed_auto_disclosure_download") && (
        <RequestDisclosureModal
          inputAddress={propertyProblemOverviewInfo.property.address}
          isOpen={isModalOpen}
          title={t("newReportPagePendingDisclosureRequestDisclosureTitle")}
          subtitle={t("newReportPagePendingDisclosureRequestDisclosureSubtitle")}
          handleModalClose={() => {
          setIsModalOpen(false);
        }}
          onRequestSent={() => setIsNotificationModalOpen(true)}
        />
      )}
    </div>
  );
};

export default ReportPage;
