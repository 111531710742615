import classNames from "classnames";
import { ReactComponent as Left } from "../../static/Left.svg";
import { ReactComponent as Right } from "../../static/Right.svg";
import { MoonLoader } from "react-spinners";

export type TableColumn = {
  columnName: string;
  renderer: (data: any) => JSX.Element;
  className?: string;
  onClick?: (data: any) => void;
};

type Props = {
  columns: TableColumn[];
  data: any[];
  pageNum?: number;
  total?: number;
  limit?: number;
  allowPagination: boolean;
  onPageNext?: () => void;
  onPageBack?: () => void;
  loading: boolean;
  tableHeight?: number;
};

const Table = ({
  columns,
  data,
  pageNum = 0,
  total = 0,
  limit = 0,
  onPageBack,
  onPageNext,
  loading,
  allowPagination,
  tableHeight = 68,
}: Props) => {
  const startNum = pageNum * limit + 1;
  const endNum = Math.min(total, (pageNum + 1) * limit);
  const canGoBack = pageNum > 0;
  const canGoNext = (pageNum + 1) * limit < total;
  console.log(data);
  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full">
        {!loading && data ? (
          columns.map((column, index) => {
            const columnIndex = index;
            return (
              <div className={`flex-col ${column.className}`}>
                <div
                  className={classNames(
                    "p-4 bg-gray-50 text-sm font-semibold border-y border-solid border-gray-200 whitespace-nowrap",
                    {
                      "border-l rounded-tl-[20px]": columnIndex === 0,
                      "border-r rounded-tr-[20px]":
                        columnIndex === columns.length - 1,
                    }
                  )}
                >
                  {column.columnName}
                </div>
                {data?.map((rowData, index) => (
                  <div
                    onClick={() => column.onClick?.(rowData)}
                    style={{ height: tableHeight }}
                    className={classNames("p-4", {
                      "border-l": columnIndex === 0,
                      "border-r": columnIndex === columns.length - 1,
                      "rounded-bl-[20px]":
                        columnIndex === 0 &&
                        index === data.length - 1 &&
                        !allowPagination,
                      "rounded-br-[20px]":
                        columnIndex === columns.length - 1 &&
                        index === data.length - 1 &&
                        !allowPagination,
                      "border-b": index === data.length - 1 && !allowPagination,
                    })}
                  >
                    {column.renderer(rowData)}
                  </div>
                ))}
              </div>
            );
          })
        ) : (
          <div className="w-full">
            <div className="flex w-full">
              {columns.map((column, index) => (
                <div className={`flex-col ${column.className}`}>
                  <div
                    className={classNames(
                      "p-4 bg-gray-50 text-sm font-semibold border-y border-solid border-gray-200 whitespace-nowrap",
                      {
                        "border-l rounded-tl-[20px]": index === 0,
                        "border-r rounded-tr-[20px]":
                          index === columns.length - 1,
                      }
                    )}
                  >
                    {column.columnName}
                  </div>
                </div>
              ))}
            </div>
            <div className="h-[340px] w-full flex items-center justify-center border-x border-solid border-gray-200">
              <MoonLoader size={24} />
            </div>
          </div>
        )}
      </div>
      {!Boolean(data?.length) && (
        <div
          style={{ height: tableHeight }}
          className="p-4 text-sm w-full flex items-center border-x border-b border-solid border-gray-200 rounded-b-[20px]"
        >
          No data
        </div>
      )}
      {allowPagination && Boolean(data?.length) && (
        <div className="border border-solid border-gray-200 rounded-b-[20px] px-4 py-3 flex justify-end w-full gap-2 items-center">
          {Boolean(total) && (
            <div className="text-xs text-gray-600">{`${startNum}-${endNum} of ${total}`}</div>
          )}
          <Left
            fill={canGoBack ? "#5D6B79" : "#D1D6DC"}
            onClick={() => {
              if (canGoBack) {
                onPageBack?.();
              }
            }}
            className={classNames({
              "cursor-pointer": canGoBack,
              "cursor-not-allowed": !canGoBack,
            })}
          />
          <Right
            fill={canGoNext ? "#5D6B79" : "#D1D6DC"}
            onClick={() => {
              if (canGoNext) {
                onPageNext?.();
              }
            }}
            className={classNames({
              "cursor-pointer": canGoNext,
              "cursor-not-allowed": !canGoNext,
            })}
          />
        </div>
      )}
    </div>
  );
};

export default Table;
