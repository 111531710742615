import classNames from "classnames";
import { ReactComponent as Discount } from "../../static/Discount.svg";
import { ReactComponent as Yes } from "../../static/Yes.svg";
import { ReactComponent as No } from "../../static/No.svg";
import { ReactComponent as HumanYes } from "../../static/HumanYes.svg";
import { useTranslation } from "react-i18next";
import Button from "../components/Button";
import { ButtonType } from "./Button";

type Props = {
  tableData: TableData;
};

export type Recommendation = {
  text?: string;
  include?: boolean;
}

export type Closing = {
  text?: string;
  include?: boolean;
}

export type PostTransaction = {
  text?: string;
  include?: boolean;
}

export type FinancialPreparation = {
  text?: string;
  include?: boolean;
}

export type Disclosure = {
  text?: string;
  include?: boolean;
}

export type Offer = {
  text?: string;
  include?: boolean;
}

export type TableData = {
  headers: {
    title: string;
    subTitle?: string;
  }[];
  discounts: {
    discount: boolean;
  }[];
  prices: {
    price: string;
    subTitle?: string;
    subSubTitle?: string;
  }[];
  saleLinks: {
    link?: string;
  }[];
  financialPreparation: {
    rowName: string;
    data: FinancialPreparation[];
  }[];
  recommendations: {
    rowName: string;
    data: Recommendation[];
  }[];
  disclosures: {
    rowName: string;
    data: Disclosure[];
  }[];
  offers: {
    rowName: string;
    data: Offer[];
  }[];
  closings: {
    rowName: string;
    data: Closing[];
  }[];
  postTransactions: {
    rowName: string;
    data: PostTransaction[];
  }[];
  tableData: {
    rowName: string;
    data: string[];
  }[];
};

function ServicePricingTable({ tableData }: Props) {
    const { t, i18n } = useTranslation();
  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full items-stretch gap-2 px-2">
        <div className="grow basis-0"></div>
        {tableData.headers.map((header) => (
          <div className="flex flex-col py-3 items-center grow basis-0 border border-gray-200 border-b-0 bg-[#F6F7F8] rounded-tl-2xl rounded-tr-2xl">
              <div className="text-xs font-bold text-[#161A1D]">{t(header.title)}</div>
              {header.subTitle && (
                <div className="text-[#5D6B79] text-xs font-normal text-center">
                {t(header.subTitle)}
                </div>
              )}
          </div>
        ))}
      </div>
      <div className="flex w-full items-stretch gap-2 px-2">
        <div className="grow basis-0"></div>
        {tableData.discounts.map((discount) => (
          <div className={classNames(
            "flex flex-col items-center grow basis-0 border border-gray-200 border-t-0 border-b-0",
            {
              "bg-[#E4FBF3]": discount.discount,
              "bg-white": !discount.discount
            }
          )}>
              {discount.discount ? (
                <div className="flex items-center text-center bg-[#E4FBF3] py-1">
                    <Discount className="h-5 w-5 mr-1" /> <span className="text-[#13986A] text-xs font-normal">{t("pricingPageDiscountTitle")}</span>
                </div>
              ) : (
                <div className="text-[#5D6B79] text-xs font-normal text-center">
                
                </div>
              )}
          </div>
        ))}
      </div>
      <div className="flex w-full items-stretch gap-2 px-2">
        <div className="grow basis-0"></div>
        {tableData.prices.map((price) => (
          <div className="flex flex-col pt-6 items-center grow basis-0 border border-gray-200 border-t-0 border-b-0 bg-white">
              <div className="text-xl font-bold text-[#161A1D]">{t(price.price)}</div>
              {price.subTitle && (
                <div className="text-[#5D6B79] text-xs font-normal text-center">
                {t(price.subTitle)}
                </div>
              )}
              {price.subSubTitle && (
                <div className="text-[#5D6B79] text-xs font-normal text-center">
                {t(price.subSubTitle)}
                </div>
              )}
          </div>
        ))}
      </div>
      <div className="flex w-full items-stretch gap-2 px-2">
        <div className="grow basis-0"></div>
        {tableData.saleLinks.map((saleLink) => (
          <div className="flex flex-col pt-5 pb-3 items-center grow basis-0 border border-gray-200 border-t-0 bg-white">
              {saleLink.link && (
                <div
                  className={classNames(
                    `bg-blue-500 hover:bg-blue-600 min-w-36 rounded-[14px] px-16 py-1 flex items-center cursor-pointer justify-between max-w-48`,
                  )}
                  onClick={async () => {
                    window.open(
                      `${saleLink.link}`,
                      '_blank',
                    );
                  }}
                >
                  <div className="flex flex-col items-center gap-1 grow">
                    <div className={`text-sm font-normal text-white`}>
                      {t("pricingPageContactSalesLabel")}
                    </div>
                  </div>
                </div>
              )}
          </div>
        ))}
      </div>

      <div>
          <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white px-2">
            <div className="grow basis-0 pt-5 pb-2">
              <div className="inline-flex pl-2 pr-2 text-sm font-bold text-black bg-[#F6F7F8] w-auto">💰 {t('pricingPageFinancialPreparationTitle')}</div>
            </div>
            <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
            <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
            <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
          </div>
        </div>
      {tableData.financialPreparation.map((data, index) => (
        <div>
          <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white px-2">
            <div className="grow basis-0 pb-2">
              <div className="pl-7 text-sm font-normal text-[#5D6B79]">{t(data.rowName)}</div>
            </div>
            {data.data.map((include) => (
              <div className="flex flex-col items-center grow basis-0 border-x border-solid">
                {include.text === "human_yes" ? (
                  <div className="flex items-center justify-center h-full">
                    <HumanYes className="h-5 w-5" />
                  </div>
                ) : (
                  include.include ? (
                    <div className="flex items-center justify-center h-full">
                    <Yes className="h-5 w-5" />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center h-full">
                      <No className="h-5 w-5" />
                    </div>
                  )
                )}
              </div>
            ))}
          </div>
        </div>
      ))}

      <div>
        <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white px-2">
          <div className="grow basis-0 pt-5 pb-2 min-w-96">
            <div className="inline-flex pl-2 pr-2 text-sm font-bold text-black bg-[#F6F7F8] w-auto">🏘 {t('pricingPageRecommendationTitle')}</div>
          </div>
          <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
          <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
          <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
        </div>
      </div>
      {tableData.recommendations.map((data, index) => (
        <div>
          <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white px-2">
            <div className="grow basis-0 pb-2">
              <div className="pl-7 text-sm font-normal text-[#5D6B79]">{t(data.rowName)}</div>
            </div>
            {data.data.map((include) => (
              <div className={classNames(
                "flex flex-col items-center grow basis-0 border-x border-solid",
                {
                  "bg-[#F6F7F8]": include.text && include.text !== "human_yes",
                  "bg-white": !include.text || include.text === "human_yes"
                }
              )}>
                {include.text === "human_yes" ? (
                  <div className="flex items-center justify-center h-full">
                    <HumanYes className="h-5 w-5" />
                  </div>
                ) : include.text ? (
                  <div className="flex items-center justify-center h-full px-2 text-xs font-normal text-[#5D6B79]">{t(include.text)}</div>
                ) : include.include ? (
                  <div className="flex items-center justify-center h-full">
                    <Yes className="h-5 w-5" />
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full">
                    <No className="h-5 w-5" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}

      <div>
        <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white px-2">
          <div className="grow basis-0 pt-5 pb-2 min-w-96">
            <div className="inline-flex pl-2 pr-2 text-sm font-bold text-black bg-[#F6F7F8] w-auto">🔍 {t('pricingPageDisclosureTitle')}</div>
          </div>
          <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
          <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
          <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
        </div>
      </div>
      {tableData.disclosures.map((data, index) => (
        <div>
          <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white px-2">
            <div className="grow basis-0 pb-2">
              <div className="pl-7 text-sm font-normal text-[#5D6B79]">{t(data.rowName)}</div>
            </div>
            {data.data.map((include) => (
              <div className="flex flex-col items-center grow basis-0 border-x border-solid">
                {include.text === "human_yes" ? (
                  <div className="flex items-center justify-center h-full">
                    <HumanYes className="h-5 w-5" />
                  </div>
                ) : (
                  include.include ? (
                    <div className="flex items-center justify-center h-full">
                    <Yes className="h-5 w-5" />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center h-full">
                      <No className="h-5 w-5" />
                    </div>
                  )
                )}
              </div>
            ))}
          </div>
        </div>
      ))}

      <div>
        <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white px-2">
          <div className="grow basis-0 pt-5 pb-2 min-w-96">
            <div className="inline-flex pl-2 pr-2 text-sm font-bold text-black bg-[#F6F7F8] w-auto">📋 {t('pricingPageOffersTitle')}</div>
          </div>
          <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
          <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
          <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
        </div>
      </div>
      {tableData.offers.map((data, index) => (
        <div>
          <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white px-2">
            <div className="grow basis-0 pb-2">
              <div className="pl-7 text-sm font-normal text-[#5D6B79]">{t(data.rowName)}</div>
            </div>
            {data.data.map((include) => (
              <div className="flex flex-col items-center grow basis-0 border-x border-solid">
                {include.text === "human_yes" ? (
                  <div className="flex items-center justify-center h-full">
                    <HumanYes className="h-5 w-5" />
                  </div>
                ) : (
                  include.include ? (
                    <div className="flex items-center justify-center h-full">
                    <Yes className="h-5 w-5" />
                    </div>
                  ) : (
                    <div className="flex items-center justify-center h-full">
                      <No className="h-5 w-5" />
                    </div>
                  )
                )}
              </div>
            ))}
          </div>
        </div>
      ))}

      <div>
        <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white px-2">
          <div className="grow basis-0 pt-5 pb-2 min-w-96">
            <div className="inline-flex pl-2 pr-2 text-sm font-bold text-black bg-[#F6F7F8] w-auto">📋 {t('pricingPageClosingTitle')}</div>
          </div>
          <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
          <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
          <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
        </div>
      </div>
      {tableData.closings.map((data, index) => (
        <div>
          <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white px-2">
            <div className="grow basis-0 pb-2">
              <div className="pl-7 text-sm font-normal text-[#5D6B79]">{t(data.rowName)}</div>
            </div>
            {data.data.map((include) => (
              <div className={classNames(
                "flex flex-col items-center grow basis-0 border-x border-solid",
                {
                  "bg-[#F6F7F8]": include.text && include.text !== "human_yes",
                  "bg-white": !include.text || include.text === "human_yes"
                }
              )}>
                {include.text === "human_yes" ? (
                  <div className="flex items-center justify-center h-full">
                    <HumanYes className="h-5 w-5" />
                  </div>
                ) : include.text ? (
                  <div className="flex items-center justify-center h-full px-2 text-xs font-normal text-[#5D6B79]">{t(include.text)}</div>
                ) : include.include ? (
                  <div className="flex items-center justify-center h-full">
                    <Yes className="h-5 w-5" />
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full">
                    <No className="h-5 w-5" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}

      <div>
        <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white px-2">
          <div className="grow basis-0 pt-5 pb-2 min-w-96">
            <div className="inline-flex pl-2 pr-2 text-sm font-bold text-black bg-[#F6F7F8] w-auto">🛋 {t('pricingPagePostTransactionTitle')}</div>
          </div>
          <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
          <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
          <div className="flex flex-col items-center grow basis-0 border-x border-solid"></div>
        </div>
      </div>
      {tableData.postTransactions.map((data, index) => (
        <div>
          <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white px-2">
            <div className="grow basis-0 pb-2">
              <div className="pl-7 text-sm font-normal text-[#5D6B79]">{t(data.rowName)}</div>
            </div>
            {data.data.map((include) => (
              <div className={classNames(
                "flex flex-col items-center grow basis-0 border-x border-solid",
                {
                  "bg-[#F6F7F8]": include.text && include.text !== "human_yes",
                  "bg-white": !include.text || include.text === "human_yes"
                }
              )}>
                {include.text === "human_yes" ? (
                  <div className="flex items-center justify-center h-full">
                    <HumanYes className="h-5 w-5" />
                  </div>
                ) : include.text ? (
                  <div className="flex items-center justify-center h-full px-2 text-xs font-normal text-[#5D6B79]">{t(include.text)}</div>
                ) : include.include ? (
                  <div className="flex items-center justify-center h-full">
                    <Yes className="h-5 w-5" />
                  </div>
                ) : (
                  <div className="flex items-center justify-center h-full">
                    <No className="h-5 w-5" />
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      ))}

      <div>
        <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white px-2">
          <div className="grow basis-0">
            <div className="pl-2 pr-2 text-sm font-bold text-black bg-[#F6F7F8] w-auto"></div>
          </div>
          <div className="flex flex-col items-center grow basis-0 border border-solid border-t-0 rounded-bl-2xl rounded-br-2xl"></div>
          <div className="flex flex-col items-center grow basis-0 border border-solid border-t-0 rounded-bl-2xl rounded-br-2xl"></div>
          <div className="flex flex-col items-center grow basis-0 border border-solid border-t-0 rounded-bl-2xl rounded-br-2xl"></div>
        </div>
      </div>
    </div>
  );
}

export default ServicePricingTable;
