import { useQuery } from "@tanstack/react-query";
import { axiosWithAuth } from "../utils/axios";

export const useHomePropertyWithProblemOverview = (propertyId?: string, lang?: string) => {
    const axios = axiosWithAuth();
    return useQuery({
        queryKey: ["home8_propertyWithProblemOverview", propertyId?.toString(), lang],
        queryFn: async () => {
            if (!propertyId) {
                return null;
            }
            try {
                const data = {
                    property_id: parseInt(propertyId),
                    lang: lang || "en-US",
                }
                const result = await axios.post('property_with_problem_overview', data)
                console.log(result.data.result)
                return result.data.result
            } catch (e: any) {
                return null;
            }
        },
        refetchOnWindowFocus: false,
    });
};
