import { useQuery } from "@tanstack/react-query";
import { axiosWithAuth } from "../utils/axios";

export const useCompareSalesData = ({ propertyId }: { propertyId: string }) => {
  const axios = axiosWithAuth();
  return useQuery({
    queryKey: ["home8_compare_sales", propertyId],
    queryFn: async () => {
      try {
        const { data } = await axios.get(
          `property/${propertyId}/compare_sales`
        );
        if (data.code === 200) {
          return data?.result;
        }
        return {
          compared_highest_property: null,
          compared_properties: [],
          compare_sales_expired_time: null,
          status: 0,
        };
      } catch (e: any) {
        return {
          compared_highest_property: null,
          compared_properties: [],
          compare_sales_expired_time: null,
          status: 0,
        };
      }
    },
    refetchOnWindowFocus: false,
  });
};
