import { useEffect, useState } from "react";
import { useTransactionDetails } from "../../query/useTransactionDetails";
import { ReactComponent as Document } from "../../static/Document.svg";
import PricingTable, { TableData } from "./PricingTable";
import classNames from "classnames";
import moment from "moment";
import TransactionCollapse from "./TransactionCollapse";

const tableData: TableData = {
  headers: [
    {
      title: "transactionManagementBasicService",
      subTitle: "transactionManagementBasicServicePrice",
    },
    {
      title: "transactionManagementAdvancedService",
      subTitle: "transactionManagementAdvancedServicePrice",
    },
    {
      title: "transactionManagementThirdPartyService",
      subTitle: "transactionManagementThirdPartyServicePrice",
    },
  ],
  tableData: [
    {
      rowName: "transactionManagementAgentSupport",
      data: [
        "transactionManagementAgentSupportBasic",
        "transactionManagementAgentSupportAdvanced",
        "transactionManagementAgentSupportThirdParty",
      ],
    },
    {
      rowName: "transactionManagementAgentInstruction",
      data: [
        "transactionManagementAgentInstructionBasic",
        "transactionManagementAgentInstructionAdvanced",
        "transactionManagementAgentInstructionThirdParty",
      ],
    },
    {
      rowName: "transactionManagementLenderSign",
      data: [
        "transactionManagementLenderSignBasic",
        "transactionManagementLenderSignAdvanced",
        "transactionManagementLenderSignThirdParty",
      ],
    },
    {
      rowName: "transactionManagementGrantDeed",
      data: [
        "transactionManagementGrantDeedBasic",
        "transactionManagementGrantDeedAdvanced",
        "transactionManagementGrantDeedThirdParty",
      ],
    },
    {
      rowName: "transactionManagementAgentAssist",
      data: [
        "transactionManagementAgentAssistBasic",
        "transactionManagementAgentAssistAdvanced",
        "transactionManagementAgentAssistThirdParty",
      ],
    },
    {
      rowName: "transactionManagementAgentInspect",
      data: [
        "transactionManagementAgentInspectBasic",
        "transactionManagementAgentInspectAdvanced",
        "transactionManagementAgentInspectThirdParty",
      ],
    },
    {
      rowName: "transactionManagementAgentPostSale",
      data: [
        "transactionManagementAgentPostSaleBasic",
        "transactionManagementAgentPostSaleAdvanced",
        "transactionManagementAgentPostSaleThirdParty",
      ],
    },
  ],
};

const TransactionSection = () => {
  const { data } = useTransactionDetails();
  const [isOpenState, setIsOpenState] = useState<number[]>([]);

  useEffect(() => {
    setIsOpenState((state) => {
      if (!state.length && data) {
        return Array(data.length).fill(0);
      } else {
        return state;
      }
    });
  }, [data]);

  if (!data) {
    return <></>;
  }
  if (!data?.length) {
    return <PricingTable tableData={tableData} />;
  }

  return (
    <div className="flex flex-col gap-4 w-full">
      {data.map((transactionData: any, index: number) => {
        const buyerName = transactionData.buyer_name;
        const buyerAgentInfo = transactionData.buying_agent_info;
        const sellerName = transactionData.seller_name;
        const listingAgentInfo = transactionData.listing_agent_info;
        const escrowInfo = transactionData.escrow_info;
        const propertyAddress = transactionData.property_address;
        const lenderInfo = transactionData.lender_info;
        const offerTerms = transactionData.offer_terms;
        const eventList = transactionData.event_list;
        const documents = transactionData.documents;

        if (transactionData.status === "completed") {
          return (
            <TransactionCollapse
              isOpen={Boolean(isOpenState[index])}
              title={propertyAddress}
              onToggle={() => {
                console.log("toggled");
                setIsOpenState((state: any) => {
                  console.log("old state:", state);
                  state[index] = 1 - state[index];
                  console.log("state:", state);
                  return [...state];
                });
              }}
            >
              <div className="flex flex-col p-4 gap-6 text-[14px] border-b border-solid border-gray-200">
                {documents.map((document: any) => (
                  <div className="flex justify-between">
                    <div className="flex gap-2 items-center">
                      <Document />
                      {document.name}
                    </div>
                    <div
                      className="flex items-center justify-center border border-solid border-gray-200 rounded-[14px] hover:bg-gray-100 cursor-pointer px-3 pt-[5px] pb-1.5"
                      onClick={() => {
                        if (!document.download_url) {
                          return;
                        }
                        fetch(document.download_url)
                          .then((response) => response.blob())
                          .then((blob) => {
                            const blobUrl = URL.createObjectURL(blob);
                            const link = document.createElement("a");
                            link.href = blobUrl;
                            link.download = document.name || "file.pdf";
                            document.body.appendChild(link);
                            link.click();
                            document.body.removeChild(link);
                            URL.revokeObjectURL(blobUrl);
                          })
                          .catch((error) =>
                            console.error("Download failed:", error)
                          );
                      }}
                    >
                      Download
                    </div>
                  </div>
                ))}
              </div>
              <div className="flex items-center text-[14px] p-4 justify-between rounded-b-[20px]">
                <div className="text-[12px] text-gray-600">{`Total: ${documents.length}`}</div>
                <div
                  className="flex items-center justify-center border border-solid border-gray-200 rounded-[14px] hover:bg-gray-100 cursor-pointer px-3 pt-[5px] pb-1.5"
                  onClick={() => {
                    documents.forEach((document: any) => {
                      if (!document.download_url) {
                        return;
                      }
                      fetch(document.download_url)
                        .then((response) => response.blob())
                        .then((blob) => {
                          const blobUrl = URL.createObjectURL(blob);
                          const link = document.createElement("a");
                          link.href = blobUrl;
                          link.download = document.name || "file.pdf";
                          document.body.appendChild(link);
                          link.click();
                          document.body.removeChild(link);
                          URL.revokeObjectURL(blobUrl);
                        })
                        .catch((error) =>
                          console.error("Download failed:", error)
                        );
                    });
                  }}
                >
                  Download all
                </div>
              </div>
            </TransactionCollapse>
          );
        }

        const processEventList = (eventList: any) => {
          if (!eventList.length) {
            return [];
          }
          const result = [];
          const list: any[] = Array.from(eventList);
          const firstEventTime = moment
            .unix(eventList[0].deadline_timestamp)
            .startOf("week");
          const lastEventTime = moment
            .unix(eventList[eventList.length - 1].deadline_timestamp)
            .endOf("week");
          let startTime = firstEventTime;
          let week = [];
          while (startTime < lastEventTime) {
            const events = [];
            while (
              list.length &&
              moment.unix(list[0].deadline_timestamp).isSame(startTime, "date")
            ) {
              const item = list.shift();
              events.push(item.notes);
            }
            week.push({
              events,
              moment: moment(startTime),
            });
            if (week.length === 7) {
              result.push(week);
              week = [];
            }
            startTime = startTime.add(1, "day");
          }
          return result;
        };
        const eventResult = processEventList(eventList);

        return (
          <div className="rounded-[20px] border border-solid border-gray-200 overflow-hidden">
            <div className="bg-gray-100 p-4 flex flex-col text-sm border-b border-solid border-gray-200">
              <div className="font-bold">{propertyAddress}</div>
              <div>{`Buyer: ${buyerName} ${!buyerAgentInfo ? "(no buyer agent represented)" : ""}`}</div>
              <div>{`Seller: ${sellerName} ${!listingAgentInfo ? "(no listing agent represented)" : ""}`}</div>
            </div>
            <div className="flex flex-col p-4 gap-4">
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(360px, 1fr))",
                  gridAutoRows: "152px",
                  gridGap: "8px",
                }}
              >
                {Boolean(buyerAgentInfo) && (
                  <div className="rounded-[20px] leading-[18px] border border-solid border-gray-200 p-4 flex flex-col gap-4">
                    <div className="text-[14px] font-semibold">
                      Buying agent
                    </div>
                    <div className="text-[14px]">
                      <div>{buyerAgentInfo.name}</div>
                      <div>{buyerAgentInfo.office}</div>
                      <div>{buyerAgentInfo.office_address}</div>
                      <div>{buyerAgentInfo.email}</div>
                      <div>{buyerAgentInfo.phone_number}</div>
                    </div>
                  </div>
                )}
                {Boolean(listingAgentInfo) && (
                  <div className="rounded-[20px] leading-[18px] border border-solid border-gray-200 p-4 flex flex-col gap-4">
                    <div className="text-[14px] font-semibold">
                      Listing agent
                    </div>
                    <div className="text-[14px]">
                      <div>{listingAgentInfo.name}</div>
                      <div>{listingAgentInfo.office}</div>
                      <div>{listingAgentInfo.office_address}</div>
                      <div>{listingAgentInfo.email}</div>
                      <div>{listingAgentInfo.phone_number}</div>
                    </div>
                  </div>
                )}
                {
                  <div className="rounded-[20px] leading-[18px] border border-solid border-gray-200 p-4 flex flex-col gap-4">
                    <div className="text-[14px] font-semibold">
                      Transaction management
                    </div>
                    <div className="text-[14px]">
                      <div>Home8.ai</div>
                      <div>support@home8.ai</div>
                    </div>
                  </div>
                }
                {Boolean(escrowInfo) && (
                  <div className="rounded-[20px] leading-[18px] border border-solid border-gray-200 p-4 flex flex-col gap-4">
                    <div className="text-[14px] font-semibold">{`Escrow ${escrowInfo.escrow_number}`}</div>
                    <div className="text-[14px]">
                      <div>{escrowInfo.agent_name}</div>
                      <div>{escrowInfo.company_name}</div>
                      <div>{escrowInfo.company_address}</div>
                      <div>{escrowInfo.agent_email}</div>
                      <div>{escrowInfo.agent_phone_number}</div>
                    </div>
                  </div>
                )}
                {Boolean(lenderInfo) && (
                  <div className="rounded-[20px] leading-[18px] border border-solid border-gray-200 p-4 flex flex-col gap-4">
                    <div className="text-[14px] font-semibold">
                      Lender agent
                    </div>
                    <div className="text-[14px]">
                      <div>{lenderInfo.loan_officer_name}</div>
                      <div>{lenderInfo.bank_name}</div>
                      <div>{lenderInfo.loan_officer_email}</div>
                      <div>{lenderInfo.loan_officer_phone_number}</div>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex flex-col rounded-[20px] border border-solid border-gray-200 overflow-hidden">
                <div className="bg-gray-100 p-4 flex text-sm border-b border-solid border-gray-200">
                  <div className="w-[200px] text-[14px] font-semibold">
                    Offer terms
                  </div>
                  <div className="text-[14px] font-semibold">Content</div>
                </div>
                <div className="flex flex-col p-4 gap-6 text-[14px]">
                  {offerTerms.map((term: any) => (
                    <div className="flex">
                      <div className="w-[200px] text-[14px]">
                        {term.term_name}
                      </div>
                      <div className="text-[14px]">{term.term_content}</div>
                    </div>
                  ))}
                </div>
              </div>
              <div className="bg-gray-100 rounded-[20px] p-4 flex flex-col gap-1">
                <div className="flex items-center gap-1 pb-1">
                  {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(
                    (value, index) => (
                      <div
                        className={classNames(
                          "text-[14px] font-semibold text-center",
                          {
                            "w-[66px]": index === 0 || index === 6,
                            "grow basis-0": index !== 0 && index !== 6,
                          }
                        )}
                      >
                        {value}
                      </div>
                    )
                  )}
                </div>
                {eventResult.map((result: any, index: number) => {
                  return (
                    <div key={index} className="flex items-stretch gap-1">
                      {result.map((value: any, index: number) => {
                        return (
                          <div
                            className={classNames("text-[14px]", {
                              "w-[66px]": index === 0 || index === 6,
                              "grow basis-0": index !== 0 && index !== 6,
                            })}
                          >
                            <div
                              className={classNames(
                                "rounded-xl flex flex-col p-2 h-full",
                                {
                                  "bg-white": !moment().isSame(
                                    value.moment,
                                    "date"
                                  ),
                                  "bg-blue-50": moment().isSame(
                                    value.moment,
                                    "date"
                                  ),
                                }
                              )}
                            >
                              <div
                                className={classNames({
                                  "font-bold": moment().isSame(
                                    value.moment,
                                    "date"
                                  ),
                                })}
                              >{`${value.moment.date()}${moment().isSame(value.moment, "date") ? " - Today" : ""}`}</div>
                              {index === 0 && (
                                <div>{value.moment.format("MMM")}</div>
                              )}
                              <div className="flex flex-col gap-1 py-2">
                                {value.events.map((value: string) => (
                                  <div className="bg-gray-100 rounded px-2 py-0.5">
                                    {value}
                                  </div>
                                ))}
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              <div className="flex flex-col rounded-[20px] border border-solid border-gray-200 overflow-hidden">
                <div className="bg-gray-100 p-4 flex text-sm border-b border-solid border-gray-200">
                  <div className="text-[14px] font-semibold">Documents</div>
                </div>
                <div className="flex flex-col p-4 gap-6 text-[14px] border-b border-solid border-gray-200">
                  {documents.map((transaction_document: any) => (
                    <div className="flex justify-between">
                      <div className="flex gap-2 items-center">
                        <Document />
                        {transaction_document.name}
                      </div>
                      <div
                        className="flex items-center justify-center border border-solid border-gray-200 rounded-[14px] hover:bg-gray-100 cursor-pointer px-3 pt-[5px] pb-1.5"
                        onClick={() => {
                          if (!transaction_document.download_url) {
                            return;
                          }
                          fetch(transaction_document.download_url)
                            .then((response) => response.blob())
                            .then((blob) => {
                              const blobUrl = URL.createObjectURL(blob);
                              const link = document.createElement("a");
                              link.href = blobUrl;
                              link.download =
                                transaction_document.name || "file.pdf";
                              document.body.appendChild(link);
                              link.click();
                              document.body.removeChild(link);
                              URL.revokeObjectURL(blobUrl);
                            })
                            .catch((error) =>
                              console.error("Download failed:", error)
                            );
                        }}
                      >
                        Download
                      </div>
                    </div>
                  ))}
                </div>
                <div className="flex items-center text-[14px] p-4 justify-between">
                  <div className="text-[12px] text-gray-600">{`Total: ${documents.length}`}</div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default TransactionSection;
