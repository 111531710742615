import Modal from "react-modal";
import { Property } from "../../store/agentInfo";
import { ReactComponent as Close } from "../../static/Close.svg";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import Button, { ButtonType } from "./Button";
import classNames from "classnames";
import { propertyStatusMap } from "../utils/propertyStatus";
import { useHomeRequestProperty } from "../../query/useHomeRequestProperty";
import { useHomeUser } from "../../query/useHomeUser";

export type Suggestion = {
  address: string;
  property: Property;
};

type Props = {
  inputAddress: string;
  title: string;
  subtitle: string;
  isOpen: boolean;
  handleModalClose: () => void;
  onRequestSent: () => void;
};

const RequestDisclosureModal = ({
  inputAddress,
  title,
  subtitle,
  isOpen,
  handleModalClose,
  onRequestSent,
}: Props) => {
  const { t, i18n } = useTranslation();
  const [selectedProperty, setSelectedProperty] = useState("");
  const { data: userData } = useHomeUser();
  const { mutateAsync: requestProperty } = useHomeRequestProperty();

  const onModalClose = () => {
    handleModalClose();
  };

  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      isOpen={isOpen}
      onRequestClose={onModalClose}
      style={{
        content: {
          width: 600,
          //height: 600,
          inset: "unset",
          padding: 0,
          border: "none",
          borderRadius: "16px",
          boxShadow:
            "0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)",
          overflow: "hidden",
          overflowY: "auto",
        },
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.55)",
          zIndex: 100,
        },
      }}
      htmlOpenClassName="overflow-hidden"
      shouldCloseOnEsc={true}
    >
      <div className="flex flex-col items-start justify-start h-full w-full p-5">
        <Close
          className="absolute top-5 right-5 cursor-pointer"
          onClick={onModalClose}
        />
        <div className="text-sm font-bold">{t("newReportPageRequestDisclosureLabel")}</div>
        <div className="flex flex-col items-center justify-start mt-10 mb-10 w-full">
          <div className="text-sm text-gray-900 font-semibold">
            {title}
          </div>
          <div className="text-sm text-gray-600 mt-2">
            {subtitle}
          </div>
        </div>
        <div className="flex w-full justify-end">
          <Button
              type={ButtonType.PRIMARY}
              loading={false}
              disabled={false}
              content={t("newReportPageRequestDisclosureModalButtonLabel")}
              onClick={async () => {
                requestProperty({
                  address: inputAddress,
                  email: userData.email,
                });
                onModalClose();
                onRequestSent()
              }}
            />
        </div>
      </div>
    </Modal>
  );
};

export default RequestDisclosureModal;
