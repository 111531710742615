import {useTranslation} from "react-i18next";


type Permit = {
    type: string
    description: string
    permit_classifications: string
    permit_number: string
    permit_status?: string
    permit_effective_date: string
    job_value: number
    contractors: string
};

function PermitCard({
    type,
    description,
    permit_classifications,
    permit_number,
    permit_status,
    permit_effective_date,
    job_value,
    contractors
}: Permit) {
    const styles = {
        card: {
            backgroundColor: '#F6F7F8', // Light background color similar to what appears in the image
            paddingTop: '12px',
            paddingLeft: '16px',
            borderRadius: '12px',
            maxWidth: '400px',
            fontFamily: 'Arial, sans-serif',
        },
        title: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700',
            marginBottom: '16px',
            color: '#161A1D'
        },
        label: {
            color: '#B1BAC3',
            marginRight: '12px'
        },
        cardList: {
            listStyleType: 'none',
            padding: 0,
        },
        cardItem: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            marginBottom: '8px',
            color: '#161A1D',
        },
    };
    const { t, i18n } = useTranslation();

    return (
        <div style={styles.card}>
            <h3 style={styles.title}>{type}</h3>
            {description &&
                <ul style={styles.cardList}>
                    <li style={styles.cardItem}><span style={styles.label}>●</span>{t('permitDescription')}: {description}</li>
                </ul>
            }
            {permit_classifications &&
                <ul style={styles.cardList}>
                    <li style={styles.cardItem}><span style={styles.label}>●</span>{t('permitClassifications')}: {permit_classifications}</li>
                </ul>
            }
            {permit_number &&
                <ul style={styles.cardList}>
                    <li style={styles.cardItem}><span style={styles.label}>●</span>{t('permitNumber')}: {permit_number}</li>
                </ul>
            }
            {permit_status &&
                <ul style={styles.cardList}>
                    <li style={styles.cardItem}><span style={styles.label}>●</span>{t('permitStatus')}: {permit_status}</li>
                </ul>
            }
            {permit_effective_date &&
                <ul style={styles.cardList}>
                    <li style={styles.cardItem}><span style={styles.label}>●</span>{t('permitStatusDate')}: {permit_effective_date}</li>
                </ul>
            }
            {job_value !== 0 &&
                <ul style={styles.cardList}>
                    <li style={styles.cardItem}><span style={styles.label}>●</span>{t('permitJobValue')}: {job_value}</li>
                </ul>
            }
            {contractors &&
                <ul style={styles.cardList}>
                    <li style={styles.cardItem}><span style={styles.label}>●</span>{t('permitContractors')}: {contractors}</li>
                </ul>
            }
        </div>
    );
}

export default PermitCard;