import GooglePlacesAutocomplete from "react-google-autocomplete";
import classnames from "classnames";
import { ReactComponent as UpArrow } from "../../static/UpArrow.svg";
import { ReactComponent as Search } from "../../static/Search.svg";
import { useRef, useState } from "react";
import { axiosPropertySearchWithAuth, axiosWithAuth } from "../../utils/axios";
import { MoonLoader } from "react-spinners";
import PropertySearchModal from "./PropertySearchModal";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { cp } from "fs";
import { useHomeUser } from "../../query/useHomeUser";
import NotificationModal from "./NotificationModal";
import { ReactComponent as Success } from "../../static/Success.svg";

const PropertySearchBar = () => {
  const [address, setAddress] = useState("");
  const canSubmit = Boolean(address);
  const [suggestionData, setSuggestionData] = useState([]);
  const [isFocused, setIsFocused] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { t } = useTranslation();
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState(false);

  const removeCountryFromAddress = (place: google.maps.places.PlaceResult) => {
    const addressComponents = place.formatted_address
      ?.split(",")
      .map((part) => part.trim());
    console.log(addressComponents);
    const countryComponentShortName = place.address_components?.find(
      (component) => component.types.includes("country")
    )?.short_name;

    const clearedAddress = addressComponents
      ?.filter(
        (component) =>
          component !== countryComponentShortName && component !== "USA"
      )
      .join(", ");
    console.log(clearedAddress);

    return clearedAddress;
  };

  const fetchSuggestionData = async () => {
    if (!address) {
      return;
    }
    setLoading(true);
    try {
      const axios = axiosPropertySearchWithAuth();
      const body = {
        address: address,
      };
      const { data } = await axios.post("property_search", body);
      setSuggestionData(data?.suggestions);
      setIsModalOpen(true);
    } catch (error) {
      //setError(error.message);
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  function expandAddressAbbreviations(address: string) {
    const abbreviations: Record<string, string> = {
      Ave: "Avenue",
      St: "Street",
      Rd: "Road",
      Blvd: "Boulevard",
    };
    const regex = new RegExp(`\\b(${Object.keys(abbreviations).join("|")})\\b`, "gi");
    return address.replace(regex, (matched: string) => abbreviations[matched]);
  }

  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div
      className={classnames(
        "border border-gray-200 border-solid rounded-[30px] h-[52px] pl-6 pr-3 py-3 w-full mb-2 flex items-center justify-between gap-2"
      )}
    >
      {!Boolean(address) && <Search />}
      <div className="flex flex-col w-full gap-1">
        {Boolean(address) && (
          <div className="text-gray-600 text-xs">
            {t("newSearchHistoryPropertySearchPlaceholder")}
          </div>
        )}
        <GooglePlacesAutocomplete
          apiKey="AIzaSyCTnqm8H8nbkWCJXYBOlOuAY7C83GHCe8A" // Optional, if already in the HTML script tag
          onPlaceSelected={(place) => {
            const result = removeCountryFromAddress(place);
            if (result) {
              const expandedAddress = expandAddressAbbreviations(result)
              setAddress(expandedAddress);
              // Focus using ref
              setTimeout(() => {
                inputRef.current?.focus();
              }, 0);
            }
          }}
          ref={inputRef}
          placeholder={t("newSearchHistoryPropertySearchPlaceholder")}
          className="border-none outline-none h-full w-full bg-transparent text-sm"
          options={{
            types: ["address"], // Limit results to addresses
            componentRestrictions: { country: "us" }, // Restrict results to the US (optional)
          }}
          inputAutocompleteValue={address}
          onChange={(e) => {
            setAddress((e.target as HTMLInputElement).value);
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter" && canSubmit && !loading) {
              e.preventDefault();
              fetchSuggestionData();
            }
          }}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
        />
      </div>
      <button
        onClick={fetchSuggestionData}
        //onKeyDown={(e) => {
        //  console.log(e.key);
        //  if (e.key === "Enter" && canSubmit && !loading) {
        //    e.preventDefault();
        //    fetchSuggestionData();
        //  }
        //}}
        //tabIndex={0}
        //role="button"
        className={classnames(
          "w-[34px] h-[34px] rounded-full shrink-0 flex items-center justify-center outline-none",
          {
            "cursor-pointer bg-blue-500 focus:ring-2 focus:ring-blue-300": canSubmit && !loading,
            "cursor-not-allowed bg-gray-200": !canSubmit || loading,
          }
        )}
      >
        {loading ? <MoonLoader size={12} /> : <UpArrow />}
      </button>
      <NotificationModal
        isModalOpen={isNotificationModalOpen}
        onModalClose={() => {
          setIsNotificationModalOpen(false);
        }}
        title={t("newNotificationModalRequestSentTitle")}
        subTitle={t("newNotificationModalRequestSentSubTitle")}
        Icon={Success}
      />
      <PropertySearchModal
        inputAddress={address}
        isOpen={isModalOpen}
        suggestionData={suggestionData}
        handleModalClose={() => {
          setIsModalOpen(false);
          setSuggestionData([]);
        }}
        onRequestSent={() => setIsNotificationModalOpen(true)}
      />
    </div>
  );
};

export default PropertySearchBar;
