import Modal from "react-modal";
import { OfferType } from "../../store/agentInfo";
import OfferCard, { PropertyStatus } from "./OfferCard";
import { useHomeOffers } from "../../query/useHomeOffers";
import { useEffect, useState } from "react";
import { ReactComponent as Close } from "../../static/Close.svg";
import { useTranslation } from "react-i18next";
import { useHomeUser } from "../../query/useHomeUser";
import { useHomeBuyingPower } from "../../query/useHomeBuyingPower";
import Button, { ButtonType } from "./Button";

const OfferSection = () => {
  const { data, refetch } = useHomeOffers();
  const [editPropertyModalOpen, setEditPropertyModalOpen] = useState(false);
  const [selectedEdit, setSelectedEdit] = useState("");
  const [selectedEditOfferId, setSelectedEditOfferId] = useState(-1);
  const [selectedEditPropertyId, setSelectedEditPropertyId] = useState("");
  const { t, i18n } = useTranslation();
  const { data: user } = useHomeUser();
  const { data: buyingPower } = useHomeBuyingPower(user?.id);

  const switchPropertyStatus = (status: string) => {
    if (status === "Active") {
      return PropertyStatus.ONSALE;
    }

    if (status === "Contingent") {
      return PropertyStatus.CONTINGENT;
    }

    if (status === "Pending") {
      return PropertyStatus.PENDING;
    }

    return PropertyStatus.SOLD;
  };

  const openEditPropertyModal = (offerId: number, propertyID: string) => {
    setEditPropertyModalOpen(true);
    setSelectedEditOfferId(offerId);
    setSelectedEditPropertyId(propertyID);
  };
  const closeEditPropertyModal = () => {
    setEditPropertyModalOpen(false);
    setSelectedEditPropertyId("");
    setSelectedEditOfferId(-1);
    setSelectedEdit("");
  };

  useEffect(() => {
    // Set interval to poll every 3 seconds
    const interval = setInterval(() => {
      refetch(); // Trigger a re-fetch of data
    }, 3000);

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, [refetch]);


  if (!data) {
    return <></>;
  }

  return (
    <div className={"flex flex-col w-full gap-3 h-full"}>
      <Modal
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        isOpen={editPropertyModalOpen}
        onRequestClose={() => {
          closeEditPropertyModal();
        }}
        style={{
          content: {
            width: 600,
            inset: "unset",
            padding: 0,
            border: "none",
            borderRadius: "16px",
            boxShadow:
              "0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)",
            overflow: "hidden",
          },
          overlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.55)",
            zIndex: 100,
          },
        }}
        htmlOpenClassName="overflow-hidden"
        shouldCloseOnEsc={true}
      >
        <div className="flex flex-col items-start justify-top h-full w-full p-5 gap-5">
          <Close
            className="absolute top-5 right-5 text-gray-900 cursor-pointer"
            onClick={() => {
              closeEditPropertyModal();
            }}
          />
          <div className="text-sm mb-5 font-bold">{t("offerEditLabel")}</div>
          <div className="flex gap-2">
            {/* Minimum Changes */}
            <div
              className={`flex-1 bg-gray-100 px-4 py-2 rounded-lg cursor-pointer border-2 ${
                selectedEdit === "minimum"
                  ? "border-blue-500"
                  : "border-gray-100"
              }`}
              onClick={() => setSelectedEdit("minimum")}
            >
              <div className="text-sm font-semibold mb-2 text-gray-900">
                {t("offerEditMinimumChangeLabel")}
              </div>
              <div className="text-xs font-normal text-gray-600">
                Minimum changes include:
              </div>
              <div className="text-xs font-normal text-gray-600">
                <div className="flex items-center text-xs font-normal">
                  <div className="bg-gray-600 h-[3px] w-[3px] rounded-full mx-2" />
                  {t("offerEditMinimumChangeOfferPriceLabel")}
                </div>
                <div className="flex items-center text-xs font-normal">
                  <div className="bg-gray-600 h-[3px] w-[3px] rounded-full mx-2" />
                  {t("offerEditMinimumChangeOfferExpirationLabel")}
                </div>
              </div>
              <div className="text-xs font-normal text-gray-600">
                No expert review required; you will receive the updated offer
                document <span className="text-xs font-bold">immediately</span>.
              </div>
            </div>

            {/* Significant Changes */}
            <div
              className={`flex-1 bg-gray-100 px-4 py-2 rounded-lg cursor-pointer border-2 ${
                selectedEdit === "significant"
                  ? "border-blue-500"
                  : "border-gray-100"
              }`}
              onClick={() => setSelectedEdit("significant")}
            >
              <div className="text-sm font-semibold mb-2 text-gray-900">
                {t("offerEditSignificantChangeLabel")}
              </div>
              <div className="text-xs font-normal text-gray-600">
                You can modify everything from your previous offer version. This
                update will require another round of expert review, which
                typically takes{" "}
                <span className="text-xs font-bold">3 business hours</span>.
              </div>
            </div>
          </div>

          <div className="flex w-full justify-end mt-5">
            <Button
              type={ButtonType.PRIMARY}
              disabled={selectedEdit === ""}
              content={t("offerContinueEditLabel")}
              onClick={async () => {
                //requestProperty({
                //  address: `${streetLineOne} ${streetLineTwo}, ${city}, ${state}, ${zipCode}`,
                //});

                window.open(
                  `/home/offer_draft?property_id=${selectedEditPropertyId}&offer_id=${selectedEditOfferId}&quick_price_check=${
                    selectedEdit === "minimum" ? "true" : "false"
                  }`,
                  "_blank"
                );
                closeEditPropertyModal();

                //setEditPropertyModalOpen(false);
                //setSelectedEdit('');
              }}
            />
          </div>
        </div>
      </Modal>
      {data.map((offer: OfferType) => {
        console.log(offer.last_offer_email_activities);
        if (offer.content !== "") {
          const content = JSON.parse(offer.content);
          const propertyStatus = switchPropertyStatus(offer.property.status);
          //console.log(offer.last_offer_email_activities)
          const address = `${content.address}, ${content.city}, ${content.state} ${content.zipcode}`;
          return (
            <OfferCard
              address={address}
              dueDate={content.offerExpireTime}
              status={offer.status}
              price={content.offerPrice}
              last_submitted_time={offer.last_submitted_time}
              offerID={offer.id}
              propertyID={offer.property_id}
              userID={user?.id ?? ""}
              offerExpiration={content.offerExpireTime}
              lastOfferEmailActivities={offer.last_offer_email_activities}
              propertyStatus={propertyStatus}
              onStatusUpdate={() => {
                setTimeout(refetch, 200);
              }}
              propertyContent={content}
              openEditPropertyModal={openEditPropertyModal}
              buyingPower={buyingPower}
              offerFailComment={offer.review_failure_comment}
            />
          );
        }
      })}
    </div>
  );
};

export default OfferSection;
