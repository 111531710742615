import classNames from "classnames";
import { useTranslation } from "react-i18next";

type Props = {
  tableData: TableData;
};

export type TableData = {
  headers: {
    title: string;
    subTitle?: string;
  }[];
  tableData: {
    rowName: string;
    data: string[];
  }[];
};

function PricingTable({ tableData }: Props) {
    const { t, i18n } = useTranslation();
  return (
    <div className="flex flex-col w-full">
      <div className="flex w-full items-stretch gap-2 px-2">
        <div className="grow basis-0"></div>
        {tableData.headers.map((header) => (
          <div className="flex flex-col py-4 items-center grow basis-0 border border-solid border-b-0 rounded-t-2xl gap-2">
            <div className="font-bold">{t(header.title)}</div>
            {header.subTitle && (
              <div className="text-gray-600 text-xs font-normal text-center">
                {t(header.subTitle)}
              </div>
            )}
          </div>
        ))}
      </div>
      {tableData.tableData.map((data, index) => (
        <div>
          <div className="flex w-full items-stretch gap-2 rounded-2xl bg-gray-50 px-2">
            <div className="grow basis-0 px-4 py-3">
              <div className="text-xs font-bold">{t(data.rowName)}</div>
            </div>
            {data.data.map((title) => (
              <div className="grow basis-0 px-4 py-3 border-x border-solid">
                <div className="text-xs font-normal">{t(title)}</div>
              </div>
            ))}
          </div>
          {index <= tableData.tableData.length - 1 && (
            <div
              className={classNames(
                "flex w-full items-stretch gap-2 rounded-2xl bg-transparent px-2",
                {
                  "h-2": index < tableData.tableData.length - 1,
                  "h-4": index === tableData.tableData.length - 1,
                }
              )}
            >
              <div className="grow basis-0"></div>
              {data.data.map((title) => (
                <div className={classNames("grow basis-0", {
                    'border-x border-solid': index < tableData.tableData.length - 1,
                    "border border-solid border-t-0 rounded-b-2xl": index === tableData.tableData.length - 1,
                })}></div>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
}

export default PricingTable;
