import { useNavigate } from "react-router-dom";
import LanguageSelect from "./LanguageSelect";
import Tag from "./Tag";
import { useTranslation } from "react-i18next";

const TopBar = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (
    <div className="flex justify-between items-center w-full p-5">
      <div
        className="text-xl font-bold cursor-pointer"
        onClick={() => {
          navigate("/");
        }}
      >
        Home8
      </div>
      <div className="flex items-center gap-2">
        <Tag
          content={t('newPricingPageTabTitle')}
          onClick={() => {
            window.open(`/home/pricing`, "_blank");
          }}
          selected={false}
        />
        <LanguageSelect />
      </div>
    </div>
  );
};

export default TopBar;
