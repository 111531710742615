type NatureHazardRisk = {
    issue: string
    status: string
    description: string
};

function NatureHazardRiskCard({
    issue,
    status,
    description
}: NatureHazardRisk) {
    const styles = {
        card: {
            backgroundColor: '#F6F7F8', // Light background color similar to what appears in the image
            paddingTop: '12px',
            paddingLeft: '16px',
            borderRadius: '12px',
            maxWidth: '400px',
            fontFamily: 'Arial, sans-serif',
        },
        title: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700',
        },
        label: {
            color: '#B1BAC3',
            marginRight: '12px'
        },
        value: {
            fontWeight: 'bold',
            color: '#333',
        },
        cardList: {
            listStyleType: 'none',
            padding: 0,
        },
        cardItem: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '400',
            marginBottom: '8px',
            color: '#161A1D',
        },
        header: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: '16px',
        },
        status: {
            fontSize: '14px',
            lineHeight: '18px',
            fontWeight: '700',
            color: '#C40612', // Orange color for the "Yes" indicator
        },
    };

    return (
        <div style={styles.card}>
            <div style={styles.header}>
                <h3 style={styles.title}>{issue}</h3>
                <span style={styles.status}>{status}</span>
            </div>
            <ul style={styles.cardList}>
                <li style={styles.cardItem}><span style={styles.label}>●</span>{description}</li>
            </ul>
        </div>
    );
}

export default NatureHazardRiskCard;