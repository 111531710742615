import { useHomeUpdateOfferStatus } from "../../query/useHomeUpdateOfferStatus";
import Button, { ButtonType } from "./Button";
import StepperItem, {
  EmailTrackerStatus,
  StepItemProps,
  StepStatus,
} from "./StepperItem";
import { useTranslation } from "react-i18next";
import { formatIsoTimestamp, formatUnixTimestamp } from "../../utils/timestamp";
import { ReactComponent as FileOffer } from "../../static/FileOffer.svg";
import { ReactComponent as Video } from "../../static/Video.svg";
import { ReactComponent as Close } from "../../static/Close.svg";
import { ReactComponent as ChatExpert } from "../../static/ChatExpert.svg";
import { ReactComponent as ListingAgent } from "../../static/ListingAgent.svg";
import Modal from "react-modal";
import { useRef, useState } from "react";
import e from "express";
import Home8Modal from "./Home8Modal";

export enum Status {
  INITIAL,
  DRAFTED,
  GENERATED,
  REVIEWPASS,
  REVIEWFAILURE,
  SIGNED,
  SUBMITTED,
  OFFERACCPETED,
  OFFERDECLINED,
  OFFEREXPIRED,
  OFFERFORFEIT,
  OFFERNEVERHEARDBACK,
}

export enum PropertyStatus {
  ONSALE,
  CONTINGENT,
  PENDING,
  SOLD,
}

type EmailActivity = {
  event: string;
  time: number;
  email?: string;
};

type Props = {
  address: string;
  dueDate: string;
  status: Status;
  price: number;
  last_submitted_time: number;
  userID: number;
  propertyID: string;
  offerID: number;
  offerExpiration?: number;
  lastOfferEmailActivities: EmailActivity[];
  propertyStatus: PropertyStatus;
  onStatusUpdate: () => void;
  propertyContent: any;
  openEditPropertyModal: (offerId: number, propertyID: string) => void;
  buyingPower: number;
  offerFailComment: string;
};

function OfferCard({
  address,
  dueDate,
  status,
  price,
  last_submitted_time,
  userID,
  propertyID,
  offerID,
  offerExpiration,
  propertyStatus,
  lastOfferEmailActivities,
  onStatusUpdate,
  propertyContent,
  openEditPropertyModal,
  buyingPower,
  offerFailComment,
}: Props) {
  console.log(dueDate);
  const { mutateAsync: updateStatus } = useHomeUpdateOfferStatus();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOfferModifyModalOpen, setIsOfferModifyModalOpen] = useState(false);
  const [isExpertAssistModalOpen, setIsExpertAssistModalOpen] = useState(false);
  const modalEventRef = useRef<(() => void) | null>(null);
  const { t, i18n } = useTranslation();
  const onClick = (event: () => void) => {
    if (propertyStatus !== PropertyStatus.ONSALE) {
      setIsModalOpen(true);
      modalEventRef.current = event;
    } else {
      event();
    }
  };

  const cardButton = () => {
    switch (status) {
      case Status.DRAFTED:
        return (
          <Button
            block
            type={ButtonType.PRIMARY}
            loading={false}
            disabled={false}
            content={t("offerDraftOfferContinueLabel")}
            onClick={async () => {
              onClick(() =>
                window.open(
                  `/home/offer_draft?property_id=${propertyID}&offer_id=${offerID}`,
                  "_blank"
                )
              );
            }}
          />
        );
      case Status.GENERATED:
        return (
          <Button
            block
            type={ButtonType.SECONDARY}
            loading={false}
            disabled={false}
            content={t("editLabel")}
            subContent={t("offerEditSubLabel")}
            onClick={async () => {
              onClick(() =>
                window.open(
                  `/home/offer_draft?property_id=${propertyID}&offer_id=${offerID}`,
                  "_blank"
                )
              );
            }}
          />
        );
      case Status.REVIEWPASS:
        return (
          <div className="flex flex-col gap-2 w-full">
            <Button
              block
              type={ButtonType.SECONDARY}
              loading={false}
              disabled={false}
              content={t("viewLabel")}
              onClick={async () => {
                onClick(() =>
                  window.open(`/home/offer_view?offer_id=${offerID}`, "_blank")
                );
              }}
            />
            <Button
              block
              type={ButtonType.SECONDARY}
              loading={false}
              disabled={false}
              content={t("editLabel")}
              subContent={buyingPower === 0 ? t("offerEditSubLabel") : ""}
              onClick={async () => {
                onClick(() =>
                  buyingPower === 0
                    ? window.open(
                        `/home/offer_draft?property_id=${propertyID}&offer_id=${offerID}`,
                        "_blank"
                      )
                    : openEditPropertyModal(offerID, propertyID)
                );
              }}
            />
            <Button
              block
              type={ButtonType.PRIMARY}
              loading={false}
              disabled={false}
              content={t("offerSignOfferRequireActionLabel")}
              onClick={async () => {
                onClick(() =>
                  window.open(`/home/offer_sign?offer_id=${offerID}`, "_blank")
                );
              }}
            />
          </div>
        );
      case Status.REVIEWFAILURE:
        return (
          <Button
            block
            type={ButtonType.PRIMARY}
            loading={false}
            disabled={false}
            content={"[Action required] Edit"}
            subContent={"Expert will provide you guidance"}
            onClick={() => onClick(() => setIsOfferModifyModalOpen(true))}
          />
        );

      default:
        return (
          <div className="flex flex-col items-center gap-2 w-full">
            <Button
              block
              type={ButtonType.SECONDARY}
              loading={false}
              disabled={false}
              content={t("viewLabel")}
              onClick={async () => {
                onClick(() =>
                  window.open(`/home/offer_view?offer_id=${offerID}`, "_blank")
                );
              }}
            />
            <Button
              block
              type={ButtonType.SECONDARY}
              loading={false}
              disabled={false}
              content={t("editLabel")}
              subContent={buyingPower === 0 ? t("offerEditSubLabel") : ""}
              onClick={async () => {
                onClick(() =>
                  buyingPower === 0
                    ? window.open(
                        `/home/offer_draft?property_id=${propertyID}&offer_id=${offerID}`,
                        "_blank"
                      )
                    : openEditPropertyModal(offerID, propertyID)
                );
              }}
            />
          </div>
        );
    }
  };

  const bottomBanner = () => {
    console.log(`banner status ${status}`);
    switch (status) {
      case Status.SIGNED:
        return (
          <div className="flex flex-col items-start gap-3 border-t border-gray-200 border-solid p-5">
            <div className="flex w-full items-center justify-end">
              <Button
                type={ButtonType.PRIMARY}
                loading={false}
                disabled={false}
                content={t("offerSendOfferRequireActionLabel")}
                subContent={t("offerSendOfferRequireActionSubLabel")}
                onClick={async () => {
                  onClick(() =>
                    window.open(
                      `/home/offer_sign?offer_id=${offerID}`,
                      "_blank"
                    )
                  );
                }}
              />
            </div>
          </div>
        );
      case Status.OFFERDECLINED:
        return (
          <div className="flex justify-end border-t border-gray-200 border-solid p-4 gap-2">
            <Button
              type={ButtonType.SECONDARY}
              loading={false}
              disabled={false}
              content={t("workingWithLAButtonTitle")}
              subContent={t("workingWithLAButtonSubTitle")}
              Icon={ListingAgent}
              onClick={async () => {
                window.open(
                  `/home/la_guide`,
                  "_blank"
                );
              }}
            />
            <Button
              type={ButtonType.PRIMARY}
              loading={false}
              disabled={false}
              content={t("bookVideoButtonTitle")}
              subContent={t("bookVideoButtonSubTitle")}
              Icon={Video}
              onClick={async () => {
                window.open(
                  `https://calendly.com/home8-support/30min`,
                  "_blank"
                );
              }}
            />
          </div>
        );
      case Status.SUBMITTED:
        if (lastOfferEmailActivities.length === 0) {
          return null;
        }
        return (
          <div className="flex items-end justify-between border-t border-gray-200 border-solid p-4 gap-[120px]">
            <div className="flex flex-col items-start gap-2 grow">
              <div className="flex flex-col items-start gap-1">
                <div className="text-sm">{t("offerUpdateStatusLabel")}</div>
                <div className="text-xs text-gray-600">
                  {t("offerUpdateStatusSubtitleLabel")}
                </div>
              </div>
              <div className="flex items-center gap-2">
                <Button
                  type={ButtonType.DONE}
                  loading={false}
                  disabled={false}
                  content={t("offerStatusOfferAcceptedLabel")}
                  subContent={t("offerStatusOfferAcceptedSubLabel")}
                  onClick={async () => {
                    await updateStatus({
                      offerId: offerID,
                      status: Status.OFFERACCPETED,
                    });
                    onStatusUpdate();
                  }}
                />
                <Button
                  type={ButtonType.WARNING}
                  loading={false}
                  disabled={false}
                  content={t("offerStatusOfferDeclinedLabel")}
                  subContent={t("offerStatusOfferDeclinedSubLabel")}
                  onClick={async () => {
                    await updateStatus({
                      offerId: offerID,
                      status: Status.OFFERDECLINED,
                    });
                    onStatusUpdate();
                  }}
                />
                <Button
                  type={ButtonType.WARNING}
                  loading={false}
                  disabled={false}
                  content={t("offerStatusOfferExpiredLabel")}
                  subContent={t("offerStatusOfferExpiredSubLabel")}
                  onClick={async () => {
                    await updateStatus({
                      offerId: offerID,
                      status: Status.OFFERFORFEIT,
                    });
                    onStatusUpdate();
                  }}
                />
              </div>
            </div>
            <div className="flex flex-col gap-2 shrink-0">
              <Button
                block
                type={ButtonType.SECONDARY}
                loading={false}
                disabled={false}
                content={t("workingWithLAButtonTitle")}
                subContent={t("workingWithLAButtonSubTitle")}
                Icon={ListingAgent}
                onClick={async () => {
                  window.open(
                    `/home/la_guide`,
                    "_blank"
                  );
                }}
              />
              <Button
                block
                type={ButtonType.PRIMARY}
                loading={false}
                disabled={false}
                content={t("bookVideoButtonTitle")}
                subContent={t("bookVideoButtonSubTitle")}
                Icon={Video}
                onClick={async () => {
                  window.open(
                    `https://calendly.com/home8-support/30min`,
                    "_blank"
                  );
                }}
              />
            </div>
          </div>
        );
      case Status.OFFEREXPIRED:
        return (
          <div className="flex justify-end border-t border-gray-200 border-solid p-4 gap-2">
            <Button
              type={ButtonType.SECONDARY}
              loading={false}
              disabled={false}
              content={t("workingWithLAButtonTitle")}
              subContent={t("workingWithLAButtonSubTitle")}
              Icon={ListingAgent}
              onClick={async () => {
                window.open(
                  `/home/la_guide`,
                  "_blank"
                );
              }}
            />
            <Button
              type={ButtonType.PRIMARY}
              loading={false}
              disabled={false}
              content={t("bookVideoButtonTitle")}
              subContent={t("bookVideoButtonSubTitle")}
              Icon={Video}
              onClick={async () => {
                window.open(
                  `https://calendly.com/home8-support/30min`,
                  "_blank"
                );
              }}
            />
          </div>
        );
        case Status.OFFERNEVERHEARDBACK:
          return (
            <div className="flex justify-end border-t border-gray-200 border-solid p-4 gap-2">
              <Button
                type={ButtonType.SECONDARY}
                loading={false}
                disabled={false}
                content={t("workingWithLAButtonTitle")}
                subContent={t("workingWithLAButtonSubTitle")}
                Icon={ListingAgent}
                onClick={async () => {
                  window.open(
                    `/home/la_guide`,
                    "_blank"
                  );
                }}
              />
              <Button
                type={ButtonType.PRIMARY}
                loading={false}
                disabled={false}
                content={t("bookVideoButtonTitle")}
                subContent={t("bookVideoButtonSubTitle")}
                Icon={Video}
                onClick={async () => {
                  window.open(
                    `https://calendly.com/home8-support/30min`,
                    "_blank"
                  );
                }}
              />
            </div>
          );
      default:
        return null;
    }
  };

  const propertyStatusLabel = (dueDate: string) => {
    switch (propertyStatus) {
      case PropertyStatus.ONSALE:
        return (
          <div className="flex flex-col items-end">
            <div className="text-[#13986A] text-xs font-bold">
              {t("propertyStatusOnSaleLabel")}
            </div>
            {dueDate && (
              <div className="text-gray-600 text-xs">
                Seller receives offers until{" "}
                <span className="text-gray-600 text-xs font-bold">
                  {formatIsoTimestamp(dueDate)}
                </span>
              </div>
            )}
          </div>
        );
      case PropertyStatus.PENDING:
        return (
          <div className="flex flex-col items-end">
            <div className="text-[#FF7A00] text-xs font-bold">Pending</div>
            <div className="text-gray-600 text-xs">
              Seller no longer receive offers
            </div>
          </div>
        );
      case PropertyStatus.CONTINGENT:
        return (
          <div className="flex flex-col items-end">
            <div className="text-[#FF7A00] text-xs font-bold">Contingent</div>
            <div className="text-gray-600 text-xs">
              Contingent Seller may receive backup offers
            </div>
          </div>
        );
      case PropertyStatus.SOLD:
        return (
          <div className="flex flex-col items-end">
            <div className="text-[#C40612] text-xs font-bold">Sold</div>
            <div className="text-gray-600 text-xs">
              Seller does not receive offers
            </div>
          </div>
        );
      default:
        return (
          <div className="flex flex-col items-end">
            <div className="text-[#13986A] text-xs font-bold">
              {t("propertyStatusOnSaleLabel")}
            </div>
          </div>
        );
    }
  };

  const stepperData = {
    [Status.INITIAL]: [
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusDraftLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusDraftReviewLabel"),
        subTitles: [t("offerStatusDraftReviewDescriptionLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusSignLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusSendLabel"),
        subTitles: [t("offerStatusSendHintLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusNegotiationLabel"),
        subTitles: [t("offerStatusNegotiationHintLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusOfferAcceptedLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
    ],
    [Status.DRAFTED]: [
      {
        status: StepStatus.PENDING,
        title: t("offerStatusDraftLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusDraftReviewLabel"),
        subTitles: [t("offerStatusDraftReviewDescriptionLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusSignLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusSendLabel"),
        subTitles: [t("offerStatusSendHintLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusNegotiationLabel"),
        subTitles: [t("offerStatusNegotiationHintLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusOfferAcceptedLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
    ],
    [Status.GENERATED]: [
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.PENDING,
        title: t("offerStatusDraftReviewLabel"),
        subTitles: [t("offerStatusDraftReviewDescriptionLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusSignLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusSendLabel"),
        subTitles: [t("offerStatusSendHintLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusNegotiationLabel"),
        subTitles: [t("offerStatusNegotiationHintLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusOfferAcceptedLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
    ],
    [Status.REVIEWFAILURE]: [
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DECLINED,
        title: t("offerStatusDraftReviewLabel"),
        subTitles: [
          <div>
            Learn how to modify{" "}
            <span
              className="text-blue-500 cursor-pointer"
              onClick={() => setIsOfferModifyModalOpen(true)}
            >
              Read guidance
            </span>
          </div>,
        ],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusSignLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusSendLabel"),
        subTitles: [t("offerStatusSendHintLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusNegotiationLabel"),
        subTitles: [t("offerStatusNegotiationHintLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusOfferAcceptedLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
    ],
    [Status.REVIEWPASS]: [
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftReviewLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.PENDING,
        title: t("offerStatusSignLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusSendLabel"),
        subTitles: [t("offerStatusSendHintLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusNegotiationLabel"),
        subTitles: [t("offerStatusNegotiationHintLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusOfferAcceptedLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
    ],
    [Status.SIGNED]: [
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftReviewLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusSignLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.PENDING,
        title: t("offerStatusSendLabel"),
        subTitles: [t("offerStatusSendHintLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusNegotiationLabel"),
        subTitles: [t("offerStatusNegotiationHintLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusOfferAcceptedLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
    ],
    [Status.SUBMITTED]: [
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftReviewLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusSignLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusSendLabel"),
        subTitles: [t("offerStatusSendHintLabel")],
        emailTrackStatus:
          lastOfferEmailActivities.length === 0
            ? EmailTrackerStatus.NOT_OPEN
            : EmailTrackerStatus.OPEN,
      },
      {
        status:
          lastOfferEmailActivities.length === 0
            ? StepStatus.GRAYED
            : StepStatus.PENDING,
        title: t("offerStatusNegotiationLabel"),
        subTitles: [t("offerStatusNegotiationHintLabel")],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.GRAYED,
        title: t("offerStatusOfferAcceptedLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
    ],
    [Status.OFFERACCPETED]: [
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftReviewLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusSignLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusSendLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusNegotiationLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusOfferAcceptedLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
    ],
    [Status.OFFERDECLINED]: [
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftReviewLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusSignLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusSendLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusNegotiationLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DECLINED,
        title: t("offerStatusOfferDeclinedLabel"),
        subTitles: [
          `${t("offerYourPriceLabel")}: $${Number(price).toLocaleString()}`,
        ],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
    ],
    [Status.OFFEREXPIRED]: [
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftReviewLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusSignLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusSendLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusNegotiationLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.EXPIRED,
        title: t("offerStatusOfferExpiredLabel"),
        subTitles: [
          `${t("offerYourPriceLabel")}: $${Number(price).toLocaleString()}`,
        ],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
    ],
    [Status.OFFERFORFEIT]: [
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftReviewLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusSignLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusSendLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusNegotiationLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DECLINED,
        title: t("offerStatusOfferExpiredLabel"),
        subTitles: [
          `${t("offerYourPriceLabel")}: $${Number(price).toLocaleString()}`,
        ],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
    ],
    [Status.OFFERNEVERHEARDBACK]: [
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusDraftReviewLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusSignLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusSendLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DONE,
        title: t("offerStatusNegotiationLabel"),
        subTitles: [],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
      {
        status: StepStatus.DECLINED,
        title: t("offerStatusOfferNeverHeardLabel"),
        subTitles: [
          `${t("offerYourPriceLabel")}: $${Number(price).toLocaleString()}`,
        ],
        emailTrackStatus: EmailTrackerStatus.NONE,
      },
    ],
  };

  const offerFinishStatusLabel = () => {
    switch (status) {
      case Status.OFFERACCPETED:
        return (
          <div className="w-full flex items-center justify-between text-gray-600 text-xs">
            <div>
              {t("offerFinishStatusLabel")}{" "}
              <span className="text-green-600 font-bold">
                {t("offerFinishStatusAccepted")}
              </span>
            </div>
            <div
              className="text-blue-500 cursor-pointer"
              onClick={async () => {
                await updateStatus({
                  offerId: offerID,
                  status: Status.SUBMITTED,
                });
                onStatusUpdate();
              }}
            >
              {t("offerChangeStatusButtonText")}
            </div>
          </div>
        );

      case Status.OFFEREXPIRED:
        return (
          <div className="w-full flex items-center justify-between text-gray-600 text-xs">
            <div>
              {t("offerFinishStatusLabel")}{" "}
              <span className="text-yellow-500 font-bold">
                {t("offerFinishStatusExpired")}
              </span>
            </div>
            <div
              className="text-xs text-blue-500 cursor-pointer"
              onClick={async () => {
                await updateStatus({
                  offerId: offerID,
                  status: Status.SUBMITTED,
                });
                onStatusUpdate();
              }}
            >
              {t("offerChangeStatusButtonText")}
            </div>
          </div>
        );

      case Status.OFFERDECLINED:
        return (
          <div className="w-full flex items-center justify-between text-gray-600 text-xs">
            <div>
              {t("offerFinishStatusLabel")}{" "}
              <span className="text-red-700 font-bold">
                {t("offerFinishStatusRejected")}
              </span>
            </div>
            <div
              className="text-xs text-blue-500 cursor-pointer"
              onClick={async () => {
                await updateStatus({
                  offerId: offerID,
                  status: Status.SUBMITTED,
                });
                onStatusUpdate();
              }}
            >
              {t("offerChangeStatusButtonText")}
            </div>
          </div>
        );

      case Status.OFFERNEVERHEARDBACK:
        return (
          <div className="w-full flex items-center justify-between text-gray-600 text-xs">
            <div>
              {t("offerFinishStatusLabel")}{" "}
              <span className="text-red-700 font-bold">
                {t("offerFinishStatusNeverHeard")}
              </span>
            </div>
            <div
              className="text-xs text-blue-500 cursor-pointer"
              onClick={async () => {
                await updateStatus({
                  offerId: offerID,
                  status: Status.SUBMITTED,
                });
                onStatusUpdate();
              }}
            >
              {t("offerChangeStatusButtonText")}
            </div>
          </div>
        );

      default:
        return null;
    }
  };

  const stepper = stepperData[status as keyof typeof stepperData];
  const banner = bottomBanner();

  const expertReviewFinished = status > Status.GENERATED;
  const offerFinished = status >= Status.OFFERACCPETED;

  console.log(stepperData, status, stepper);
  return (
    <div className="flex flex-col rounded-[20px] border border-solid border-gray-200 bg-white w-full">
      <Modal
        shouldCloseOnOverlayClick={true}
        ariaHideApp={false}
        isOpen={isModalOpen}
        onRequestClose={() => {
          setIsModalOpen(false);
        }}
        style={{
          content: {
            width: 600,
            //height: 600,
            inset: "unset",
            padding: 0,
            border: "none",
            borderRadius: "16px",
            boxShadow:
              "0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)",
            overflow: "hidden",
            overflowY: "auto",
          },
          overlay: {
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            background: "rgba(0, 0, 0, 0.55)",
            zIndex: 100,
          },
        }}
        htmlOpenClassName="overflow-hidden"
        shouldCloseOnEsc={true}
      >
        <div className="flex flex-col items-start justify-start h-full w-full p-5 gap-4 text-sm">
          <Close
            className="absolute top-5 right-5 text-gray-900 cursor-pointer"
            onClick={() => {
              setIsModalOpen(false);
            }}
          />
          <div className="font-bold">
            Have you checked the status of the property?
          </div>
          {propertyStatus === PropertyStatus.SOLD ? (
            <span>
              The property has been{" "}
              <span className="font-bold">sold within 6 months</span> and
              current status is not on market. The seller may not consider
              offers at this time. Please contact Home8 support before
              proceeding.
            </span>
          ) : propertyStatus === PropertyStatus.CONTINGENT ? (
            <span>
              This property is{" "}
              <span className="font-bold">under contingency</span>, meaning an
              offer has been accepted and is contingent on fulfilling certain
              criteria within the purchase agreement. The listing is still on
              market. The seller may or may not accept backup offers. Please
              contact the listing agent before proceeding.
            </span>
          ) : (
            <span>
              The property's listing status is{" "}
              <span className="font-bold">pending or under contract</span>,
              meaning an offer has been accepted and is expected to close. The
              seller is not accepting additional offers and the property is not
              on market. They may not consider another offer at this time.
              Please contact the listing agent before proceeding.
            </span>
          )}
          <div className="flex flex-col">
            <div>{`Listing agent: ${propertyContent?.listingAgentName}`}</div>
            <div>{propertyContent?.agentPhoneNumber}</div>
            <div>{propertyContent?.agentEmail}</div>
          </div>
          <div className="w-full flex justify-end">
            <Button
              type={ButtonType.PRIMARY}
              loading={false}
              disabled={false}
              content={t("offerContinueLabel")}
              onClick={() => {
                if (modalEventRef.current) {
                  modalEventRef.current();
                }
                setIsModalOpen(false);
              }}
            />
          </div>
        </div>
      </Modal>
      <div className="flex items-center justify-between border-b border-gray-200 border-solid px-4 py-3">
        <div className="flex flex-col items-start">
          <div className="text-sm font-bold">{address}</div>
        </div>
        {propertyStatusLabel(dueDate)}
      </div>
      <div className="flex w-full items-start justify-between p-4 gap-[120px]">
        <div className="flex flex-col gap-4">
          {stepper.map((s: StepItemProps) => (
            <StepperItem
              status={s.status}
              title={s.title}
              subTitles={s.subTitles}
              emailTrackStatus={s.emailTrackStatus}
            />
          ))}
        </div>
        <div className="w-[272px] h-[320px] bg-gray-50 flex flex-col items-start justify-between gap-2 rounded-xl p-4 shrink-0">
          <div className="flex flex-col items-start w-full">
            <div className="flex items-center gap-2">
              <FileOffer />
              <div className="text-sm">{t("offerLabel")}</div>
            </div>
            {Boolean(last_submitted_time) && (
              <div className="text-gray-600 pt-2 text-xs">{`${t("offerLastSubmittedTimeLabel")}: ${formatUnixTimestamp(last_submitted_time)}`}</div>
            )}
            {Boolean(dueDate) && (
              <div className="text-gray-600 text-xs">{`${t("offerExpirationLabel")}: ${formatIsoTimestamp(dueDate)}`}</div>
            )}
            {Boolean(price) && (
              <div className="text-gray-600 text-xs">{`${t("offerYourPriceLabel")}: $${Number(price).toLocaleString()}`}</div>
            )}
            {expertReviewFinished && (
              <div className="text-gray-600 text-xs">
                {t("offerReviewStatusLabel")}{" "}
                {status === Status.REVIEWFAILURE ? (
                  <span className="text-xs font-bold text-red-700">
                    {t("offerReviewStatusNotApproved")}
                  </span>
                ) : (
                  <span className="text-xs font-bold text-green-600">
                    {t("offerReviewStatusApproved")}
                  </span>
                )}
              </div>
            )}
            {offerFinished && offerFinishStatusLabel()}
            {status === Status.REVIEWPASS && (
              <div className="text-gray-600 text-xs">
                {t("buyerSignatureStatusLabel")}{" "}
                <span className="text-xs font-bold text-yellow-500">
                  {t("buyerSignatureStatusReady")}
                </span>
              </div>
            )}
          </div>
          <div className="flex w-full justify-end pt-4">{cardButton()}</div>
        </div>
      </div>
      {banner && banner}
      <Home8Modal
        isModalOpen={isOfferModifyModalOpen}
        onModalClose={() => {
          setIsOfferModifyModalOpen(false);
        }}
        title={t("offerModifyModalTitle")}
        subTitle={t("offerModifyModalSubTitle")}
      >
        <div className="text-sm whitespace-pre-wrap">{offerFailComment}</div>
        <div className="flex w-full justify-between">
          <Button
            type={ButtonType.SECONDARY}
            content={t("offerModifyModalPrimaryButtonTitle")}
            subContent={t("offerModifyModalPrimaryButtonSubTitle")}
            onClick={() => {
              setIsOfferModifyModalOpen(false);
              setIsExpertAssistModalOpen(true);
            }}
          />
          <Button
            type={ButtonType.PRIMARY}
            loading={false}
            content={t("offerModifyModalSecondaryButtonSubTitle")}
            onClick={async () => {
              window.open(
                `/home/offer_draft?property_id=${propertyID}&offer_id=${offerID}`,
                "_blank"
              );
            }}
          />
        </div>
      </Home8Modal>
      <Home8Modal
        isModalOpen={isExpertAssistModalOpen}
        onModalClose={() => {
          setIsExpertAssistModalOpen(false);
        }}
        title={t("expertAssistModalTitle")}
        subTitle={t("expertAssistModalSubTitle")}
      >
        <div className="flex w-full justify-between">
          <Button
            type={ButtonType.SECONDARY}
            Icon={Video}
            content={t("expertAssistModalPrimaryButtonTitle")}
            onClick={() => {
              window.open(`https://calendly.com/home8-support/30min`, "_blank");
            }}
          />
          <Button
            type={ButtonType.SECONDARY}
            Icon={ChatExpert}
            loading={false}
            content={t("expertAssistModalSecondaryButtonTitle")}
            onClick={async () => {
              window.open(`/home/expert-chat`, "_blank");
            }}
          />
        </div>
      </Home8Modal>
    </div>
  );
}

export default OfferCard;
