import {useQuery} from '@tanstack/react-query'
import {axiosWithAuth} from '../utils/axios'

export const usePropertySearchHistory = (offset?: number, limit?: number) => {
  const axios = axiosWithAuth()
  return useQuery({
    queryKey: ['property_search_history', offset, limit],
    queryFn: async () => {
      try {
        const params = {
          limit: limit,
          offset: offset,
        }
        const result = await axios.get('property_search_history', {
          params
        })
        return result.data.result
      } catch (e: any) {
        return []
      }
    },
    refetchOnWindowFocus: false,
    keepPreviousData: true
  })
}
