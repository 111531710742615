import { useParams } from "react-router-dom";
import TopBar from "../components/TopBar";
import { useHomePropertyWithProblemOverview } from "../../query/useHomePropertyWithProblemOverview";
import { useHomePropertyCost } from "../../query/useHomePropertyCost";
import { PropertyWithProblemOverview } from "../../store/agentInfo";
import { propertyStatusMap } from "../utils/propertyStatus";
import moment from "moment";
import Button, { ButtonType } from "../components/Button";
import { ReactComponent as Video } from "../../static/Video.svg";
import { useTranslation } from "react-i18next";
import ContactExpertButton from "../components/ContactExpertButton";
import { useCallback, useEffect, useState } from "react";
import Input from "../components/Input";
const mortgageJs = require("mortgage-js");

const CalculatorPage = () => {
  const { propertyId } = useParams();
  const { data } = useHomePropertyCost({ propertyId: propertyId ?? "" });
  const { t, i18n } = useTranslation();
  const { data: propertyData } = useHomePropertyWithProblemOverview(propertyId);
  const propertyProblemOverviewInfo =
    propertyData as PropertyWithProblemOverview;
  const [listingPrice, setListingPrice] = useState(
    localStorage.getItem(`home8-calculator-total-price-cache-${propertyId}`) ??
      ""
  );
  const [downPayment, setDownPayment] = useState(
    localStorage.getItem(`home8-calculator-downpay-cache`) ?? ""
  );
  const [interestRate, setInterestRate] = useState(
    localStorage.getItem("home8-calculator-interest-cache") ?? ""
  );
  const [propertyIncreaseRate, setPropertyIncreaseRate] = useState(
    localStorage.getItem(
      `home8-calculator-price-increase-cache-${propertyId}`
    ) ?? ""
  );
  const [rentIncome, setRentIncome] = useState(
    localStorage.getItem(`home8-calculator-income-cache-${propertyId}`) ?? ""
  );
  const [propertyManagement, setPropertyManagement] = useState(
    localStorage.getItem(
      `home8-calculator-management-cost-cache-${propertyId}`
    ) ?? ""
  );
  const [repairReserve, setRepairReserve] = useState(
    localStorage.getItem(`home8-calculator-repair-cost-cache-${propertyId}`) ??
      ""
  );
  const [holdingPeriod, setHoldingPeriod] = useState(
    localStorage.getItem(
      `home8-calculator-holding-period-cache-${propertyId}`
    ) ?? ""
  );
  const [monthlyPayment, setMonthlyPayment] = useState(0);
  const [propertyTax, setPropertyTax] = useState(0);
  const [insurance, setInsurance] = useState(0);
  const [totalInvestment, setTotalInvestment] = useState(0);
  const [cashFlow, setCashFlow] = useState(0);
  const [principalPayoff, setPrincipalPayoff] = useState(0);
  const [priceIncrease, setPriceIncrease] = useState(0);
  const [hoa, setHoa] = useState(0);
  const [roi, setRoi] = useState(0);
  const totalCost = monthlyPayment + propertyTax + insurance + hoa;
  const mortgageCalculator = mortgageJs.createMortgageCalculator();
  const { hoa_fee, insurance_rate, property_tax_rate } = data ?? {};

  const calculateCost = useCallback(
    (listingPriceInput?: string) => {
      const listingPriceStr = listingPriceInput ?? listingPrice;
      const downPaymentStr = downPayment;
      const interestRateStr = interestRate;
      if (!listingPriceStr || !downPaymentStr || !interestRateStr || !data) {
        return;
      }
      mortgageCalculator.totalPrice = parseFloat(listingPriceStr);
      mortgageCalculator.downPayment = parseFloat(downPaymentStr);
      mortgageCalculator.interestRate = parseFloat(interestRateStr) * 0.01;
      mortgageCalculator.months = 360;
      mortgageCalculator.taxRate = property_tax_rate;
      mortgageCalculator.insuranceRate = insurance_rate;
      mortgageCalculator.mortgageInsuranceEnabled = false;
      const payment = mortgageCalculator.calculatePayment();
      console.log(payment);
      setMonthlyPayment(payment.principalAndInterest);
      setPropertyTax((parseFloat(listingPriceStr) * property_tax_rate) / 12);
      setInsurance((parseFloat(listingPriceStr) * insurance_rate) / 12);
    },
    [listingPrice, downPayment, interestRate, data]
  );

  const calculateInvestment = useCallback(
    (listingPriceInput?: string, totalCostInput?: number) => {
      const listingPriceStr = listingPriceInput ?? listingPrice;
      const totalCostVal = totalCostInput ?? totalCost;
      const downPaymentStr = downPayment;
      const interestRateStr = interestRate;
      if (
        !listingPriceStr ||
        !downPaymentStr ||
        !interestRateStr ||
        !data ||
        !totalCostVal ||
        !rentIncome ||
        !propertyIncreaseRate ||
        !propertyManagement ||
        !repairReserve ||
        !holdingPeriod
      ) {
        return;
      }
      mortgageCalculator.totalPrice = parseFloat(listingPriceStr);
      mortgageCalculator.downPayment = parseFloat(downPaymentStr);
      mortgageCalculator.interestRate = parseFloat(interestRateStr) * 0.01;
      mortgageCalculator.months = 360;
      mortgageCalculator.taxRate = property_tax_rate;
      mortgageCalculator.insuranceRate = insurance_rate;
      mortgageCalculator.mortgageInsuranceEnabled = false;
      const payment = mortgageCalculator.calculatePayment();
      const holdingPeriodVal = parseInt(holdingPeriod);
      const rentIncomeVal = parseFloat(rentIncome);
      const propertyIncreaseRateVal = parseFloat(propertyIncreaseRate);
      const propertyManagementVal = parseFloat(propertyManagement);
      const repairReserveVal = parseFloat(repairReserve);
      const totalInvestmentVal = parseFloat(downPaymentStr) * 1.01;
      setTotalInvestment(totalInvestmentVal);
      const cashFlowVal =
        rentIncomeVal - totalCostVal - repairReserveVal - propertyManagementVal;
      setCashFlow(cashFlowVal);
      const principalPayoffVal = payment.paymentSchedule
        .map((schedule: any) => schedule.principalPayment)
        .slice(0, holdingPeriodVal * 12)
        .reduce((a: number, b: number) => a + b, 0);
      setPrincipalPayoff(principalPayoffVal);
      const priceIncreaseVal =
        parseFloat(listingPriceStr) *
        propertyIncreaseRateVal *
        0.01 *
        holdingPeriodVal;
      setPriceIncrease(priceIncreaseVal);
      const roiVal =
        ((cashFlowVal * holdingPeriodVal * 12 +
          principalPayoffVal +
          priceIncreaseVal) /
          totalInvestmentVal) *
        100;
      setRoi(roiVal);
    },
    [
      listingPrice,
      downPayment,
      interestRate,
      data,
      totalCost,
      rentIncome,
      propertyIncreaseRate,
      propertyManagement,
      repairReserve,
      holdingPeriod,
    ]
  );

  useEffect(() => {
    console.log("change", totalCost);
    if (!listingPrice) {
      setListingPrice(
        propertyProblemOverviewInfo?.property?.listing_price.toString() ?? ""
      );
    }
    if (propertyProblemOverviewInfo && data) {
      calculateCost(
        propertyProblemOverviewInfo?.property?.listing_price.toString()
      );
      if (totalCost) {
        calculateInvestment(
          propertyProblemOverviewInfo?.property?.listing_price.toString(),
          totalCost
        );
      }
    }
  }, [propertyProblemOverviewInfo, data, totalCost]);

  if (!propertyId || !data || !propertyData) {
    return <></>;
  }

  console.log(data);

  const statusData = propertyStatusMap[
    propertyProblemOverviewInfo.property
      .status as keyof typeof propertyStatusMap
  ] ?? {
    label: "For sale",
    color: "green-600",
  };
  const createdTime = moment(propertyProblemOverviewInfo.property.create_time);
  const dateDiff = moment().diff(createdTime, "day");

  return (
    <div className="flex flex-col items-center h-full">
      <TopBar />
      <div className="flex flex-col px-5 w-full items-center">
        <div className="flex items-start justify-between w-full">
          <div className="flex flex-col gap-5">
            <div className="flex flex-col gap-1">
              <div className="text-xl font-bold text-gray-900">
                {propertyProblemOverviewInfo.property.address}
              </div>
              <div className={`text-sm font-bold text-${statusData.color}`}>
                {statusData.label}{" "}
                <span className="text-sm font-normal text-gray-900">
                  {dateDiff} days,{" "}
                  {propertyProblemOverviewInfo.property.offer_due === "N/A" ||
                  !propertyProblemOverviewInfo.property.offer_due
                    ? "no offer due data"
                    : `offer due on ${new Date(
                        propertyProblemOverviewInfo.property.offer_due
                      ).toLocaleDateString("en-US", {
                        month: "short",
                        day: "2-digit",
                        year: "numeric",
                      })}`}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col gap-2">
            <Button
              type={ButtonType.SECONDARY}
              loading={false}
              disabled={false}
              content={t("newCalculatorPageBookVideoConsultationTitle")}
              subContent={t("newCalculatorPageBookVideoConsultationSubTitle")}
              Icon={Video}
              onClick={async () => {
                window.open(
                  `https://calendly.com/home8-support/30min`,
                  "_blank"
                );
              }}
            />
          </div>
        </div>
        <div className="flex flex-col w-[948px] items-center pt-4 pb-[80px] gap-4">
          <div className="bg-gray-50 rounded-[20px] w-full gap-4 py-3 px-4 flex flex-col items-center">
            <div className="text-sm font-bold">{t("newCalculatorPageCostOfOwnershipTitle")}</div>
            <div className="flex items-center gap-2 w-full">
              <Input
                className="w-full text-black h-[40px] text-xs bg-white grow"
                required
                placeholder={t("newCalculatorPageCostOfOwnershipTotalPriceLabel")}
                value={listingPrice}
                isError={false}
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  setListingPrice(value);
                  localStorage.setItem(
                    `home8-calculator-total-price-cache-${propertyId}`,
                    value
                  );
                }}
                type="number"
                onBlur={() => calculateCost()}
              />
              <Input
                className="w-full text-black h-[40px] text-xs bg-white grow"
                required
                placeholder={t("newCalculatorPageCostOfOwnershipDownPaymentLabel")}
                value={downPayment}
                isError={false}
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  setDownPayment(value);
                  localStorage.setItem(`home8-calculator-downpay-cache`, value);
                }}
                type="number"
                onBlur={() => calculateCost()}
              />
              <Input
                className="w-full text-black h-[40px] text-xs bg-white grow"
                required
                placeholder={t("newCalculatorPageCostOfOwnershipInterestRateLabel")}
                value={interestRate}
                isError={false}
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  setInterestRate(value);
                  localStorage.setItem(
                    "home8-calculator-interest-cache",
                    value
                  );
                }}
                type="number"
                onBlur={() => calculateCost()}
              />
            </div>
            <div className="flex flex-col w-full gap-2">
              <div className="flex w-full justify-between">
                <div className="text-sm">
                  {t("newCalculatorPageCostOfOwnershipMonthlyMortgagePaymentLabel")}
                </div>
                <div className="text-sm">
                  {monthlyPayment.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                  /{t("newCalculatorPageMonthlyLabel")}
                </div>
              </div>
              <div className="flex w-full justify-between">
                <div className="text-sm">{t("newCalculatorPageCostOfOwnershipPropertyTaxLabel")}</div>
                <div className="text-sm">
                  {propertyTax.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                  /{t("newCalculatorPageMonthlyLabel")}
                </div>
              </div>
              <div className="flex w-full justify-between">
                <div className="text-sm">{t("newCalculatorPageCostOfOwnershipHomeInsuranceLabel")}</div>
                <div className="text-sm">
                  {insurance.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                  /{t("newCalculatorPageMonthlyLabel")}
                </div>
              </div>
              <div className="flex w-full justify-between">
                <div className="text-sm">{t("newCalculatorPageCostOfOwnershipHOALabel")}</div>
                <div className="text-sm">Coming Soon</div>
              </div>
              <div className="flex w-full justify-between">
                <div className="text-sm font-bold">{t("newCalculatorPageCostOfOwnershipTotalLabel")}</div>
                <div className="text-sm font-bold">
                  {(
                    monthlyPayment +
                    propertyTax +
                    insurance +
                    hoa
                  ).toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                  /{t("newCalculatorPageMonthlyLabel")}
                </div>
              </div>
            </div>
          </div>
          <div className="bg-gray-50 rounded-[20px] w-full gap-4 py-3 px-4 flex flex-col items-center">
            <div className="text-sm font-bold">{t("newCalculatorPageInvestmentLabel")}</div>
            <div className="flex items-center gap-2 w-full flex-wrap">
              <Input
                className="w-full text-black h-[40px] text-xs bg-white grow max-w-[300px]"
                required
                placeholder={t("newCalculatorPageInvestmentTotalPriceLabel")}
                value={listingPrice}
                isError={false}
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  setListingPrice(value);
                  localStorage.setItem(
                    `home8-calculator-total-price-cache-${propertyId}`,
                    value
                  );
                }}
                type="number"
                onBlur={() => {
                  calculateCost();
                  calculateInvestment();
                }}
              />
              <Input
                className="w-full text-black h-[40px] text-xs bg-white grow max-w-[300px]"
                required
                placeholder={t("newCalculatorPageInvestmentDownPaymentLabel")}
                value={downPayment}
                isError={false}
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  setDownPayment(value);
                  localStorage.setItem(`home8-calculator-downpay-cache`, value);
                }}
                type="number"
                onBlur={() => {
                  calculateCost();
                  calculateInvestment();
                }}
              />
              <Input
                className="w-full text-black h-[40px] text-xs bg-white grow max-w-[300px]"
                required
                placeholder={t("newCalculatorPageInvestmentInterestRateLabel")}
                value={interestRate}
                isError={false}
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  setInterestRate(value);
                  localStorage.setItem(
                    "home8-calculator-interest-cache",
                    value
                  );
                }}
                type="number"
                onBlur={() => {
                  calculateCost();
                  calculateInvestment();
                }}
              />
              <Input
                className="w-full text-black h-[40px] text-xs bg-white grow max-w-[300px]"
                required
                placeholder={t("newCalculatorPageInvestmentPriceIncreaseLabel")}
                value={propertyIncreaseRate}
                isError={false}
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  setPropertyIncreaseRate(value);
                  localStorage.setItem(
                    `home8-calculator-price-increase-cache-${propertyId}`,
                    value
                  );
                }}
                type="number"
                onBlur={() => calculateInvestment()}
              />
              <Input
                className="w-full text-black h-[40px] text-xs bg-white grow max-w-[300px]"
                required
                placeholder={t("newCalculatorPageInvestmentMonthlyRentLabel")}
                value={rentIncome}
                isError={false}
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  setRentIncome(value);
                  localStorage.setItem(
                    `home8-calculator-income-cache-${propertyId}`,
                    value
                  );
                }}
                type="number"
                onBlur={() => calculateInvestment()}
              />
              <Input
                className="w-full text-black h-[40px] text-xs bg-white grow max-w-[300px]"
                required
                placeholder={t("newCalculatorPageInvestmentPropertyManagementLabel")}
                value={propertyManagement}
                isError={false}
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  setPropertyManagement(value);
                  localStorage.setItem(
                    `home8-calculator-management-cost-cache-${propertyId}`,
                    value
                  );
                }}
                type="number"
                onBlur={() => calculateInvestment()}
              />
              <Input
                className="w-full text-black h-[40px] text-xs bg-white grow max-w-[300px]"
                required
                placeholder={t("newCalculatorPageInvestmentRepairReserveLabel")}
                value={repairReserve}
                isError={false}
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  setRepairReserve(value);
                  localStorage.setItem(
                    `home8-calculator-repair-cost-cache-${propertyId}`,
                    value
                  );
                }}
                type="number"
                onBlur={() => calculateInvestment()}
              />
              <Input
                className="w-full text-black h-[40px] text-xs bg-white grow max-w-[300px]"
                required
                placeholder={t("newCalculatorPageInvestmentHoldingPeriodLabel")}
                value={holdingPeriod}
                isError={false}
                onChange={(e) => {
                  const value = (e.target as HTMLInputElement).value;
                  setHoldingPeriod((e.target as HTMLInputElement).value);
                  localStorage.setItem(
                    `home8-calculator-holding-period-cache-${propertyId}`,
                    value
                  );
                }}
                type="number"
                onBlur={() => calculateInvestment()}
              />
            </div>
            <div className="flex flex-col w-full gap-2">
              <div className="flex w-full justify-between">
                <div className="text-sm">
                  {t("newCalculatorPageInvestmentTotalCostLabel")}
                </div>
                <div className="text-sm">
                  {totalInvestment.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </div>
              </div>
              <div className="flex w-full justify-between">
                <div className="text-sm">{t("newCalculatorPageInvestmentCashFlowLabel")}</div>
                <div className="text-sm">
                  {cashFlow.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                  /month
                </div>
              </div>
              <div className="flex w-full justify-between">
                <div className="text-sm">
                  {holdingPeriod}{t("newCalculatorPageYearsLabel")}{t("newCalculatorPageInvestmentPrinciplePayoffLabel")}
                </div>
                <div className="text-sm">
                  {principalPayoff.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </div>
              </div>
              <div className="flex w-full justify-between">
                <div className="text-sm">
                  {holdingPeriod}{t("newCalculatorPageYearsLabel")}{t("newCalculatorPageInvestmentPriceIncreaseLabel")}
                </div>
                <div className="text-sm">
                  {priceIncrease.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 2,
                  })}
                </div>
              </div>
              <div className="flex w-full justify-between">
                <div className="text-sm font-bold">
                  {holdingPeriod}{t("newCalculatorPageYearsLabel")}{t("newCalculatorPageInvestmentROILabel")}
                </div>
                <div className="text-sm font-bold">{roi.toFixed(2)}%</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="fixed right-5 bottom-5">
        <ContactExpertButton />
      </div>
    </div>
  );
};

export default CalculatorPage;
