import React, { useEffect, useRef, useState } from "react";
import { ReactComponent as DropDownArrow } from "../../static/DropDownArrow.svg";
import classNames from "classnames";
import { ReactComponent as DropDownSelected } from "../../static/DropDownSelected.svg";

export type Option = {
  label: string;
  value: string;
};

type Props = {
  options: Option[];
  selectedOption: string;
  onOptionSelected: (value: string) => void;
};

const DropdownSelect = ({
  selectedOption,
  options,
  onOptionSelected,
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [menuWidth, setMenuWidth] = useState(0);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleOptionClick = (option: string) => {
    onOptionSelected(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const calculateWidth = () => {
      const tempDiv = document.createElement("div");
      tempDiv.style.position = "absolute";
      tempDiv.style.visibility = "hidden";
      tempDiv.style.whiteSpace = "nowrap";
      tempDiv.style.fontSize = "14px";
      document.body.appendChild(tempDiv);

      let maxWidth = 0;
      options.forEach((option) => {
        tempDiv.innerText = option.label;
        const width = tempDiv.offsetWidth;
        if (width > maxWidth) maxWidth = width;
      });

      document.body.removeChild(tempDiv);
      setMenuWidth(maxWidth);
    };

    calculateWidth();
  }, [options]);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const selectedOptionLabel =
    options.find((option) => option.value === selectedOption)?.label ?? "";

  return (
    <div ref={dropdownRef} className="relative">
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={classNames(
          "cursor-pointer flex items-center text-sm py-0.5 pl-3 pr-1 border border-solid border-gray-200 rounded-[20px] hover:bg-gray-50 gap-1",
          {
            "bg-gray-50": isOpen,
            "bg-white": !isOpen,
          }
        )}
      >
        <div style={{ width: menuWidth }}>{selectedOptionLabel}</div>
        <DropDownArrow className="fill-gray-900" />
      </div>

      {isOpen && (
        <div
          style={{
            boxShadow:
              "2px 4px 10px 0px rgba(0, 0, 0, 0.1),-2px 4px 10px 0px rgba(0, 0, 0, 0.1)",
          }}
          className="absolute top-[120%] right-0 rounded-xl bg-white z-10 flex flex-col justify-end text-sm overflow-hidden"
        >
          {options.map((option) => (
            <div
              className={classNames(
                "group py-0.5 pl-3 pr-1 w-full cursor-pointer flex justify-end items-center hover:bg-gray-50 gap-1"
              )}
              key={option.value}
              onClick={() => handleOptionClick(option.value)}
            >
              <div className="group-hover:text-blue-500">{option.label}</div>
              <DropDownSelected className="invisible group-hover:visible" />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default DropdownSelect;
