export const propertyStatusMap = {
  Active: {
    label: "For sale",
    color: "green-600",
  },
  Contingent: {
    label: "Contingent",
    color: "yellow-500",
  },
  Pending: {
    label: "Pending",
    color: "yellow-500",
  },
  PendingDoNotShow: {
    label: "Pending",
    color: "yellow-500",
  },
  Closed: {
    label: "Sold",
    color: "red-700",
  },
  Expired: {
    label: "Expired",
    color: "red-700",
  },
  Canceled: {
    label: "Canceled",
    color: "red-700",
  },
  Withdrawn: {
    label: "Withdrawn",
    color: "red-700",
  },
  Sold: {
    label: "Off market",
    color: "gray-400",
  },
};

export const propertyStatusDarkMap = {
  Active: {
    label: "For sale",
    color: "green-300",
  },
  Contingent: {
    label: "Contingent",
    color: "yellow-300",
  },
  Pending: {
    label: "Pending",
    color: "yellow-300",
  },
  PendingDoNotShow: {
    label: "Pending",
    color: "yellow-300",
  },
  Closed: {
    label: "Sold",
    color: "red-400",
  },
  Expired: {
    label: "Expired",
    color: "red-400",
  },
  Canceled: {
    label: "Canceled",
    color: "red-400",
  },
  Withdrawn: {
    label: "Withdrawn",
    color: "red-400",
  },
  Sold: {
    label: "Off market",
    color: "gray-200",
  },
};
