import React from 'react';
import TopBar from '../components/TopBar';
import ContactExpertButton from '../components/ContactExpertButton';
import { Sidebar } from 'rsuite';
import { useTranslation } from "react-i18next";
import ServicePricingTable, { TableData } from '../components/ServicePricingTable';


const tableData: TableData = {
    headers: [
      {
        title: "pricingPageDIYTitle",
        subTitle: "pricingPageDIYSubTitle",
      },
      {
        title: "pricingPageNegotiationTitle",
        subTitle: "pricingPageNegotiationSubTitle",
      },
      {
        title: "pricingPageFullServiceTitle",
        subTitle: "pricingPageFullServiceSubTitle",
      },
    ],
    discounts: [
      {
        discount: false,
      },
      {
        discount: true,
      },
      {
        discount: false,
      },
    ],
    prices: [
      {
        price: "pricingPageDIYPriceTitle",
        subTitle: "pricingPageDIYPriceSubTitle",
      },
      {
        price: "pricingPageNegotiationPriceTitle",
        subTitle: "pricingPageNegotiationPriceSubTitle",
        //subSubTitle: "pricingPageNegotiationPriceSubSubTitle",
      },
      {
        price: "pricingPageFullServicePriceTitle",
        subTitle: "pricingPageFullServicePriceSubTitle",
      },
    ],
    saleLinks: [
      {
      },
      {
        link: "https://calendly.com/home8-support/sales-price-negotiation-service",
      },
      {
        link: "https://calendly.com/home8-support/sales-full-service-buyer-agent",
      },
    ],
    financialPreparation: [
      {
        rowName: "pricingPageFinancialPreparationLoanReferral",
        data: [
          {
            include: true,
          },
          {
            include: true,
          },
          {
            include: true,
          },
        ],
      },
      {
        rowName: "pricingPageFinancialPreparationFinancialDiscussion",
        data: [
          {
            include: false,
          },
          {
            include: false,
          },
          {
            text: "human_yes",
          },
        ],
      },
    ],
    recommendations: [
      {
        rowName: "pricingPageRecommendationPersonalizedRecommendation",
        data: [
          {
            include: false,
          },
          {
            include: false,
          },
          {
            text: "human_yes",
          },
        ],
      },
      {
        rowName: "pricingPageRecommendationTour",
        data: [
          {
            text: "pricingPageRecommendationPaidServiceText",
          },
          {
            text: "pricingPageRecommendationPaidServiceText",
          },
          {
            text: "human_yes",
          },
        ],
      },
      {
        rowName: "pricingPageRecommendationAnalysis",
        data: [
          {
            include: false,
          },
          {
            include: false,
          },
          {
            text: "human_yes",
          },
        ],
      },
    ],
    disclosures: [
      {
        rowName: "pricingPageDisclosureService1",
        data: [
          {
            include: true,
          },
          {
            include: true,
          },
          {
            include: true,
          },
        ],
      },
      {
        rowName: "pricingPageDisclosureService2",
        data: [
          {
            include: true,
          },
          {
            include: true,
          },
          {
            include: true,
          },
        ],
      },
      {
        rowName: "pricingPageDisclosureService3",
        data: [
          {
            include: true,
          },
          {
            include: true,
          },
          {
            include: true,
          },
        ],
      },
      {
        rowName: "pricingPageDisclosureService4",
        data: [
          {
            include: true,
          },
          {
            include: true,
          },
          {
            include: true,
          },
        ],
      },
      {
        rowName: "pricingPageDisclosureService5",
        data: [
          {
            include: true,
          },
          {
            include: true,
          },
          {
            include: true,
          },
        ],
      },
      {
        rowName: "pricingPageDisclosureService6",
        data: [
          {
            include: false,
          },
          {
            include: false,
          },
          {
            text: "human_yes",
          },
        ],
      },
      {
        rowName: "pricingPageDisclosureService7",
        data: [
          {
            include: false,
          },
          {
            include: false,
          },
          {
            text: "human_yes",
          },
        ],
      },
      {
        rowName: "pricingPageDisclosureService8",
        data: [
          {
            include: false,
          },
          {
            include: false,
          },
          {
            text: "human_yes",
          },
        ],
      },
    ],
    offers: [
      {
        rowName: "pricingPageOffersService1",
        data: [
          {
            include: true,
          },
          {
            include: true,
          },
          {
            include: true,
          },
        ],
      },
      {
        rowName: "pricingPageOffersService2",
        data: [
          {
            text: "human_yes",
          },
          {
            text: "human_yes",
          },
          {
            text: "human_yes",
          },
        ],
      },
      {
        rowName: "pricingPageOffersService3",
        data: [
          {
            include: true,
          },
          {
            include: true,
          },
          {
            include: true,
          },
        ],
      },
      {
        rowName: "pricingPageOffersService4",
        data: [
          {
            include: true,
          },
          {
            include: true,
          },
          {
            include: true,
          },
        ],
      },
      {
        rowName: "pricingPageOffersService5",
        data: [
          {
            include: true,
          },
          {
            include: true,
          },
          {
            include: true,
          },
        ],
      },
      {
        rowName: "pricingPageOffersService6",
        data: [
          {
            include: false,
          },
          {
            text: "human_yes",
          },
          {
            text: "human_yes",
          },
        ],
      },
      {
        rowName: "pricingPageOffersService7",
        data: [
          {
            include: false,
          },
          {
            text: "human_yes",
          },
          {
            text: "human_yes",
          },
        ],
      },
    ],
    closings: [
      {
        rowName: "pricingPageClosingService1",
        data: [
          {
            text: 'pricingPageTransactionServiceTitle',
          },
          {
            text: "human_yes",
          },
          {
            text: "human_yes",
          },
        ],
      },
      {
        rowName: "pricingPageClosingService2",
        data: [
          {
            text: "pricingPageTransactionServiceTitle",
          },
          {
            text: "human_yes",
          },
          {
            text: "human_yes",
          },
        ],
      },
      {
        rowName: "pricingPageClosingService3",
        data: [
          {
            text: "pricingPageTransactionServiceTitle",
          },
          {
            text: "human_yes",
          },
          {
            text: "human_yes",
          },
        ],
      },
      {
        rowName: "pricingPageClosingService4",
        data: [
          {
            text: "pricingPageTransactionServiceTitle",
          },
          {
            text: "human_yes",
          },
          {
            text: "human_yes",
          },
        ],
      },
      {
        rowName: "pricingPageClosingService5",
        data: [
          {
            text: "pricingPageTransactionServiceTitle",
          },
          {
            text: "human_yes",
          },
          {
            text: "human_yes",
          },
        ],
      },
    ],
    postTransactions: [
      {
        rowName: "pricingPagePostTransactionService1",
        data: [
          {
            text: "pricingPageTransactionServiceTitle",
          },
          {
            text: "human_yes",
          },
          {
            text: "human_yes",
          },
        ],
      },
      {
        rowName: "pricingPagePostTransactionService2",
        data: [
          {
            text: "pricingPageTransactionServiceTitle",
          },
          {
            text: "human_yes",
          },
          {
            text: "human_yes",
          },
        ],
      },
    ],

    tableData: [
      {
        rowName: "transactionManagementAgentSupport",
        data: [
          "transactionManagementAgentSupportBasic",
          "transactionManagementAgentSupportAdvanced",
          "transactionManagementAgentSupportThirdParty",
        ],
      },
      {
        rowName: "transactionManagementAgentInstruction",
        data: [
          "transactionManagementAgentInstructionBasic",
          "transactionManagementAgentInstructionAdvanced",
          "transactionManagementAgentInstructionThirdParty",
        ],
      },
      {
        rowName: "transactionManagementLenderSign",
        data: [
          "transactionManagementLenderSignBasic",
          "transactionManagementLenderSignAdvanced",
          "transactionManagementLenderSignThirdParty",
        ],
      },
      {
        rowName: "transactionManagementGrantDeed",
        data: [
          "transactionManagementGrantDeedBasic",
          "transactionManagementGrantDeedAdvanced",
          "transactionManagementGrantDeedThirdParty",
        ],
      },
      {
        rowName: "transactionManagementAgentAssist",
        data: [
          "transactionManagementAgentAssistBasic",
          "transactionManagementAgentAssistAdvanced",
          "transactionManagementAgentAssistThirdParty",
        ],
      },
      {
        rowName: "transactionManagementAgentInspect",
        data: [
          "transactionManagementAgentInspectBasic",
          "transactionManagementAgentInspectAdvanced",
          "transactionManagementAgentInspectThirdParty",
        ],
      },
      {
        rowName: "transactionManagementAgentPostSale",
        data: [
          "transactionManagementAgentPostSaleBasic",
          "transactionManagementAgentPostSaleAdvanced",
          "transactionManagementAgentPostSaleThirdParty",
        ],
      },
    ],
  };

const PricingPage = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className="flex flex-col items-center">
          <TopBar/>

          <ServicePricingTable tableData={tableData} />
          {/*<div className="flex flex-col w-full">
            <div className="flex w-full items-stretch gap-2 rounded-2xl bg-white px-2">
              <div className="grow basis-0 pt-5 pb-2 min-w-96">
                <div className="inline-flex pl-2 pr-2 text-sm font-bold text-black bg-[#F6F7F8] w-auto">{t('pricingPageDisclaimerTitle')}</div>
                <div className="pl-7 text-sm font-normal text-[#5D6B79] mb-20">{t('pricingPageDisclaimerContent')}</div>
              </div>
            </div>
          </div>*/}
          <div className="w-full h-10 mb-20"></div>

          <div className="fixed right-5 bottom-5">
            <ContactExpertButton />
          </div>
        </div>
    );
};

export default PricingPage;