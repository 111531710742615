import { ReactNode } from "react";
import { ReactComponent as Close } from "../../static/Close.svg";
import Modal from "react-modal";
import { title } from "process";

type Props = {
  isModalOpen: boolean;
  onModalClose: () => void;
  title: string;
  subTitle?: string;
  children: ReactNode;
};

const Home8Modal = ({
  isModalOpen,
  onModalClose,
  children,
  title,
  subTitle,
}: Props) => {
  return (
    <Modal
      shouldCloseOnOverlayClick={true}
      ariaHideApp={false}
      isOpen={isModalOpen}
      onRequestClose={onModalClose}
      style={{
        content: {
          width: 600,
          //height: 600,
          inset: "unset",
          padding: 0,
          border: "none",
          borderRadius: "16px",
          boxShadow:
            "0px 6px 12px -10px rgba(31, 35, 41, 0.06), 0px 8px 24px rgba(31, 35, 41, 0.04), 0px 10px 36px 10px rgba(31, 35, 41, 0.04)",
          overflow: "hidden",
          overflowY: "auto",
        },
        overlay: {
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          background: "rgba(0, 0, 0, 0.55)",
          zIndex: 100,
        },
      }}
      htmlOpenClassName="overflow-hidden"
      shouldCloseOnEsc={true}
    >
      <div className="flex flex-col items-start justify-start h-full w-full p-5 gap-10 text-sm">
        <Close
          className="absolute top-5 right-5 text-gray-900 cursor-pointer"
          onClick={onModalClose}
        />
        <div>
          <div className="text-sm font-bold">{title}</div>
          {Boolean(subTitle) && (
            <div className="text-sm text-gray-600 mt-2">{subTitle}</div>
          )}
        </div>
        {children}
      </div>
    </Modal>
  );
};

export default Home8Modal;
