import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import TopBar from "../components/TopBar";
import PropertySearchBar from "../components/PropertySearchBar";
import HomePageIllustration from "../components/HomePageIllustration";
import ContactExpertButton from "../components/ContactExpertButton";
import { usePropertySearchHistory } from "../../query/usePropertySearchHistory";
import Tag from "../components/Tag";
import Table, { TableColumn } from "../components/Table";
import { Suggestion } from "../components/PropertySearchModal";
import { OfferType, Property } from "../../store/agentInfo";
import { useTranslation } from "react-i18next";
import classNames from "classnames";
import { useEffect, useState } from "react";
import OfferSection from "../components/OfferSection";
import TransactionSection from "../components/TransactionSection";
import DropdownSelect from "../components/DropdownSelect";
import LogRocket from "logrocket";
import { axiosWithAuth } from "../../utils/axios";

enum TabType {
  SearchHistory = "history",
  Offer = "offer",
  Transaction = "transaction",
}

const tabs = [
  {
    key: TabType.SearchHistory,
    content_key: "newSearchHistoryTabLabel",
  },
  {
    key: TabType.Offer,
    content_key: "newMyOffersTabLabel",
  },
  {
    key: TabType.Transaction,
    content_key: "newMyTransactionsTabLabel",
  },
];

type PropertySearchHistoryType = {
  last_view_time: string;
  offer_price?: number;
  property: Property;
};

const limit = 10;

const HomePage = () => {
  const { t, i18n } = useTranslation();
  const [URLSearchParams, SetURLSearchParams] = useSearchParams();
  const [pageNum, setPageNum] = useState(0);
  const offset = pageNum * limit;
  const { data, isFetching } = usePropertySearchHistory(offset, limit);
  const currentTab = (() => {
    switch (URLSearchParams.get("tab")) {
      case "history":
        return TabType.SearchHistory;
      case "offer":
        return TabType.Offer;
      case "transaction":
        return TabType.Transaction;
      default:
        return TabType.SearchHistory;
    }
  })();

  useEffect(() => {
    const identifyUser = async() => {
      const axios = axiosWithAuth()
      // Assuming you have user data in your state or props
      const {data} = await axios.get('user/me')
      const user = data?.result
      LogRocket.identify(user.id, {
        email: user.email,
      });
    }

    identifyUser();
  }, []);

  const columns: TableColumn[] = [
    {
      columnName: t("newSearchHistoryColumnAddressLabel"),
      renderer: (data: PropertySearchHistoryType) => (
        <div className="flex gap-2 items-center max-w-[600px]">
          <div
            className="bg-cover bg-no-repeat bg-center h-9 w-9 rounded shrink-0"
            style={{
              backgroundImage: `url(${data.property.property_thumbnail_url})`,
            }}
          />
          <div className="min-w-0">
            <div className="text-sm font-normal text-gray-900 whitespace-nowrap text-ellipsis overflow-hidden">
              {data.property.address}
            </div>
            <div className="text-xs font-normal text-gray-600">
              {data.property.bedrooms}b
              {data.property.full_bathrooms +
                data.property.half_bathrooms * 0.5}
              b | {data.property.living_sqft} {t("sqftLabel")}{" "}
              {Boolean(data.property.lot_size) &&
                `| ${data.property.lot_size} 
              ${t("sqftLabel")} lot`}
            </div>
          </div>
        </div>
      ),
      className: "grow min-w-[400px] cursor-pointer",
      onClick: (data: PropertySearchHistoryType) =>
        window.open(`/home/report/${data.property.id}`, "_blank"),
    },
    {
      columnName: t("newSearchHistoryColumnStatusLabel"),
      renderer: (data: PropertySearchHistoryType) => {
        switch (data.property.status) {
          case "Active":
            return (
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <div className="h-1.5 w-1.5 rounded-full bg-green-600" />
                  <div className="text-sm">For sale</div>
                </div>
              </div>
            );
          case "Contingent":
            return (
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <div className="h-1.5 w-1.5 rounded-full bg-yellow-500" />
                  <div className="text-sm">Contingent</div>
                </div>
              </div>
            );
          case "Pending":
            return (
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <div className="h-1.5 w-1.5 rounded-full bg-yellow-500" />
                  <div className="text-sm">Pending</div>
                </div>
              </div>
            );
          case "PendingDoNotShow":
            return (
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <div className="h-1.5 w-1.5 rounded-full bg-yellow-500" />
                  <div className="text-sm">Pending</div>
                </div>
              </div>
            );
          case "Canceled":
            return (
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <div className="h-1.5 w-1.5 rounded-full bg-red-700" />
                  <div className="text-sm">Canceled</div>
                </div>
              </div>
            );
          case "Closed":
            return (
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <div className="h-1.5 w-1.5 rounded-full bg-red-700" />
                  <div className="text-sm">Closed</div>
                </div>
              </div>
            );
          case "Expired":
            return (
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <div className="h-1.5 w-1.5 rounded-full bg-red-700" />
                  <div className="text-sm">Expire</div>
                </div>
              </div>
            );
          case "Withdrawn":
            return (
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <div className="h-1.5 w-1.5 rounded-full bg-red-700" />
                  <div className="text-sm">Withdraw</div>
                </div>
              </div>
            );
          case "Sold":
            return (
              <div className="flex flex-col">
                <div className="flex items-center gap-2">
                  <div className="h-1.5 w-1.5 rounded-full bg-gray-400" />
                  <div className="text-sm">Sold</div>
                </div>
              </div>
            );
          default:
            return <></>;
        }
      },
      className: "grow cursor-pointer",
      onClick: (data: PropertySearchHistoryType) =>
        window.open(`/home/report/${data.property.id}`, "_blank"),
    },
    {
      columnName: t("newSearchHistoryColumnListingPriceLabel"),
      renderer: (data: PropertySearchHistoryType) => (
        <div className="text-sm">
          {data.property.listing_price.toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            minimumFractionDigits: 0,
            maximumFractionDigits: 0,
          })}
        </div>
      ),
      className: "grow cursor-pointer",
      onClick: (data: PropertySearchHistoryType) =>
        window.open(`/home/report/${data.property.id}`, "_blank"),
    },
    {
      columnName: t("newSearchHistoryColumnOfferPriceLabel"),
      renderer: (data: PropertySearchHistoryType) => {
        if (!data.offer_price) {
          return <></>;
        } else {
          return (
            <div className="flex flex-col">
              <div className="text-sm">
                {data.offer_price.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </div>
              <p
                className={classNames("text-sm", {
                  "text-red-700":
                    data.offer_price < data.property.listing_price,
                  "text-green-700":
                    data.offer_price >= data.property.listing_price,
                })}
              >
                {data.offer_price < data.property.listing_price ? "-" : "+"}
                {Math.abs(
                  data.property.listing_price - data.offer_price
                ).toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                })}
              </p>
            </div>
          );
        }
      },
      className: "grow cursor-pointer",
      onClick: (data: PropertySearchHistoryType) =>
        window.open(`/home/report/${data.property.id}`, "_blank"),
    },
  ];

  if (!data) {
    return (
      <div className="flex flex-col items-center">
        <TopBar />
        <div className="fixed right-5 bottom-5">
          <ContactExpertButton />
        </div>
      </div>
    );
  }

  const [searchHistory, searchHistoryTotalCount] = data;
  console.log(searchHistory);

  if (data && searchHistoryTotalCount === 0) {
    return (
      <div className="flex flex-col items-center">
        <TopBar />
        <div className="flex flex-col items-center justify-center gap-6 w-[716px]">
          <div className="text-xl font-bold">💰 0 commission 💰</div>
          <div className="text-4xl">
            Unbiased insights + Buying consultation
          </div>
          <PropertySearchBar />
        </div>
        <HomePageIllustration />
        <div className="fixed right-5 bottom-5">
          <ContactExpertButton />
        </div>
      </div>
    );
  }

  return (
    <div className="flex flex-col items-center">
      <TopBar />
      <div className="flex flex-col items-center w-full p-5">
        <PropertySearchBar />
        <div className="flex flex-col items-start pt-4 gap-3 w-full pb-[72px]">
          <div className="flex items-center gap-2">
            {tabs.map((tab) => (
              <Tag
                content={t(tab.content_key)}
                selected={currentTab === tab.key}
                onClick={() => {
                  SetURLSearchParams((params) => {
                    params.set("tab", tab.key);
                    return params;
                  });
                }}
              />
            ))}
          </div>
          {currentTab === TabType.SearchHistory && (
            <Table
              data={searchHistory}
              columns={columns}
              pageNum={pageNum}
              limit={limit}
              total={searchHistoryTotalCount}
              onPageNext={() => {
                setPageNum((num) => num + 1);
              }}
              onPageBack={() => {
                setPageNum((num) => num - 1);
              }}
              loading={isFetching}
              allowPagination
            />
          )}
          {currentTab === TabType.Offer && <OfferSection />}
          {currentTab === TabType.Transaction && <TransactionSection />}
        </div>
      </div>
      <div className="fixed right-5 bottom-5">
        <ContactExpertButton />
      </div>
    </div>
  );
};

export default HomePage;
