import homePageHouse from "../../static/HomePageHouse.png";

const HomePageIllustration = () => {
  return (
    <div className="mt-[-60px] z-[-1]">
        <img className="w-[896px]" src={homePageHouse}/>
    </div>
  );
};

export default HomePageIllustration;
