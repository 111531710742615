import { useQuery } from "@tanstack/react-query";
import { axiosWithAuth } from "../utils/axios";

export const useHomePropertyCost = ({ propertyId }: { propertyId: string }) => {
  const axios = axiosWithAuth();
  return useQuery({
    queryKey: ["home8_property_cost"],
    queryFn: async () => {
      try {
        const { data } = await axios.post("property_cost_of_ownership", {
          property_id: propertyId,
        });
        return data?.result;
      } catch (e: any) {
        return {};
      }
    },
    refetchOnWindowFocus: false,
  });
};
