import { useMutation } from "@tanstack/react-query";
import useAgentInfoStore from "../store/agentInfo";
import useAuthStore from "../store/auth";
import { axiosWithAuth } from "../utils/axios";

export const useHomeCreateCompareSalesRequest = () => {
  const axios = axiosWithAuth();

  const createRequest = async (propertyId: string) => {
    try {
      await axios.post(`property/${propertyId}/compare_sales_request`);
    } catch (e) {
    } finally {
    }
  };

  const { mutate } = useMutation(createRequest);
  return { mutate };
};
