import Button, { ButtonType } from "./Button";
import { ReactComponent as Done } from "../../static/Done.svg";
import { ReactComponent as Pending } from "../../static/Pending.svg";
import { ReactComponent as Declined } from "../../static/Declined.svg";
import { ReactComponent as Expired } from "../../static/Expired.svg";
import { ReactComponent as Grayed } from "../../static/Grayed.svg";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";

export enum StepStatus {
  DONE,
  PENDING,
  DECLINED,
  EXPIRED,
  GRAYED,
}

export type StepItemProps = {
  status: StepStatus;
  title: string;
  subTitles: ReactNode[];
  emailTrackStatus: EmailTrackerStatus;
};

export enum EmailTrackerStatus {
  NONE,
  NOT_OPEN,
  OPEN,
}

function StepperItem({
  status,
  title,
  subTitles,
  emailTrackStatus,
}: StepItemProps) {
  const icon = () => {
    switch (status) {
      case StepStatus.DONE:
        return <Done className="w-[18px] h-[18px] mt-[1px] shrink-0" />;
      case StepStatus.PENDING:
        return <Pending className="w-[18px] h-[18px] mt-[1px] shrink-0" />;
      case StepStatus.DECLINED:
        return <Declined className="w-[18px] h-[18px] mt-[1px] shrink-0" />;
      case StepStatus.EXPIRED:
        return <Expired className="w-[18px] h-[18px] mt-[1px] shrink-0" />;
      case StepStatus.GRAYED:
        return <Grayed className="w-[18px] h-[18px] mt-[1px] shrink-0" />;
      default:
        return (
          <Button
            type={ButtonType.PRIMARY}
            loading={false}
            disabled={false}
            content="Talk to offer expert"
            subContent="Available now"
            onClick={async () => {}}
          />
        );
    }
  };
  const { t, i18n } = useTranslation();

  return (
    <div className="flex items-start gap-2">
      {icon()}
      <div className="flex flex-col">
        <div
          className={classNames("text-sm", {
            "text-gray-200": status === StepStatus.GRAYED,
          })}
        >
          {title}
        </div>
        {subTitles &&
          subTitles?.map((title) => (
            <div
              className={classNames("text-xs", {
                "text-gray-200": status === StepStatus.GRAYED,
                "text-gray-600": status !== StepStatus.GRAYED,
              })}
            >
              {title}
            </div>
          ))}
        {emailTrackStatus === EmailTrackerStatus.NOT_OPEN && (
          <div className={classNames("text-xs text-gray-600")}>
            {t("offerEmailTrackerLabel")}:{" "}
            <span className="font-bold text-yellow-500">
              {t("offerEmailTrackerNotOpenLabel")}
            </span>
          </div>
        )}
        {emailTrackStatus === EmailTrackerStatus.OPEN && (
          <div className={classNames("text-xs text-gray-600")}>
            {t("offerEmailTrackerLabel")}:{" "}
            <span className="font-bold text-green-600">
              {t("offerEmailTrackerOpenLabel")}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default StepperItem;
