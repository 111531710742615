import { useHomeExpertConversation } from "../../query/useHomeExpertConversation";
import experts from "../../static/Experts.png";
import { useTranslation } from "react-i18next";
import WarningTag from "./WarningTag";

const ContactExpertButton = () => {
  const { t } = useTranslation();
  const { data, refetch } = useHomeExpertConversation();
  return (
    <div
      style={{
        boxShadow:
          "-2px 4px 10px 0px rgba(0, 0, 0, 0.1), 2px 4px 10px 0px rgba(0, 0, 0, 0.1)",
      }}
      className="flex items-center gap-2 rounded-[40px] p-2 cursor-pointer pr-5 bg-white hover:bg-gray-50"
      onClick={() => {
        window.open(`/home/expert_chat`, "_blank");
        setTimeout(refetch, 2000);
      }}
    >
      {Boolean(data) && !Boolean(data?.read) && (
        <div className="absolute top-[-10px] right-[-4px]">
          <WarningTag content={t("newContactExpertButtonUnreadTitle")} />
        </div>
      )}
      <img className="w-[112px] h-10" src={experts} />
      <div className="flex flex-col items-start">
        <div className="flex items-center gap-1 text-[14px] font-semibold">
          <div>{t("newContactExpertLabel")}</div>
        </div>
        <div className="text-[12px] text-gray-600">
          {t("newContactExpertSubLabel")}
        </div>
      </div>
    </div>
  );
};

export default ContactExpertButton;
