import classNames from "classnames";
import { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { ReactComponent as Fold } from "../../static/Fold.svg";
import { ReactComponent as Unfold } from "../../static/Unfold.svg";

type Props = {
  isOpen: Boolean;
  title: string;
  children: ReactNode;
  onToggle: (value: boolean) => void;
};

function TransactionCollapse({ isOpen, title, onToggle, children }: Props) {
  return (
    <div className="flex flex-col w-full rounded-[20px] overflow-hidden border border-solid border-gray-200">
      <div
        className={classNames(
          "p-4 text-[14px] font-bold flex items-center justify-between", {
            "bg-white": !isOpen,
            "bg-gray-100 border-b": isOpen
          }
        )}
      >
        <div>{title}</div>
        {isOpen ? (
          <Fold className="cursor-pointer" onClick={() => onToggle(false)} />
        ) : (
          <Unfold className="cursor-pointer" onClick={() => onToggle(true)} />
        )}
      </div>
      {isOpen && children}
    </div>
  );
}

export default TransactionCollapse;
